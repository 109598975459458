import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useSidebarOpenState } from '@backstage/core-components';

const useStyles = makeStyles()({
  container: {
    textAlign: 'center',
    margin: 'auto',
    marginBottom: 20,
    '& span': {
      display: 'block',
      margin: '0 auto',
      color: 'white',
    },
  },
  title: {
    fontSize: 'large',
  },
  subTitle: {
    fontSize: 'smaller',
  },
});

export const AppTitle = () => {
  const { classes } = useStyles();
  const { isOpen } = useSidebarOpenState();
  return isOpen ? (
    <div className={classes.container}>
      <span className={classes.title}>BACKSTAGE</span>
      <span className={classes.subTitle}>CONFIDENTIAL</span>
    </div>
  ) : null;
};
