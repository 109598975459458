import { Entity, EntityPolicy } from '@backstage/catalog-model';
import { ACADEMY_VALIDATIONS_SPEC } from './academy-entity-validations';
import { EntityValidator } from '../../../common';
import { AcademyEntity } from '../index';

export class AcademyEntityPolicy implements EntityPolicy {
  constructor(
    private readonly validator = new EntityValidator<AcademyEntity>(
      ACADEMY_VALIDATIONS_SPEC,
    ),
  ) {
    this.validator = validator;
  }

  async enforce(entity: AcademyEntity): Promise<Entity> {
    if (entity.kind === 'Academy') {
      this.validator.applyDefaults(entity);
    }
    return entity;
  }
}
