export function ifTypes(types: string[], typeFilters: string[]) {
  return typeFilters.find(filter => types.includes(filter)) !== undefined;
}

const defaultKinds = [
  'Component',
  'Seamless',
  'Infrastructure',
  'API',
  'Group',
  'Template',
];

export function kinds(types: string[]) {
  if (types.length === 0) {
    return [...defaultKinds, 'Academy', 'Content'];
  } else if (ifTypes(types, ['software-catalog'])) {
    return defaultKinds;
  }
  return [];
}
