import { ContentAlphaEntity, ContentType } from './content.alpha.entity';

const MB_5 = 5000000;

/**
 * @public
 */
export class ContentEntityDataValidator {
  static validate(entity: ContentAlphaEntity, data: Buffer) {
    this.validateSize(entity, data);
    this.validateContent(entity, data);
  }

  private static validateSize(entity: ContentAlphaEntity, buffer: Buffer) {
    if (buffer.byteLength > MB_5) {
      throw new Error(
        `Entity < ${entity.metadata.name} > has a exceeded size. Expected max ${MB_5} got ${buffer.byteLength}`,
      );
    }
  }

  private static validateContent(entity: ContentAlphaEntity, data: Buffer) {
    if (entity.spec.type === ContentType.JSON) {
      this.validateJSON(entity, data);
    }
  }

  private static validateJSON(entity: ContentAlphaEntity, data: Buffer) {
    try {
      JSON.parse(data.toString()); // simply call JSON.parse to check if a parse has been succeeded
    } catch (e) {
      throw new Error(
        `Invalid data provided for < ${entity.metadata.name} >, error on expected json file: ${e.message}`,
      );
    }
  }
}
