import { WarningPanel } from '@backstage/core-components';
import { CustomErrorBase } from '@backstage/errors';
import React from 'react';

type Props = {
  error: Error | undefined | null;
  altTitle: string;
  altMessage?: string;
  severity?: 'error' | 'warning' | 'info';
};

export function DefaultWarningPanel({
  error,
  altTitle,
  altMessage,
  severity = 'error',
}: Props) {
  function errorTitle(): string {
    if (!error) {
      return altTitle;
    }
    if ((error as CustomErrorBase).cause) {
      return error.message;
    }
    return error.name;
  }
  function errorMessage(): string | undefined | null {
    if (!error && !altMessage) {
      return null;
    }
    if (error) {
      if ((error as CustomErrorBase).cause) {
        return `${(error as CustomErrorBase).cause!.message}`;
      }
      return error.message;
    }
    return altMessage;
  }
  return (
    <WarningPanel severity={severity} title={errorTitle()}>
      {errorMessage()}
    </WarningPanel>
  );
}
