import { JSONSchema6 } from 'json-schema';
import Schema from './schema/infrastructure.schema.json';

export type { InfrastructureAlphaEntity as InfrastructureEntity } from './InfrastructureAlphaEntity';
export * from './constants';
export { InfrastructureEntityPolicy } from './infrastructure-entity-policy';
export * from './types';
export { AWS_RESOURCE_TYPE_MAPPING } from './validations/infrastructure-entity-validations';

/**
 * @public
 */
export const InfrastructureAlphaSchema: JSONSchema6 = Schema as Omit<
  JSONSchema6,
  'examples'
>;
