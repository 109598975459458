import TextField from '@mui/material/TextField';
import { FieldProps } from '@rjsf/utils';
import React from 'react';

// See also: https://backstage.io/docs/features/software-templates/writing-custom-field-extensions
/**
 * This is the actual component that will get rendered in the form
 */
export const CustomInputValidationExtension = ({
  onChange,
  schema,
  rawErrors,
  required,
  formData,
  idSchema,
  errorSchema,
  placeholder,
  uiSchema,
}: FieldProps<string>) => {
  // ### NOTE ###
  // calling props.onChange directly causes an error:  Maximum call stack size exceeded, backstage can not handle events directly described as in documentation
  // we need to delegate onChange by target.value to assign the data as we except
  const onValueChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => onChange(event.currentTarget.value, errorSchema);

  const length = rawErrors?.length ?? 0;
  return (
    <TextField
      id={idSchema.$id}
      label={schema.title}
      placeholder={placeholder}
      helperText={schema.description}
      required={required}
      value={formData ?? ''}
      margin="normal"
      variant="standard"
      error={length > 0 && !formData}
      onChange={onValueChange}
      inputProps={{ autoFocus: uiSchema?.['ui:autofocus'] }}
    />
  );
};
