import { RequestOptions } from '@mercedes-benz/academy-common';
import { AcademyRequest, DiscoveryApi, FetchApi } from './types';
import { ResponseError } from '@backstage/errors';

export abstract class BaseClient {
  protected constructor(
    protected readonly discoveryApi: DiscoveryApi,
    protected readonly fetchApi: FetchApi,
    protected readonly endpointPath: string,
  ) {}

  protected async request({
    path,
    method,
    body,
    options,
  }: AcademyRequest): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('academy')}${this.endpointPath}${path}`;

    const headers: Record<string, string> = this.getDefaultHeaders(options);
    const response = await this.fetchApi.fetch(url, { method, body, headers });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return options?.responseAsJson !== false ? response.json() : response;
  }

  protected getDefaultHeaders(options?: RequestOptions) {
    return {
      'content-type': 'application/json;charset=UTF-8',
      ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
    };
  }
}
