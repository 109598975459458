import { ResponseError } from '@backstage/errors';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

export type PmasRequest = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
  method: string;
  path: string;
  body?: object;
  responseType?: 'json' | 'text';
};

export async function submitRequest({
  fetchApi,
  discoveryApi,
  path,
  method,
  body,
  responseType = 'json',
}: PmasRequest): Promise<any> {
  const url = `${await discoveryApi.getBaseUrl('pmas')}${path}`;

  const headers: Record<string, string> = {
    'content-type': 'application/json',
  };

  const requestBody = typeof body === 'object' ? JSON.stringify(body) : body;

  const response = await fetchApi.fetch(url, {
    method,
    headers,
    body: requestBody,
  });

  if (!response.ok) {
    throw await ResponseError.fromResponse(response);
  }

  return responseType === 'text' ? response.text() : response.json();
}
