import { Entity } from '@backstage/catalog-model';
import { AEM_PLUGIN_ANNOTATION } from './types';

export const hasAemPluginAnnotation = (entity: Entity) =>
  entity?.metadata.annotations?.[AEM_PLUGIN_ANNOTATION] !== undefined;

/**
 * It parses the annotation and returns the value if
 *  - it exists
 *  You must call this function for entities which has this annotation. You can check whether an annotation exists
 *  by calling `hasAemPluginAnnotation` function
 * @param entity - a backstage entity
 */
export const useAemPluginAnnotation = (entity: Entity): string | undefined => {
  return entity.metadata.annotations?.[AEM_PLUGIN_ANNOTATION];
};
