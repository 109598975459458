import {
  Content,
  ContentHeader,
  CreateButton,
  SupportButton,
} from '@backstage/core-components';
import React from 'react';

type Props = {
  title: string;
  supportTitle?: string;
  createTitle?: string;
} & React.ComponentPropsWithoutRef<'div'>;

export const EntityContentLayout = ({
  title,
  supportTitle,
  createTitle,
  children,
  ...otherProps
}: Props) => {
  return (
    <Content {...otherProps}>
      <ContentHeader title={title}>
        {createTitle && <CreateButton title={createTitle} />}
        {supportTitle && <SupportButton>{supportTitle}</SupportButton>}
      </ContentHeader>
      {children}
    </Content>
  );
};
