import { Link } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  EntityListComponent,
  PrepareResult,
} from '@backstage/plugin-catalog-import';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { BackButton, NextButton } from '../Buttons';
import { ReviewResult } from '../hooks';

type Props = {
  prepareResult: PrepareResult;
  onReview: (result: ReviewResult) => void;
  onGoBack?: () => void;
};

export const StepReviewLocation = ({ prepareResult, onGoBack }: Props) => {
  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptionalString('app.title') || 'Backstage';

  const exists =
    prepareResult.type === 'locations' &&
    prepareResult.locations.some(l => l.exists);

  return (
    <>
      {prepareResult.type === 'repository' && (
        <>
          <Typography paragraph>
            The following Pull Request has been opened:{' '}
            <Link
              to={prepareResult.pullRequest.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {prepareResult.pullRequest.url}
            </Link>
          </Typography>

          <Typography paragraph>
            You can already import the location and {appTitle} will fetch the
            entities as soon as the Pull Request is merged.
          </Typography>
        </>
      )}

      <Typography>
        {exists
          ? 'The following locations already exist in the catalog:'
          : 'The following locations were found:'}
      </Typography>

      <EntityListComponent
        locations={prepareResult.locations}
        locationListItemIcon={() => <LocationOnIcon />}
        withLinks={exists}
      />

      <Grid container spacing={0}>
        {onGoBack && <BackButton onClick={onGoBack} />}
        <Link to="/create">
          <NextButton>Done</NextButton>
        </Link>
      </Grid>
    </>
  );
};
