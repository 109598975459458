import { MissingAnnotationEmptyState } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { HARBOR_REPOSITORY_ANNOTATION } from '@mb.io/harbor-common';
import React from 'react';
import { isHarborAvailable } from '../../plugin';
import { HarborContent } from '../HarborContent';

type HarborContentPageProps = {
  title?: string;
};

export const HarborContentPage = ({ title }: HarborContentPageProps) => {
  const { entity } = useEntity();

  return !isHarborAvailable(entity) ? (
    <MissingAnnotationEmptyState annotation={HARBOR_REPOSITORY_ANNOTATION} />
  ) : (
    <HarborContent title={title} entity={entity} variant="gridItem" />
  );
};
