import { createApiRef } from '@backstage/core-plugin-api';
import { UserSettingsApi } from '@mercedes-benz/user-settings-common';

/**
 * A Backstage ApiRef for the User Settings API. See https://backstage.io/docs/api/utility-apis for more information on
 * Backstage ApiRefs.
 * @public
 */
export const userSettingsApiRef = createApiRef<UserSettingsApi>({
  id: 'plugin.user-settings.api',
});
