import { EntityBadgesDialog } from '@backstage-community/plugin-badges';
import { EntityLayout } from '@backstage/plugin-catalog';
import BadgeIcon from '@mui/icons-material/CallToAction';
import React, { ReactNode, useState } from 'react';

export const EntityLayoutWrapper = ({ children }: { children: ReactNode }) => {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);

  const extraMenuItems = [
    {
      title: 'Badges',
      Icon: BadgeIcon as any, // fixme remove any after EntityLayout migrated to mui
      onClick: () => setBadgesDialogOpen(true),
    },
  ];

  return (
    <>
      <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>
        {children}
      </EntityLayout>
      <EntityBadgesDialog
        open={badgesDialogOpen}
        onClose={() => setBadgesDialogOpen(false)}
      />
    </>
  );
};
