import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import React from 'react';
import './EntityPage.css';
import { ToggleableTabScroll } from './ToggleableTabScroll';
import { ApiPage } from './api/ApiPage';
import { ComponentPage } from './component/ComponentPage';
import { ContentPage } from './content/ContentPage';
import { DefaultEntityPage } from './default/DefaultEntityPage';
import { DomainPage } from './domain/DomainPage';
import { GroupPage } from './group/GroupPage';
import { InfrastructurePage } from './infrastructure/InfrastructurePage';
import { SeamlessPage } from './seamless/Seamless';
import { SystemRolePage } from './system-role/SystemRolePage';
import { SystemPage } from './system/SystemPage';
import { UserPage } from './user/UserPage';
import { AcademyPage } from './academy/AcademyPage';

/**
 * Important! Please do not convert this component to a function or class. It causes to an issue with
 * mounting of reference via router-dom in our local servers where we use hot-loader library and there is no reason
 * to use a function or class here!
 */
export const EntityPage = (
  <ToggleableTabScroll toggleName="tabscroll">
    <EntitySwitch>
      <EntitySwitch.Case if={isKind('component')} children={ComponentPage} />
      <EntitySwitch.Case if={isKind('api')} children={ApiPage} />
      <EntitySwitch.Case if={isKind('group')} children={GroupPage} />
      <EntitySwitch.Case if={isKind('user')} children={UserPage} />
      <EntitySwitch.Case if={isKind('system')} children={SystemPage} />
      <EntitySwitch.Case if={isKind('domain')} children={DomainPage} />
      <EntitySwitch.Case if={isKind('seamless')} children={SeamlessPage} />
      <EntitySwitch.Case if={isKind('content')} children={ContentPage} />
      <EntitySwitch.Case if={isKind('SystemRole')} children={SystemRolePage} />
      <EntitySwitch.Case if={isKind('Academy')} children={AcademyPage} />
      <EntitySwitch.Case
        if={isKind('Infrastructure')}
        children={InfrastructurePage}
      />
      <EntitySwitch.Case>{DefaultEntityPage}</EntitySwitch.Case>
    </EntitySwitch>
  </ToggleableTabScroll>
);
