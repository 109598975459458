import Grid from '@mui/material/Grid';
import {
  EntityApiDefinitionCard,
  EntityConsumingComponentsCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import { EntityLayout, EntitySwitch } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntityFeedbackResponseContent } from '@backstage-community/plugin-entity-feedback';
import {
  EnhancedEntityLinksCard,
  TeamContactsCard,
  isLinksAvailable,
} from '@mb.io/core-components';
import React from 'react';
import { EntityAboutCard } from '../../about-card';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';

export const ApiPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid
            container
            alignItems="stretch"
            direction="column"
            style={{ padding: 0 }}
          >
            <Grid item xs={12} style={{ padding: 0 }}>
              <TeamContactsCard />
            </Grid>
            <EntitySwitch>
              <EntitySwitch.Case if={isLinksAvailable}>
                <Grid item xs={12} style={{ padding: 0, paddingTop: '1em' }}>
                  <EnhancedEntityLinksCard />
                </Grid>
              </EntitySwitch.Case>
            </EntitySwitch>
          </Grid>
        </Grid>
        <Grid item xs={12} className="grid-item">
          <EntityCatalogGraphCard
            variant="gridItem"
            height={400}
            maxDepth={1}
          />
        </Grid>
        <Grid container alignItems="stretch">
          <Grid item md={12} xl={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={12} xl={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
