import { KubernetesValidatorFunctions } from '@backstage/catalog-model';
import { FieldValidation } from '@rjsf/utils';

/**
 * Artifact ID is the name of the jar without version. If you created it, then you can choose whatever name you want with lowercase letters and no strange symbols. If it's a third party jar, you have to take the name of the jar as it's distributed.
 * eg: maven, commons-math.
 * */
export const validateMavenArtifactId = (
  value: string,
  validation: FieldValidation,
) => {
  if (!KubernetesValidatorFunctions.isValidObjectName(value)) {
    validation.addError(
      `Invalid Maven artifactId ${value}. Should follow naming convention: a string that is sequences of [a-zA-Z0-9] separated by any of [-_.], at most 63 characters in total. eg: maven, commons-math`,
    );
  }
};
