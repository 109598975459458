import { useUserGroups, useUserIdentity } from '@mb.io/core-components';
import React, { ReactNode } from 'react';
import { usePlatformFeatureFlag } from '../hooks';
import { UserFeatureFlagged } from './UserFeatureFlagged';

export type PlatformFeatureFlaggedType = {
  children: ReactNode;
  feature: string;
};

export const PlatformFeatureFlagged = ({
  children,
  feature,
}: PlatformFeatureFlaggedType) => {
  const globalFeatureFlag = usePlatformFeatureFlag(feature);
  const { user, loading } = useUserIdentity(false, true);
  const userGroups = useUserGroups(user);

  // undefined means the flag has been removed entirely
  if (!globalFeatureFlag || globalFeatureFlag.available === undefined) {
    return <>{children}</>;
  }

  if (loading) {
    // wait for the user...
    return <></>;
  }

  // disallow for specific user groups
  if (
    globalFeatureFlag.forbiddenOwners?.some(group =>
      userGroups?.includes(group),
    )
  ) {
    return <></>;
  }

  const isAllowedOwner = globalFeatureFlag.allowedOwners?.some(group =>
    userGroups?.includes(group),
  );
  // if allowed for specific users which is not includes the user check only if allowed owners defined
  if (globalFeatureFlag.allowedOwners && !isAllowedOwner) {
    return <></>;
  }

  // no need for global check, convert to local feature flag
  if (globalFeatureFlag?.toggleable) {
    return (
      <UserFeatureFlagged feature={feature}>{children}</UserFeatureFlagged>
    );
  }
  return <>{(globalFeatureFlag?.available || isAllowedOwner) && children}</>;
};
