import Grid from '@mui/material/Grid';
import { EntityHasSystemsCard, EntityLayout } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import React from 'react';
import { EntityAboutCard } from '../../about-card';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';

export const DomainPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={12}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard variant="gridItem" height={800} maxDepth={1} />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
