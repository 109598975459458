import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  box: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type EmptyStateComponentProps = {
  title: string;
  description?: string | React.JSX.Element;
  action?: React.JSX.Element;
};

export const EmptyStateComponent = ({
  title,
  description,
  action,
}: EmptyStateComponentProps) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.box}>
      <Typography variant="h4" component="div" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" paragraph>
        {description}
      </Typography>
      {action}
    </Box>
  );
};
