import { ResourcePermission } from '@backstage/plugin-permission-common';

/**
 * @public
 */
export const SYSTEM_ROLE_PERMISSION_RESOURCE_TYPE = 'system-role';

/**
 * @public
 */
export const systemRolePermission: ResourcePermission<
  typeof SYSTEM_ROLE_PERMISSION_RESOURCE_TYPE
> = {
  name: 'system.role.permission',
  resourceType: SYSTEM_ROLE_PERMISSION_RESOURCE_TYPE,
  attributes: {
    action: 'update',
  },
  type: 'resource',
};
