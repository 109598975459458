import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import { RELATION_CONTAINS } from '@mb.io/catalog-model';
import React from 'react';
import {
  asSeamlessEntities,
  seamlessEntityColumns,
  seamlessEntityHelpLink,
} from '../seamless-constants';

type Props = {
  variant?: 'gridItem';
};

export const SeamlessContainsCard = ({ variant = 'gridItem' }: Props) => {
  return (
    <RelatedEntitiesCard
      variant={variant}
      title="Contains components"
      entityKind="Seamless"
      relationType={RELATION_CONTAINS}
      columns={seamlessEntityColumns}
      asRenderableEntities={asSeamlessEntities}
      emptyMessage="This package contains no components."
      emptyHelpLink={seamlessEntityHelpLink}
    />
  );
};
