import { Entity } from '@backstage/catalog-model';
import {
  HARBOR_REPOSITORY_ANNOTATION,
  RepositoryId,
} from '@mb.io/harbor-common';

const HARBOR_PROJECT_INSTANCE_SEPARATOR = '/';

function extractFirstSubstring(str: string): string[] {
  const substrings = str.split(HARBOR_PROJECT_INSTANCE_SEPARATOR);
  if (substrings.length === 0) return ['', ''];
  const first = substrings.shift() ?? '';
  const rest = substrings.join(HARBOR_PROJECT_INSTANCE_SEPARATOR);
  return [first, rest];
}

function hasInstanceName(repositorySlug: string): boolean {
  const substringsCount = (
    repositorySlug.split(HARBOR_PROJECT_INSTANCE_SEPARATOR) || []
  ).length;
  return substringsCount > 2;
}

export const useRepositoryKey = ({
  projectName,
  repoName,
}: RepositoryId): string => `${projectName}/${repoName}`;

export function useHarborAnnotation(entity: Entity): RepositoryId {
  const repositorySlug =
    entity.metadata.annotations?.[HARBOR_REPOSITORY_ANNOTATION] ?? '';

  let instanceName: string | null = null;
  let projectName: string;
  let repoName: string;

  if (hasInstanceName(repositorySlug)) {
    let projectAndRepoName = null;
    [instanceName, projectAndRepoName] = extractFirstSubstring(repositorySlug);
    [projectName, repoName] = extractFirstSubstring(projectAndRepoName);
  } else {
    [projectName, repoName] = extractFirstSubstring(repositorySlug);
  }

  return {
    instanceName,
    projectName,
    repoName,
  };
}
