import { Entity } from '@backstage/catalog-model';
import { ANNOTATION_EXTERNAL_PROVIDER } from './common';

export function isEntityProvidedBy(entity: Entity, externalSystemName: string) {
  return (
    externalSystemName.toLocaleLowerCase() ===
    entity.metadata.annotations?.[
      ANNOTATION_EXTERNAL_PROVIDER
    ]?.toLocaleLowerCase()
  );
}
