import { ThemeOptions } from '@mui/material/styles';
import { AppTheme } from '@backstage/core-plugin-api';
import { UnifiedThemeProvider } from '@backstage/theme';
import {
  black,
  blue,
  grey,
  LightTheme,
  white,
} from '@mercedes-benz/mui5-theme';
import React, { ReactElement } from 'react';
import { MBStarIcon } from '../icons';
import { mbuiBaseTheme } from './MBuiBaseTheme';
import { ExtendedOverrides } from './custom-theme-overrides';

const additionalPalette = {};
const additionalOverrides: ThemeOptions['components'] & ExtendedOverrides = {
  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: black,
        color: white,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        '&:hover': {
          '& [class*=deleteIcon]': {
            color: white,
          },
        },
      },
      deleteIcon: {
        '&:hover': {
          color: 'rgba(255, 255, 255, 0.6) !important',
        },
      },
    },
  },
  // unset hover for table rows, e.g. Dependabot-Plugin
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderBottomColor: grey[80],
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        '&[class*=hover]:hover': {
          backgroundColor: 'unset',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      sizeSmall: {
        padding: '6px 24px 6px 16px',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: black,
        '&:hover': {
          color: grey[50],
          backgroundColor: 'none',
        },
      },
    },
  },
  // Fix for outlined icons in a button, e.g. Catalog-Entity > Overview > Support > Team Members
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '&:not(.MuiChip-outlined)': {
          '& [class*=makeStyles-icon][class*=secondary]': {
            fill: white,
          },
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      containedPrimary: {
        color: `white !important`,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: blue[50],
        '&:hover': {
          color: 'inherit',
          textDecoration: 'underline',
        },
      },
    },
  },
  BackstageHeader: {
    styleOverrides: {
      subtitle: {
        color: white,
      },
    },
  },
};

const mbuiLightTheme = mbuiBaseTheme({
  baseTheme: LightTheme,
  additionalPalette,
  additionalOverrides,
});

const Provider = ({ children }: { children: ReactElement }) => (
  <UnifiedThemeProvider theme={mbuiLightTheme}>
    <>{children}</>
  </UnifiedThemeProvider>
);

export const mbuiLightThemeWithProvider: Partial<AppTheme> &
  Omit<AppTheme, 'theme'> = {
  id: 'backstage-theme-light',
  title: 'Light Theme',
  variant: 'light',
  icon: (
    <MBStarIcon
      style={{
        width: '22.62px',
        height: '22.62px',
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
      }}
    />
  ),
  Provider,
};
