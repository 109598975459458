import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'BackstageHeaderIcon' })(theme => ({
  root: {
    textAlign: 'center',
  },
  label: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: theme.typography.fontSize,
    lineHeight: 1,
  },
}));

type HeaderIconProps = {
  label: string;
  url: string;
  icon: React.JSX.Element;
};

/**
 * Additional icon label to main {@link Header}
 *
 * @public
 *
 */
export function HeaderIcon(props: HeaderIconProps) {
  const navigate = useNavigate();
  const { label, url, icon } = props;
  const { classes } = useStyles();
  return (
    <IconButton onClick={() => navigate(url)} size="large">
      <Typography component="span" className={classes.root}>
        {icon}
        <Typography className={classes.label}>
          {label || '<Unknown>'}
        </Typography>
      </Typography>
    </IconButton>
  );
}
