import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import {
  scmAuthApiRef,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  catalogImportApiRef,
  CatalogImportClient,
} from '@backstage/plugin-catalog-import';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { rootRouteRef } from './routes';

export const catalogAnalyzerPlugin = createPlugin({
  id: 'catalog-analyzer',
  apis: [
    createApiFactory({
      api: catalogImportApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        scmAuthApi: scmAuthApiRef,
        identityApi: identityApiRef,
        scmIntegrationsApi: scmIntegrationsApiRef,
        catalogApi: catalogApiRef,
        configApi: configApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({
        discoveryApi,
        scmAuthApi,
        scmIntegrationsApi,
        catalogApi,
        fetchApi,
        configApi,
      }) =>
        new CatalogImportClient({
          discoveryApi,
          scmAuthApi,
          fetchApi,
          scmIntegrationsApi,
          catalogApi,
          configApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const CatalogAnalyzerPage = catalogAnalyzerPlugin.provide(
  createRoutableExtension({
    name: 'CatalogAnalyzerPage',
    component: () =>
      import('./components/CatalogAnalyzerPage').then(
        m => m.CatalogAnalyzerPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
