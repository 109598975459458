import { EntityContentLayout } from '@mb.io/core-components';
import React from 'react';

type Props = {
  title?: string;
  repositoryKey: string;
  children: React.JSX.Element | React.JSX.Element[];
};

export const HarborContentContainer = ({
  title,
  repositoryKey,
  children,
}: Props) => {
  const actualTile = title ?? 'Harbor Dashboard';
  const support = `Explore Harbor information for ${repositoryKey}.`;

  return (
    <EntityContentLayout title={actualTile} supportTitle={support}>
      {children}
    </EntityContentLayout>
  );
};
