import {
  createRouteRef,
  createExternalRouteRef,
  createSubRouteRef,
} from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'scaffolder',
});

export const scaffolderTemplatesRouteRef = createSubRouteRef({
  id: 'scaffolder:templates',
  parent: rootRouteRef,
  path: '/templates/:categoryId',
});

export const registerComponentRouteRef = createExternalRouteRef({
  id: 'register-component',
  optional: true,
});

export const viewTechDocRouteRef = createExternalRouteRef({
  id: 'view-techdoc',
  optional: true,
  params: ['namespace', 'kind', 'name'],
});

export const selectedTemplateRouteRef = createSubRouteRef({
  id: 'scaffolder/selected-template',
  parent: rootRouteRef,
  path: '/templates/:namespace/:templateName',
});

export const scaffolderTaskRouteRef = createSubRouteRef({
  id: 'scaffolder/task',
  parent: rootRouteRef,
  path: '/tasks/:taskId',
});
