import { MissingAnnotationEmptyState } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ManifestFile } from '@mercedes-benz/seamless-common';
import React from 'react';
import { AEM_PLUGIN_ANNOTATION, useAemPluginAnnotation } from '../annotation';
import { Manifest } from './manifest/Manifest';

export type ManifestProps = {
  manifestFile?: ManifestFile;
};

export const AemManifestPage = () => {
  const { entity } = useEntity();
  const aemPluginName = useAemPluginAnnotation(entity);

  return !aemPluginName ? (
    <MissingAnnotationEmptyState annotation={AEM_PLUGIN_ANNOTATION} />
  ) : (
    <Manifest aemPluginName={aemPluginName} />
  );
};
