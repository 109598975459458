import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Divider from '@mui/material/Divider';

type IntroComponentProps = {
  intro: string;
};

export const IntroComponent = ({ intro }: IntroComponentProps) => {
  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Typography variant="h4" component="div" gutterBottom>
          {intro}
        </Typography>
        <Divider />
      </Box>
    </>
  );
};
