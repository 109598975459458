import { RELATION_HAS_PART } from '@backstage/catalog-model';
import { InfoCardVariants } from '@backstage/core-components';
import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import React from 'react';
import {
  asContentEntities,
  contentEntityColumns,
  contentEntityHelpLink,
} from '../content-constants';

/** @public */
export interface HasContentsCardCardProps {
  variant?: InfoCardVariants;
}

export function HasContentsCard(props: HasContentsCardCardProps) {
  const { variant = 'gridItem' } = props;
  return (
    <RelatedEntitiesCard
      variant={variant}
      title="Has contents"
      entityKind="Content"
      relationType={RELATION_HAS_PART}
      columns={contentEntityColumns}
      asRenderableEntities={asContentEntities}
      emptyMessage="No content is part of this system"
      emptyHelpLink={contentEntityHelpLink}
    />
  );
}
