import { BackstageOverrides } from '@backstage/core-components';
import {
  DarkTheme,
  LightTheme,
  black,
  blue,
  grey,
  red,
  white,
} from '@mercedes-benz/mui5-theme';
import { Theme } from '@mui/material/styles';
import {
  BACKGROUND_HOVER_DARK,
  BACKGROUND_HOVER_LIGHT,
  MB_PRIMARY_DARK,
  MB_PRIMARY_LIGHT,
} from './MBuiBaseTheme';

export type ExtendedOverrides = {
  [K in keyof BackstageOverrides]: BackstageOverrides[K] & {
    styleOverrides?: BackstageOverrides[K];
  };
};

export const createCustomThemeOverrides = (
  type: 'light' | 'dark',
): Theme['components'] & ExtendedOverrides => {
  const primaryColor = type === 'dark' ? MB_PRIMARY_DARK : MB_PRIMARY_LIGHT;
  const hoverColor =
    type === 'dark' ? BACKGROUND_HOVER_DARK : BACKGROUND_HOVER_LIGHT;

  const theme = type === 'dark' ? DarkTheme : LightTheme;
  return {
    BackstageSelect: {
      styleOverrides: {
        formControl: {
          maxWidth: 'initial',
          textTransform: 'capitalize',
        },
        label: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            fontSize: '0.875rem',
          },
        },
        a: {
          fontSize: '0.875rem',
          textDecoration: 'none',
          color: type === 'dark' ? 'white' : 'black',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:any-link': null,
          display: 'unset',
          // change color of icon-buttons but only if they are not in the header
          '& > span.MuiIconButton-label > svg.MuiSvgIcon-root': {
            fill: type === 'light' ? black : white,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          '& [class~=MuiButton-label]': {
            color: theme.palette.primary.contrastText,
          },
          '&[class~=MuiLink-underlineHover]': {
            '&:hover': {
              textDecoration: `underline ${theme.palette.primary.contrastText}`,
            },
          },
        },
        containedPrimary: {
          color: 'white',
          backgroundColor: primaryColor,
          '&:hover, &:focus': {
            backgroundColor: hoverColor,
          },
        },
        text: {
          fontSize: '1rem',
        },
        textPrimary: {
          color: primaryColor,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          paddingRight: '1rem',
        },
        item: {
          padding: '8px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          marginRight: '8px',
          marginBottom: '8px',
        },
        deleteIcon: {
          color: 'white',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          marginBottom: '0.5rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: type === 'dark' ? grey[10] : white,
          backgroundImage: 'unset',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          flexGrow: 1,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
        },
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: {
          '& a': {
            color: 'inherit',
          },
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
        label: {
          fontWeight: 'inherit',
        },
        selected: {
          backgroundColor: primaryColor,
          borderLeft: `solid 3px ${blue[60]} !important`,
        },
        searchFieldHTMLInput: {
          color: white,
          '&::placeholder': {
            color: white,
            opacity: 0.7,
          },
        },
        searchRoot: {
          '& [class*=iconContainer]': {
            paddingTop: '6px',
          },
        },
      },
    },
    BackstageWarningPanel: {
      styleOverrides: {
        panel: {
          backgroundColor: grey[95],
        },
        details: {
          backgroundColor: grey[95],
        },
        summaryText: {
          color: red[20],
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        title: {
          fontSize: '3rem',
          fontFamily: 'MB Corpo A Title Condensed,MB Corpo S Title Condensed',
        },
        subtitle: {
          fontWeight: 'normal',
          color: 'inherit',
        },
        rightItemsBox: {
          '& a': {
            color: 'inherit',
          },
        },
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        selected: {
          borderLeft: 'none !important',
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        value: {
          '& .MuiLink-root': {
            color: white,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: blue[50],
        },
        flexContainer: {
          '& .Mui-selected': {
            borderLeft: 'none',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '16px',
        },
      },
    },
    BackstageTabbedCard: {
      styleOverrides: {
        root: {
          '& button': {
            padding: '0 15px',
          },
        },
      },
    },
  };
};
