import {
  CreateEnrollmentRequest,
  DeleteResponse,
  Enrollment,
  EnrollmentsApi,
  Page,
  RequestOptions,
  SortOrder,
} from '@mercedes-benz/academy-common';
import { DiscoveryApi, FetchApi } from '../types';
import { BaseClient } from '../base.client';

/**
 * A frontend and backend compatible client for communicating with the Academy Enrollments API
 *
 * @public
 */
export class EnrollmentsClient extends BaseClient implements EnrollmentsApi {
  constructor(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    super(discoveryApi, fetchApi, '/enrollments');
  }

  async create(
    request: CreateEnrollmentRequest,
    options?: RequestOptions,
  ): Promise<Enrollment> {
    return this.request({
      method: 'POST',
      path: `/`,
      body: JSON.stringify(request),
      options,
    });
  }

  async delete(
    enrollmentId: string,
    options?: RequestOptions,
  ): Promise<DeleteResponse> {
    return this.request({
      method: 'DELETE',
      path: `/${encodeURIComponent(enrollmentId)}`,
      options,
    });
  }

  async getAll(
    pageNumber: number = 1,
    pageSize: number = 10,
    sortBy: keyof Enrollment = 'createdAt',
    sortOrder = SortOrder.DESC,
    options?: RequestOptions,
  ): Promise<Page<Enrollment>> {
    return this.request({
      method: 'GET',
      path: `/page=${encodeURIComponent(pageNumber)}&pageSize=${encodeURIComponent(pageSize)}&sortBy=${encodeURIComponent(sortBy)}&sortOrder=${encodeURIComponent(sortOrder)}`,
      options,
    });
  }

  async getById(
    enrollmentId: string,
    options?: RequestOptions,
  ): Promise<Enrollment> {
    return this.request({
      method: 'GET',
      path: `/${encodeURIComponent(enrollmentId)}`,
      options,
    });
  }
}
