import { Progress } from '@backstage/core-components';
import { ResourcePermission } from '@backstage/plugin-permission-common';
import { ownershipPermission } from '@mercedes-benz/permission-common';
import React from 'react';
import { useGuard } from '../hooks';
import { AccessDeniedComponent } from './AccessDeniedComponent';

type BackstageGuardProps = {
  children: React.ReactElement;
  disallowComponent?: React.ReactElement;

  hideLoading?: boolean;
  /**
   * default is ownerShipPermission if not given.
   */
  permission?: ResourcePermission<'catalog-entity'>;
};

export const BackstageGuard = ({
  hideLoading,
  disallowComponent,
  children,
  permission,
}: BackstageGuardProps) => {
  const { allowed, loading } = useGuard(permission ?? ownershipPermission);

  if (loading && !hideLoading) {
    return <Progress />;
  }

  if (allowed) {
    return <> {children} </>;
  }

  // allow an optional component to display
  return (
    <>{disallowComponent ? disallowComponent : <AccessDeniedComponent />}</>
  );
};
