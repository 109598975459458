import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { NewsletterDialog } from '../../dialogs';

type ComingSoonComponentProps = {
  heading: string;
};

export const ComingSoonComponent = ({ heading }: ComingSoonComponentProps) => {
  const [openNewsletterDialog, setOpenNewsletterDialog] = React.useState(false);

  const onNewsletterDialogClose = () => {
    setOpenNewsletterDialog(false);
  };

  const onNewsletterDialogOpen = () => {
    setOpenNewsletterDialog(true);
  };

  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Typography variant="h6" color="primary" gutterBottom>
          Coming Soon!
        </Typography>
        <Typography variant="h4" component="div" gutterBottom>
          {heading}
        </Typography>
        <Typography variant="body1" paragraph>
          This feature is planned or in development, but be sure to check back
          for updates.
        </Typography>
        <Button
          variant="outlined"
          onClick={onNewsletterDialogOpen}
          style={{ display: 'none' }}
        >
          Get notified when released
        </Button>
      </Box>
      <NewsletterDialog
        open={openNewsletterDialog}
        onClose={onNewsletterDialogClose}
      />
    </>
  );
};
