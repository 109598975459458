import Grid from '@mui/material/Grid';
import React from 'react';
import { GridItemComponent } from '../GridItemComponent';
import type { Item } from '../GridItemComponent/GridItemComponent';

type GridComponentProps = {
  items: Item[];
  alignCentered?: boolean;
};

export const GridComponent = ({ items, alignCentered }: GridComponentProps) => {
  return (
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <GridItemComponent item={item} alignCentered={alignCentered} />
        </Grid>
      ))}
    </Grid>
  );
};
