import { EntityValidation } from '../../common';
import { ContentAlphaEntity, ContentType } from './content.alpha.entity';

export const CONTENT_ENTITY_VALIDATIONS_SPEC: EntityValidation<ContentAlphaEntity>[] =
  [
    {
      field: 'spec.type',
      message: entity =>
        `Type '${
          entity.spec.type
        }' is not supported for Content kind, it must be a type of: ${Object.values(
          ContentType,
        ).join(' or ')}.`,
      validate: entity => Object.values(ContentType).includes(entity.spec.type),
    },
    {
      field: 'spec.fetch',
      message: () =>
        `< entity.spec.fetch > is allowed to be true if a target in spec has been defined!.`,
      validate: entity =>
        !(entity.spec.fetch === true && entity.spec.target === undefined),
    },
    {
      field: 'spec.data',
      message: () =>
        `< entity.spec.data > and < entity.spec.target > can not be defined at same time.`,
      validate: entity =>
        !(
          typeof entity.spec.data !== 'undefined' &&
          typeof entity.spec.target !== 'undefined'
        ),
    },
  ];
