import {
  AcademyApi,
  EnrollmentsApi,
  MetadataApi,
  SessionsApi,
  TrainingsApi,
} from '@mercedes-benz/academy-common';
import { DiscoveryApi, FetchApi } from './types';
import { TrainingsClient } from './trainings';
import { EnrollmentsClient } from './enrollments';
import { SessionsClient } from './sessions';
import { MetadataClient } from './metadata';
import crossFetch from 'cross-fetch';

/**
 * A frontend and backend compatible client for communicating with the Academy API.
 *
 * @public
 */
export class AcademyClient implements AcademyApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly trainingsApi: TrainingsApi,
    private readonly enrollmentsApi: EnrollmentsApi,
    private readonly sessionsApi: SessionsApi,
    private readonly metadataApi: MetadataApi,
  ) {}

  static create(options: {
    discoveryApi: DiscoveryApi;
    fetchApi?: FetchApi;
  }): AcademyClient {
    const discoveryApi = options.discoveryApi;
    const fetchApi = options.fetchApi || { fetch: crossFetch };
    return new AcademyClient(
      discoveryApi,
      new TrainingsClient(discoveryApi, fetchApi),
      new EnrollmentsClient(discoveryApi, fetchApi),
      new SessionsClient(discoveryApi, fetchApi),
      new MetadataClient(discoveryApi, fetchApi),
    );
  }

  enrollments(): EnrollmentsApi {
    return this.enrollmentsApi;
  }

  metadata(): MetadataApi {
    return this.metadataApi;
  }

  sessions(): SessionsApi {
    return this.sessionsApi;
  }

  trainings(): TrainingsApi {
    return this.trainingsApi;
  }

  async contentRoot(): Promise<string> {
    return `${await this.discoveryApi.getBaseUrl('academy')}/content/static`;
  }
}
