import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { DefaultResponse, UrlGroupType } from '@mercedes-benz/pmas-common';
import { submitRequest } from './submitRequest';

export class UrlGroupsApi {
  private readonly discoveryApi;
  private readonly fetchApi;

  constructor(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    this.fetchApi = fetchApi;
    this.discoveryApi = discoveryApi;
  }

  async getUrlGroups(): Promise<DefaultResponse<UrlGroupType[]>> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'GET',
      path: `/urlGroups`,
    });
  }

  async createUrlGroup(urlGroupProps: object): Promise<DefaultResponse> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'POST',
      path: `/urlGroups`,
      body: {
        urlGroupProps: urlGroupProps,
      },
    });
  }

  async updateUrlGroup(
    urlGroupId: number,
    urlGroupProps: object,
  ): Promise<DefaultResponse> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'PUT',
      path: `/urlGroups/${urlGroupId}`,
      body: {
        urlGroupProps: urlGroupProps,
      },
    });
  }

  async deleteUrlGroup(urlGroupId: number): Promise<DefaultResponse> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'DELETE',
      path: `/urlGroups/${urlGroupId}`,
    });
  }

  async addUrlsToUrlGroup(
    urlGroupId: number,
    urls: string,
  ): Promise<DefaultResponse> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'POST',
      path: `/urlGroups/${urlGroupId}/urls`,
      body: {
        urls: [urls],
      },
    });
  }

  async deleteUrlsFromUrlGroup(
    urlGroupId: number,
    url: string,
  ): Promise<DefaultResponse> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'DELETE',
      path: `/urlGroups/${urlGroupId}/urls/${url}`,
    });
  }
}
