import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  CustomFieldExtensionSchema,
  CustomFieldValidator,
  FieldExtensionComponent,
} from '@backstage/plugin-scaffolder-react';
import { validateAlphaNumericCharacters } from './validation/alpha-numeric.validation';
import { validateCatalogEntityName } from './validation/catalog-entity-name.validation';
import { CustomInputValidationExtension } from './validation/CustomInputValidationExtension';
import { validateJavaPackageName } from './validation/java-package.validation';
import { validateMavenArtifactId } from './validation/maven-artifact-id.validation';
import { validateMavenGroupId } from './validation/maven-group-id.validation';
import { validateNpmArtifactName } from './validation/npm-artifact-name.validation';
import { validateNpmScope } from './validation/npm-scope.validation';
import { validateSeamlessPrefix } from './validation/seamless-prefix.validation';
import { UIOptionsType } from '@rjsf/utils';
import {
  CustomMarkdownTextExtension,
  CustomMarkdownTextExtensionSchema,
} from './field/CustomMarkdownTextExtension';

function createExtension(
  name: string,
  component: any,
  validation: CustomFieldValidator<string>,
  schema?: CustomFieldExtensionSchema,
) {
  return createScaffolderFieldExtension({
    name,
    component: component,
    validation,
    schema: schema,
  });
}

/**
 * List of custom field extension, we can provide multiple extensions for validations
 */

export const AlphaNumericValidation: FieldExtensionComponent<
  string,
  UIOptionsType
> = scaffolderPlugin.provide(
  createExtension(
    'AlphaNumericValidation',
    CustomInputValidationExtension,
    validateAlphaNumericCharacters,
  ),
);

export const CatalogEntityNameValidation: FieldExtensionComponent<
  string,
  UIOptionsType
> = scaffolderPlugin.provide(
  createExtension(
    'CatalogEntityNameValidation',
    CustomInputValidationExtension,
    validateCatalogEntityName,
  ),
);

export const JavaPackageNameValidation: FieldExtensionComponent<
  string,
  UIOptionsType
> = scaffolderPlugin.provide(
  createExtension(
    'JavaPackageNameValidation',
    CustomInputValidationExtension,
    validateJavaPackageName,
  ),
);

export const MavenArtifactIdValidation: FieldExtensionComponent<
  string,
  UIOptionsType
> = scaffolderPlugin.provide(
  createExtension(
    'MavenArtifactIdValidation',
    CustomInputValidationExtension,
    validateMavenArtifactId,
  ),
);

export const MavenGroupIdValidation: FieldExtensionComponent<
  string,
  UIOptionsType
> = scaffolderPlugin.provide(
  createExtension(
    'MavenGroupIdValidation',
    CustomInputValidationExtension,
    validateMavenGroupId,
  ),
);

export const NpmArtifactNameValidation: FieldExtensionComponent<
  string,
  UIOptionsType
> = scaffolderPlugin.provide(
  createExtension(
    'NpmArtifactNameValidation',
    CustomInputValidationExtension,
    validateNpmArtifactName,
  ),
);

export const NpmScopeValidation: FieldExtensionComponent<
  string,
  UIOptionsType
> = scaffolderPlugin.provide(
  createExtension(
    'NpmScopeValidation',
    CustomInputValidationExtension,
    validateNpmScope,
  ),
);

export const SeamlessPrefixValidation: FieldExtensionComponent<
  string,
  UIOptionsType
> = scaffolderPlugin.provide(
  createExtension(
    'SeamlessPrefixValidation',
    CustomInputValidationExtension,
    validateSeamlessPrefix,
  ),
);

export const MarkdownField: FieldExtensionComponent<string, UIOptionsType> =
  scaffolderPlugin.provide(
    createExtension(
      'MarkdownField',
      CustomMarkdownTextExtension,
      () => {},
      CustomMarkdownTextExtensionSchema,
    ),
  );
