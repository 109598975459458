import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React, { ComponentProps } from 'react';
import { useStyles } from './buttons-style';

export const NextButton = (
  props: ComponentProps<typeof Button> & { loading?: boolean },
) => {
  const { loading, ...buttonProps } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        color="primary"
        variant="contained"
        {...buttonProps}
        disabled={props.disabled || props.loading}
      />
      {props.loading && (
        <CircularProgress size="1.5rem" className={classes.buttonProgress} />
      )}
      {props.loading}
    </div>
  );
};
