import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';

import {
  actionEnum,
  GitHubApi,
  MultiGitHubUserGroupRequest,
  UserGroupResponse,
} from '@mercedes-benz/sip-central-common';

export const githubApiRef = createApiRef<GitHubApi>({
  id: 'plugin.sip-central.github.api',
});

export type GithubRequest = {
  method: string;
  path: string;
  body?: object;
  responseAsJson?: boolean;
};
export class GithubApiClient implements GitHubApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }

  searchUserOrganizations(userName: string): any {
    return this.submitRequest({
      method: 'GET',
      path: `/github/users/${userName}/organizations`,
    });
  }

  searchUserGroups(organization: string, userName: string): Promise<string[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/github/organizations/${organization}/users/${userName}/teams`,
    });
  }
  async addUserToGroup(
    addUserToGroupRequest: MultiGitHubUserGroupRequest,
  ): Promise<UserGroupResponse> {
    const res = await this.submitRequest({
      method: 'POST',
      path: `/github/groups/users`,
      body: addUserToGroupRequest,
      responseAsJson: false,
    });

    this.submitRequest({
      method: 'POST',
      path: `/audit-logs`,
      body: {
        currentUser: undefined,
        action: actionEnum.ADD_PERMISSION,
        activities: [
          {
            performedOn: addUserToGroupRequest.loginName,
            toolPermissions: {
              tool: 'Github',
              permissions: [
                {
                  instance: addUserToGroupRequest.organization,
                  groups: addUserToGroupRequest.groups,
                },
              ],
            },
          },
        ],
        actionDate: new Date().toJSON(),
      },
    });

    return res;
  }

  async removeUserFromGroup(
    removeUserFromGroupRequest: MultiGitHubUserGroupRequest,
  ): Promise<UserGroupResponse> {
    const res = await this.submitRequest({
      method: 'POST',
      path: `/github/groups/users/remove`,
      body: removeUserFromGroupRequest,
      responseAsJson: false,
    });

    this.submitRequest({
      method: 'POST',
      path: `/audit-logs`,
      body: {
        currentUser: undefined,
        action: actionEnum.REMOVE_PERMISSION,
        activities: [
          {
            performedOn: removeUserFromGroupRequest.loginName,
            toolPermissions: {
              tool: 'Github',
              permissions: [
                {
                  instance: removeUserFromGroupRequest.organization,
                  groups: removeUserFromGroupRequest.groups,
                },
              ],
            },
          },
        ],
        actionDate: new Date().toJSON(),
      },
    });

    return res;
  }

  private async submitRequest({
    path,
    method,
    body,
    responseAsJson = true,
  }: GithubRequest): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('sip-central')}${path}`;

    const headers: Record<string, string> = {
      'content-type': 'application/json',
    };
    const response = await this.fetchApi.fetch(url, {
      method,
      headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      return Promise.reject('Github');
    }
    return responseAsJson ? response.json() : response;
  }
}
