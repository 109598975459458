import { useEffect, useState } from 'react';
import { usePlatformFeatureFlagContext } from '../context/PlatformFeatureFlagContext';
import { PlatformFeatureFlag } from '../types/platform-feature.flag';

export const usePlatformFeatureFlag = (feature: string) => {
  const { features } = usePlatformFeatureFlagContext();
  const [platformFeatureFlag, setPlatformFeatureFlag] = useState<
    PlatformFeatureFlag | undefined
  >(undefined);

  useEffect(() => {
    const featureFlag = features.find(current => current.feature === feature);
    if (featureFlag) {
      setPlatformFeatureFlag(featureFlag);
    }
  }, [feature, features, setPlatformFeatureFlag]);

  return platformFeatureFlag;
};
