import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef, snippetRouteRef, tutorialRouteRef } from './routes';
import { academyApiRef } from './Api/AcademyApi';
import { AcademyClient } from '@mercedes-benz/academy-client';
import {
  createSearchResultListItemExtension,
  SearchResultListItemExtensionProps,
} from '@backstage/plugin-search-react';
import { AcademySearchResultListItemProps } from './search';

export const academyPlugin = createPlugin({
  id: 'academy',
  routes: {
    root: rootRouteRef,
    tutorial: tutorialRouteRef,
    snippet: snippetRouteRef,
  },
  apis: [
    createApiFactory({
      api: academyApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        AcademyClient.create({ discoveryApi, fetchApi }),
    }),
  ],
});

export const AcademyCatalog = academyPlugin.provide(
  createRoutableExtension({
    name: 'AcademyPage',
    component: () => import('./App').then(m => m.App),
    mountPoint: rootRouteRef,
  }),
);

export const AcademySearchResultListItem: (
  props: SearchResultListItemExtensionProps<AcademySearchResultListItemProps>,
) => JSX.Element | null = academyPlugin.provide(
  createSearchResultListItemExtension({
    name: 'AcademySearchResultListItem',
    component: () =>
      import('./search/AcademySearchResultListItem').then(
        m => m.AcademySearchResultListItem,
      ),
    predicate: result => result.type === 'academy',
  }),
);
