import {
  configApiRef,
  FetchApi,
  fetchApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import React, { useEffect } from 'react';
import { oidcAuthRef } from '../../apis';
import { AuthenticationManager } from './AuthenticationManager';

const { fetch: originalFetch } = window;

const ensureContentType = (options: RequestInit) => {
  // Check if the method is POST, PUT, or PATCH
  if (!['POST', 'PUT', 'PATCH'].includes(options.method?.toUpperCase() ?? '')) {
    return;
  }

  if (!options.headers) {
    options.headers = { 'Content-Type': 'application/json' };
  } else if (
    options.headers instanceof Headers &&
    !options.headers.has('Content-Type')
  ) {
    options.headers.append('Content-Type', 'application/json');
  } else if (
    Array.isArray(options.headers) &&
    !options.headers.some(([key]) => key.toLowerCase() === 'content-type')
  ) {
    options.headers.push(['Content-Type', 'application/json']);
  } else {
    const headers = options.headers as Record<string, unknown>;
    const containsContentType = Object.keys(headers).some(
      header => header.toLowerCase() === 'content-type',
    );
    if (!containsContentType) {
      headers['Content-Type'] = 'application/json';
    }
  }
};

const createInterceptor = (
  authManager: AuthenticationManager,
  fetchApi: FetchApi,
) => {
  const fetcher = async (
    resource: RequestInfo | URL,
    config: RequestInit = {},
  ) => {
    const result = await originalFetch(resource, config);

    // if we get an unauthorized from the server, enforce user to sign-in again
    if (authManager.isUnauthorized(resource, result)) {
      await authManager.signOut();
    }
    return result;
  };

  // override backstage core fetcher
  fetchApi.fetch = (url, init) => window.fetch(url as never, init);

  window.fetch = async (resource, config = {}) => {
    if (
      resource.toString().includes('amazonaws') ||
      resource.toString().includes('rum.browser-intake-datadoghq.eu')
    ) {
      return originalFetch(resource as never, config);
    }

    if (
      (config.headers as Record<string, string>)?.accept !==
      'application/vnd.github.v3+json'
    ) {
      config.credentials = 'include';
    }

    ensureContentType(config);
    // skip auth calls
    // IMPORTANT: Skip authorization token resolution if there is already a token injected in the request config due to second-level authentication against GitHub APIs.
    // This will avoid appending external tokens (GitHub) with backstage auth token.
    const url = resource.toString();
    if (url.includes('/auth/') || url.includes('/session')) {
      return originalFetch(resource as never, config);
    }

    const headers = (config.headers as Record<string, string>) ?? {};
    const token = await authManager.getBearerToken();

    if (
      typeof resource !== 'string' &&
      typeof (resource as Request).headers.set === 'function'
    ) {
      (resource as Request).headers.set('Authorization', token);
      return fetcher(resource as never, config);
    }

    if (
      Array.isArray(headers) &&
      !headers.some(item => item.includes('Authorization'))
    ) {
      headers.push(['Authorization', token]);
    } else if (!headers.Authorization && !headers.authorization) {
      headers.Authorization = token;
    }
    return fetcher(resource as never, {
      ...config,
      method: config?.method ?? 'get',
    });
  };

  return () => {
    window.fetch = originalFetch;
  };
};

export const FetchInterceptorOutlet = () => {
  const api = useApi(identityApiRef);
  const config = useApi(configApiRef);
  const fetchApi = useApi(fetchApiRef);
  const oidcAuth = useApi(oidcAuthRef);

  // restore on unmount
  useEffect(() => {
    if (config.getOptionalBoolean('app.auth.enabled') === false) {
      return () => {};
    }

    return createInterceptor(
      new AuthenticationManager(config, api, oidcAuth),
      fetchApi,
    );
  }, [api, config, fetchApi, oidcAuth]);
  return <div />;
};
