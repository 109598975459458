import { EmptyState } from '@backstage/core-components';
import React from 'react';

export const AccessDeniedComponent = () => (
  <EmptyState
    title="Access Denied"
    missing="data"
    description="You don't have access to see this page. Only the entity/component owners can see this information."
  />
);
