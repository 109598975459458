import { InfoCard } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useCatalogFilename } from '../hooks';

/**
 * Props for {@link AnalyzeInfoCard}.
 *
 * @public
 */
export interface AnalyzeInfoCardProps {
  exampleLocationUrl?: string;
  exampleRepositoryUrl?: string;
}

/**
 * Shows information about the analyze process.
 *
 * @public
 */
export const AnalyzeInfoCard = (props: AnalyzeInfoCardProps) => {
  const {
    exampleLocationUrl = 'https://github.com/backstage/backstage/blob/master/catalog-info.yaml',
    exampleRepositoryUrl = 'https://github.com/backstage/backstage',
  } = props;

  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptionalString('app.title') || 'Backstage';

  const hasGithubIntegration = configApi.has('integrations.github');

  const catalogFilename = useCatalogFilename();

  return (
    <InfoCard
      title="Analyze an existing component"
      titleTypographyProps={{ component: 'h3' }}
    >
      <Typography variant="body2" color="error" paragraph>
        We do not allow registering new components but you can still use this
        page to analyze and validate your entities.
      </Typography>
      <Typography variant="body2" paragraph>
        Enter the URL to your source code repository to analyze it.
      </Typography>
      <Typography component="h4" variant="h6">
        Link to an existing entity file
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" paragraph>
        Example: <code>{exampleLocationUrl}</code>
      </Typography>
      <Typography variant="body2" paragraph>
        The wizard analyzes the file, previews the entities, and adds them to
        the {appTitle} catalog.
      </Typography>
      {hasGithubIntegration && (
        <>
          <Typography component="h4" variant="h6">
            Link to a repository{' '}
            <Chip label="GitHub only" variant="outlined" size="small" />
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" paragraph>
            Example: <code>{exampleRepositoryUrl}</code>
          </Typography>
          <Typography variant="body2" paragraph>
            The wizard discovers all <code>{catalogFilename}</code> files in the
            repository, previews the entities, and gives analysing results about
            them for the {appTitle} catalog.
          </Typography>
        </>
      )}
    </InfoCard>
  );
};
