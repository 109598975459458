import {
  SidebarOpenStateProvider,
  useSidebarOpenState,
} from '@backstage/core-components';
import { MBStarIcon } from '@mb.io/core-components';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ shouldDisplayLarger: boolean }>()(_ => ({
  svg: {
    width: 58,
    height: 58,
    transition: 'all ease-in 0.4s',
  },
}));

const LogoComponent = () => {
  const { isOpen } = useSidebarOpenState();
  const { classes } = useStyles({ shouldDisplayLarger: isOpen });

  return <MBStarIcon className={classes.svg} />;
};

export const MBLogo = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <SidebarOpenStateProvider value={{ isOpen, setOpen }}>
      <LogoComponent />
    </SidebarOpenStateProvider>
  );
};
