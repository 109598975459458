import { Page } from '@backstage/core-components';
import {
  TechDocsReaderPageContent,
  TechDocsReaderPageHeader,
} from '@backstage/plugin-techdocs';
import React from 'react';
import { TechDocsPageSubHeader } from '@mb.io/core-components';

const DefaultTechDocsPage = () => {
  return (
    <Page themeId="documentation">
      <TechDocsReaderPageHeader />
      <TechDocsPageSubHeader />
      <TechDocsReaderPageContent withSearch />
    </Page>
  );
};
export const techDocsPage = <DefaultTechDocsPage />;
