/**
 * Checks whether the given argument is a valid URL
 *
 * @param url URL to be validated
 * */
export function isValidUrl(url: string): boolean {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch {
    return false;
  }
}
