import { KubernetesValidatorFunctions } from '@backstage/catalog-model';
import { FieldValidation } from '@rjsf/utils';

export const validateCatalogEntityName = (
  value: string,
  validation: FieldValidation,
) => {
  if (!KubernetesValidatorFunctions.isValidObjectName(value)) {
    validation.addError(
      `Invalid Catalog entity name ${value}. Should follow Catalog naming convention: a string that is sequences of [a-zA-Z0-9] separated by any of [-_.], at most 63 characters in total. eg: vmds-service`,
    );
  }
};
