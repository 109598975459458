import { EntityValidation } from '../../../common';
import {
  INFRA_ENVIRONMENT_ANNOTATION,
  INFRA_PLATFORM_ANNOTATION,
  INFRA_TENANT_ANNOTATION,
} from '../annotations';
import {
  INFRA_ACCOUNT,
  INFRA_CACHE,
  INFRA_DOCUMENTDB,
  INFRA_DYNAMODB_GLOBAL_TABLE,
  INFRA_DYNAMODB_STREAM,
  INFRA_DYNAMODB_TABLE,
  INFRA_EFS,
  INFRA_EKS,
  INFRA_GATEWAY,
  INFRA_LAMBDA,
  INFRA_MEMORYDB,
  INFRA_MQ,
  INFRA_RDS_CLUSTER,
  INFRA_RDS_INSTANCE,
  INFRA_S3,
  INFRA_SEARCH,
  INFRA_SNS,
  INFRA_SQS,
} from '../constants';
import { InfrastructureEntity } from '../index';

export const AWS_RESOURCE_TYPE_MAPPING: Record<string, string> = {
  [INFRA_ACCOUNT]: 'account',
  [INFRA_DOCUMENTDB]: 'documentDbCluster',
  [INFRA_EFS]: 'fileSystem',
  [INFRA_EKS]: 'eksCluster',
  [INFRA_CACHE]: 'cacheCluster',
  [INFRA_GATEWAY]: 'gateway',
  [INFRA_LAMBDA]: 'lambda',
  [INFRA_MEMORYDB]: 'memoryDbCluster',
  [INFRA_MQ]: 'mqBroker',
  [INFRA_SEARCH]: 'searchDomain',
  [INFRA_RDS_INSTANCE]: 'rdsInstance',
  [INFRA_RDS_CLUSTER]: 'rdsCluster',
  [INFRA_S3]: 's3Bucket',
  [INFRA_SNS]: 'snsTopic',
  [INFRA_SQS]: 'sqsQueue',
  [INFRA_DYNAMODB_GLOBAL_TABLE]: 'globalTable',
  [INFRA_DYNAMODB_TABLE]: 'table',
  [INFRA_DYNAMODB_STREAM]: 'stream',
};

export const INFRASTRUCTURE_VALIDATIONS_SPEC: EntityValidation<InfrastructureEntity>[] =
  [
    {
      field: 'spec',
      validate: entity => {
        const resourceProperty = AWS_RESOURCE_TYPE_MAPPING[entity.spec.type];
        return resourceProperty in entity.spec;
      },
      message: entity => {
        const resourceProperty = AWS_RESOURCE_TYPE_MAPPING[entity.spec.type];
        return `Property ${resourceProperty} is required for type '${entity.spec.type}`;
      },
    },
    {
      field: 'spec.region',
      validate: entity => {
        return (
          entity.spec.type === 'account' ||
          entity.spec.type === 's3' ||
          entity.spec.region !== undefined
        );
      },
      message: entity => {
        return `Property 'spec.region' is required for type '${entity.spec.type}`;
      },
    },
    {
      field: `metadata.annotations[infra.mercedes-benz.com/platform]`,
      message: _ => `Annotation "${INFRA_PLATFORM_ANNOTATION}" is required.`,
      validate: entity =>
        entity.metadata.annotations?.[INFRA_PLATFORM_ANNOTATION] !== undefined,
    },
    {
      field: `metadata.annotations[infra.mercedes-benz.com/tenant]`,
      message: _ => `Annotation "${INFRA_TENANT_ANNOTATION}" is required.`,
      validate: entity =>
        entity.metadata.annotations?.[INFRA_TENANT_ANNOTATION] !== undefined,
    },
    {
      field: `metadata.annotations[infra.mercedes-benz.com/environment]`,
      message: _ => `Annotation "${INFRA_ENVIRONMENT_ANNOTATION}" is required.`,
      validate: entity =>
        entity.metadata.annotations?.[INFRA_ENVIRONMENT_ANNOTATION] !==
        undefined,
    },
  ];
