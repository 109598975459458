import React, { ReactNode } from 'react';
import { useUserFeatureValue } from '../hooks';

type UserFeatureFlagProps = {
  feature: string;
  defaultValue?: boolean;
  children: ReactNode;
};

export const UserFeatureFlagged = ({
  feature,
  defaultValue,
  children,
}: UserFeatureFlagProps) => {
  const userFeatureValue = useUserFeatureValue({ feature, defaultValue });
  return userFeatureValue ? <>{children}</> : <></>;
};
