import { JSONSchema6 } from 'json-schema';
import Schema from './system-role.alpha.schema.json';

export { SystemRoleEntityPolicy } from './system-role-entity.policy';
export type { SystemRoleAlphaEntity } from './system-role.alpha.entity';

export * from './types';

export const SystemRoleAlphaSchema: JSONSchema6 = Schema as Omit<
  JSONSchema6,
  'examples'
>;
