import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  ManifestClient,
  ManifestFile,
  SEAMLESS_PLUGIN_ID,
  SeamlessLivePreviewClient,
  SeamlessLivePreviewResponse,
} from '@mercedes-benz/seamless-common';

type AemRequest = {
  method: string;
  path: string;
  responseAsJson?: boolean;
  body?: never;
};

export const seamlessApiClientRef = createApiRef<SeamlessApiClient>({
  id: 'plugin.seamless.api',
});

export class SeamlessApiClient
  implements SeamlessLivePreviewClient, ManifestClient
{
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }

  deleteLivePreview(componentId: string): Promise<void> {
    return this.submitRequest({
      path: `/live-preview/${componentId}`,
      method: 'DELETE',
      responseAsJson: false,
    });
  }

  generateLivePreviewUploadUrl(
    componentId: string,
  ): Promise<SeamlessLivePreviewResponse> {
    return this.submitRequest({
      method: 'POST',
      path: `/live-preview/${componentId}`,
    });
  }

  getLivePreview(componentId: string): Promise<SeamlessLivePreviewResponse> {
    return this.submitRequest({
      method: 'GET',
      path: `/live-preview/${componentId}`,
    });
  }

  getManifestFiles(componentId?: string): Promise<ManifestFile[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/manifest/${componentId}`,
    });
  }

  async upload(
    preSignedUrl: string,
    file: File,
    contentType: string,
  ): Promise<void> {
    const response = await this.fetchApi.fetch(preSignedUrl, {
      method: 'PUT',
      headers: {
        'content-type': contentType,
      },
      body: file,
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
  }

  private async submitRequest<T = never>({
    path,
    method,
    responseAsJson = true,
    body,
  }: AemRequest): Promise<T> {
    const url = `${await this.discoveryApi.getBaseUrl(
      SEAMLESS_PLUGIN_ID,
    )}${path}`;
    const headers: Record<string, string> = {};
    const response = await this.fetchApi.fetch(url, { method, headers, body });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return responseAsJson ? response.json() : response.text();
  }
}
