import { UserEntity } from '@backstage/catalog-model';
import {
  BackstageUserIdentity,
  errorApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useVersionedContext } from '@backstage/version-bridge';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAsyncRetry from 'react-use/esm/useAsyncRetry';
import { IdentityLoadingStatus } from '../context';
import { parseEntityRef } from '../utils';
import { useIdentityRef } from './useIdentityRef';

/**
 * React hook to retrieve the authenticated user from logged-in user identity.
 *
 * @author manuel.santos@mercedes-benz.io
 * */
export const useUserIdentity = (
  shouldNavigate: boolean = false,
  hideAlert = false,
): IdentityLoadingStatus => {
  const errorApi = useApi(errorApiRef);
  const catalogApi = useApi(catalogApiRef);
  const navigate = useNavigate();
  const { identity } = useIdentityRef();

  const {
    loading,
    error,
    value: user,
    retry: refresh,
  } = useAsyncRetry(async () => {
    if (identity) {
      const userRef = parseEntityRef(identity.userEntityRef);

      const result = await catalogApi.getEntityByRef({
        kind: 'User',
        namespace: userRef.namespace ?? 'default',
        name: userRef.name,
      });
      return result as UserEntity;
    }
    return undefined;
  }, [catalogApi, identity]);

  useEffect(() => {
    if (!identity && !loading) {
      if (!hideAlert) {
        errorApi.post(new Error('No Backstage identity found!'));
      }
      if (shouldNavigate) {
        navigate('/');
      }
    }
  }, [
    errorApi,
    navigate,
    error,
    loading,
    user,
    identity,
    hideAlert,
    shouldNavigate,
  ]);

  return {
    user,
    identity,
    loading,
    error,
    refresh,
  };
};

/**
 * Grab the current user and identity from the context and its current loading state.
 *
 * @author manuel.santos@mercedes-benz.io
 * */
export const useUserIdentityFromContext = () => {
  const versionedHolder = useVersionedContext<{ 1: IdentityLoadingStatus }>(
    'identity-context',
  );

  if (!versionedHolder) {
    throw new Error('Identity context is not available');
  }

  const value = versionedHolder.atVersion(1);
  if (!value) {
    throw new Error('Identity context v1 not available');
  }

  const { user, identity, loading, error, refresh } = value;
  return {
    user: user as UserEntity,
    identity: identity as BackstageUserIdentity,
    loading,
    error,
    refresh,
  };
};
