import { isValidPrefix } from '@mb.io/catalog-model';
import { FieldValidation } from '@rjsf/utils';

export const validateSeamlessPrefix = (
  value: string,
  validation: FieldValidation,
) => {
  if (!isValidPrefix(value)) {
    validation.addError(
      `Invalid prefix ${value}. Allowed characters: a-z and number 0-9. Min 2 to 24 chars`,
    );
  }
};
