import { createRouteRef } from '@backstage/core-plugin-api';

export const esgHomeRouteRef = createRouteRef({
  id: 'esg',
});

export const esgCategoryRouteRef = createRouteRef({
  id: 'esg:category',
  params: ['categoryId'],
});

export const esgStaticDocRouteRef = createRouteRef({
  id: 'esg:static-doc',
  params: ['componentName', 'categoryName'],
});

export const esgDocsRouteRef = createRouteRef({
  id: 'esg:reader-page',
  params: ['namespace', 'kind', 'name'],
});
