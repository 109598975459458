import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { Tenant } from '@mb.io/tdms-common';
import {
  DefaultResponse,
  Endpoints,
  PossibleIntegrationTypes,
  Datasource,
  TenantsOverview,
  TenantIntegrations,
} from '@mercedes-benz/pmas-common';
import { submitRequest } from './submitRequest';

export class IntegrationsApi {
  private readonly discoveryApi;
  private readonly fetchApi;

  constructor(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  async getTenants(): Promise<Tenant[]> {
    const url = `${await this.discoveryApi.getBaseUrl('tdms')}`;
    const response = await this.fetchApi.fetch(`${url}/v1/tenants`);
    return response.json();
  }

  async getAllTenantOverview(): Promise<DefaultResponse<TenantsOverview[]>> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'GET',
      path: `/tenant/overview/all`,
    });
  }

  async getTenantIntegrations(
    name: string,
  ): Promise<DefaultResponse<TenantIntegrations>> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'GET',
      path: `/tenant/${name}`,
    });
  }

  async enableTenantIntegrations(
    name: string,
    status: string,
  ): Promise<DefaultResponse> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'POST',
      path: `/tenant/${name}/${status}`,
    });
  }

  async getIntegrations(
    integrationType: Endpoints,
  ): Promise<DefaultResponse<PossibleIntegrationTypes>> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'GET',
      path: `/integrations/${integrationType}`,
    });
  }

  async getIntegration(
    integrationId: number,
    integrationType: Endpoints,
  ): Promise<DefaultResponse<PossibleIntegrationTypes>> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'GET',
      path: `/integrations/${integrationType}/${integrationId}`,
    });
  }

  async getIntegrationsPerTenant<T = PossibleIntegrationTypes>(
    tenant: string,
    integrationType: Endpoints,
  ): Promise<DefaultResponse<T>> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'GET',
      path: `/integrations/${integrationType}/tenant/${tenant}`,
    });
  }

  async getDatasources(
    integrationType: Endpoints,
  ): Promise<DefaultResponse<Datasource[]>> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'GET',
      path: `/integrations/${integrationType}/datasources`,
    });
  }

  async createIntegration(
    integrationType: string,
    integration: object,
  ): Promise<DefaultResponse<PossibleIntegrationTypes>> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'POST',
      path: `/integrations/${integrationType}`,
      body: {
        integration: integration,
      },
    });
  }

  async updateIntegration(
    integrationType: string,
    integrationId: number,
    integration: object,
  ): Promise<DefaultResponse<PossibleIntegrationTypes>> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'PUT',
      path: `/integrations/${integrationType}/${integrationId}`,
      body: {
        integration: integration,
      },
    });
  }

  async deleteIntegration(
    integrationId: number,
    integrationType: string,
  ): Promise<DefaultResponse> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'DELETE',
      path: `/integrations/${integrationType}/${integrationId}`,
    });
  }
}
