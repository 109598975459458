import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import {
  BookingsApiClient,
  bookingsApiRef,
  EntitiesApiClient,
  entitiesApiRef,
  PackagesApiClient,
  packagesApiRef,
  ReportsApiClient,
  reportsApiRef,
  ServicesApiClient,
  servicesApiRef,
  TDMSApiClient,
  tdmsApiRef,
  TenantsApiClient,
  tenantsApiRef,
  UsersApiClient,
  usersApiRef,
} from './api';
import { rootRouteRef } from './routes';

export const tdmsNextPlugin = createPlugin({
  id: 'tdms-next',
  apis: [
    createApiFactory({
      api: tdmsApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        bookingsService: bookingsApiRef,
        entitiesService: entitiesApiRef,
        packagesService: packagesApiRef,
        reportsService: reportsApiRef,
        servicesService: servicesApiRef,
        tenantsService: tenantsApiRef,
        usersService: usersApiRef,
      },
      factory: ({
        bookingsService,
        entitiesService,
        packagesService,
        reportsService,
        servicesService,
        tenantsService,
        usersService,
      }) => {
        const dependencies = {
          bookingsService,
          entitiesService,
          packagesService,
          reportsService,
          servicesService,
          tenantsService,
          usersService,
        };
        return TDMSApiClient.create(dependencies);
      },
    }),
    createApiFactory({
      api: bookingsApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new BookingsApiClient({ discoveryApi, fetchApi }),
    }),
    createApiFactory({
      api: entitiesApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new EntitiesApiClient({ discoveryApi, fetchApi }),
    }),
    createApiFactory({
      api: packagesApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new PackagesApiClient({ discoveryApi, fetchApi }),
    }),
    createApiFactory({
      api: reportsApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new ReportsApiClient({ discoveryApi, fetchApi }),
    }),
    createApiFactory({
      api: servicesApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new ServicesApiClient({ discoveryApi, fetchApi }),
    }),
    createApiFactory({
      api: tenantsApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new TenantsApiClient({ discoveryApi, fetchApi }),
    }),
    createApiFactory({
      api: usersApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new UsersApiClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const TdmsNextPage = tdmsNextPlugin.provide(
  createRoutableExtension({
    name: 'TdmsNextPage',
    component: () => import('./App').then(m => m.App),
    mountPoint: rootRouteRef,
  }),
);
