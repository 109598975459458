import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

import { Entity } from '@backstage/catalog-model';
import { GITHUB_REPO_ANNOTATION } from '@mb.io/core-components';
import { rootRouteRef } from './routes';

export const isDependabotAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[GITHUB_REPO_ANNOTATION]);

export const githubDependabotPlugin = createPlugin({
  id: 'github-dependabot',
  routes: {
    root: rootRouteRef,
  },
});

export const DependabotScoreWidget = githubDependabotPlugin.provide(
  createComponentExtension({
    name: 'DependabotScoreWidget',
    component: {
      lazy: () =>
        import('./components/DependabotScoreWidget').then(
          m => m.DependabotScoreWidget,
        ),
    },
  }),
);

export const DependabotAlertsWidget = githubDependabotPlugin.provide(
  createComponentExtension({
    name: 'DependabotAlertsWidget',
    component: {
      lazy: () =>
        import('./components/DependabotAlertsWidget').then(
          m => m.DependabotAlertsWidget,
        ),
    },
  }),
);

export const DependabotAlertsTable = githubDependabotPlugin.provide(
  createComponentExtension({
    name: 'DependabotAlertsTable',
    component: {
      lazy: () =>
        import('./components/DependabotAlertsTable').then(
          m => m.DependabotAlertsTable,
        ),
    },
  }),
);

/**
 * Important: mountPoint was removed due to a limitation in the Backstage auto-mounting mechanism that doesn't recognize
 * components mounted using mountPoint if used as sub-components. They can only be used at upper-level.
 * */
export const DependabotContentPage = githubDependabotPlugin.provide(
  createComponentExtension({
    name: 'DependabotContentPage',
    component: {
      lazy: () =>
        import('./components/DependabotContentPage').then(
          m => m.DependabotContentPage,
        ),
    },
  }),
);
