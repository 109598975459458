import {
  ComponentEntity,
  Entity,
  getCompoundEntityRef,
  RELATION_MEMBER_OF,
  RELATION_OWNED_BY,
  stringifyEntityRef,
  UserEntity,
} from '@backstage/catalog-model';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { SeamlessEntity } from '@mb.io/catalog-model';

interface EntityCondition {
  (item: Entity): boolean;
}

export const and =
  (...conditions: EntityCondition[]) =>
  (entity: Entity) =>
    conditions.filter(condition => condition(entity)).length ===
    conditions.length;

export const or =
  (...conditions: EntityCondition[]) =>
  (entity: Entity) =>
    conditions.some(condition => condition(entity));

export const not = (condition: EntityCondition) => (entity: Entity) =>
  !condition(entity);

export const compare = (
  a: string | undefined,
  b: string | undefined,
): boolean =>
  Boolean(a && a?.toLocaleLowerCase('en-US') === b?.toLocaleLowerCase('en-US'));

export const isKind = (kind: string) => (entity: Entity) =>
  compare(entity?.kind, kind);

export const isType = (type: string) => (entity: Entity) => {
  const entityType = entity.spec?.type as string | undefined;
  return compare(entityType, type);
};

export const isTypes =
  (types: string[]) =>
  (entity: Entity): boolean => {
    return types.find(type => isType(type)(entity)) !== undefined;
  };

export const isComponentType = (type: string) => {
  return (entity: Entity) => {
    if (!compare(entity?.kind, 'component')) {
      return false;
    }
    const componentEntity = entity as ComponentEntity;
    return compare(componentEntity.spec.type, type);
  };
};

export const isSeamlessType = (type: string) => {
  return (entity: Entity) => {
    if (!compare(entity?.kind, 'seamless')) {
      return false;
    }
    const seamlessEntity = entity as SeamlessEntity;
    return compare(seamlessEntity.spec.type, type);
  };
};

export const isNamespace = (namespace: string) => (entity: Entity) =>
  compare(entity?.metadata?.namespace, namespace);

export const canProvideAPIs = (entity: Entity) =>
  entity.spec?.type === 'service' || entity.spec?.type === 'tsc-node';

export const canConsumeAPIs = (entity: Entity) => {
  switch (entity.spec?.type) {
    case 'service':
    case 'website':
    case 'library':
    case 'tsc-node':
    case 'tsc-web':
    case 'vue-library':
    case 'vue-component':
    case 'stencil-component':
    case 'workbench-component':
      return true;
    default:
      return false;
  }
};

export const isCustom = (type: string) => {
  switch (type) {
    case 'mono-repo':
    case 'vue-package':
    case 'stencil-package':
    case 'tsc-node':
    case 'tsc-web':
    case 'vue-library':
    case 'vue-component':
    case 'stencil-component':
    case 'workbench-component':
    case 'infrastructure':
    case 'kubernetes':
    case 'catalog':
    case 'ci-cd':
      return true;
    default:
      return false;
  }
};

export const isPackage = (entity: Entity) =>
  or(isType('vue-package'), isType('stencil-package'))(entity);

export const isContainer = (entity: Entity): boolean =>
  or(isType('mono-repo'), isPackage)(entity);

export const isSeamlessModule = (entity: Entity): boolean =>
  isKind('seamless')(entity) && not(isType('mono-repo'))(entity);

export const isSeamlessComponent = (entity: Entity) =>
  or(
    isType('vue-component'),
    isType('stencil-component'),
    isType('workbench-component'),
  )(entity);

export const isOwned = (user: UserEntity, entity: Entity) => {
  const possibleOwners = new Set(
    [
      ...getEntityRelations(user, RELATION_MEMBER_OF),
      ...(user ? [getCompoundEntityRef(user)] : []),
    ].map(stringifyEntityRef),
  );

  const owners = getEntityRelations(entity, RELATION_OWNED_BY).map(
    stringifyEntityRef,
  );

  for (const ownerItem of owners) {
    if (possibleOwners.has(ownerItem)) {
      return true;
    }
  }
  return false;
};

export const isLinksAvailable = (entity: Entity): boolean => {
  const links = entity?.metadata?.links;
  return !(!links || links.length === 0);
};
