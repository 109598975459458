import React from 'react';
import { CATEGORY_ICONS } from './icons';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  categoryIcon: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    width: '50px',
    height: '50px',
    padding: theme.spacing(1),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& svg': {
      fill: '#fff',
    },
  },
}));

const mapCategory = (templateType: string) => {
  switch (templateType) {
    case 'service':
      return 'backend';
    case 'ci-cd':
      return 'cicd';
    case 'mono-repo':
    case 'stencil-component':
    case 'stencil-package':
    case 'tsc-node':
    case 'tsc-web':
    case 'vue-component':
    case 'vue-library':
    case 'vue-package':
      return 'seamless';
    default:
      return templateType;
  }
};
export const ScaffolderCategoryIcon = (options: { category: string }) => {
  const { classes } = useStyles();

  return (
    <div className={`${classes.categoryIcon} icon`}>
      {CATEGORY_ICONS[mapCategory(options.category)] ?? <LibraryBooksIcon />}
    </div>
  );
};
