import { UserEntity } from '@backstage/catalog-model';
import { BackstageUserIdentity } from '@backstage/core-plugin-api';
import {
  createVersionedContext,
  createVersionedValueMap,
} from '@backstage/version-bridge';
import React, { ReactNode } from 'react';

export type IdentityLoadingStatus = {
  user?: UserEntity;
  identity?: BackstageUserIdentity;
  loading: boolean;
  error?: Error;
  refresh?: VoidFunction;
};

/**
 * Properties for the AsyncIdentityProvider component.
 */
export interface AsyncIdentityProviderProps extends IdentityLoadingStatus {
  children: ReactNode;
}

export const IdentityContext = createVersionedContext<{
  1: IdentityLoadingStatus;
}>('identity-context');

/**
 * Provides a loaded user identity to be picked up by the `useIdentity` hook.
 */
export const AsyncIdentityProvider = ({
  children,
  identity,
  user,
  loading,
  error,
  refresh,
}: AsyncIdentityProviderProps) => {
  const value = { identity, user, loading, error, refresh };
  // We provide both the old and the new context, since
  // consumers might be doing things like `useContext(IdentityContext)`
  return (
    <IdentityContext.Provider value={createVersionedValueMap({ 1: value })}>
      {children}
    </IdentityContext.Provider>
  );
};
