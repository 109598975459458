import type { IdentityApi } from '@backstage/core-plugin-api';

export class PostLoginManager {
  private static readonly TOKEN_EXPIRY_BUFFER_MS = 4 * 60 * 1000; // 4 minutes
  private static readonly MINIMUM_DELAY_MS = 10000; // 10 seconds

  constructor(private identityApi: IdentityApi) {}

  public async setTokenCookie(url: string): Promise<void> {
    const { token } = await this.identityApi.getCredentials();
    if (!token) {
      return;
    }

    await this.fetchWithToken(url, token);
    this.scheduleNextTokenRefresh(url, token);
  }

  private async fetchWithToken(url: string, token: string): Promise<void> {
    await fetch(url, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private scheduleNextTokenRefresh(url: string, token: string): void {
    const ms =
      PostLoginManager.msUntilExpiry(token) -
      PostLoginManager.TOKEN_EXPIRY_BUFFER_MS;
    setTimeout(
      () => {
        void this.setTokenCookie(url);
      },
      Math.max(ms, PostLoginManager.MINIMUM_DELAY_MS),
    );
  }

  private static parseJwt(token: string): { exp: number } {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(c => PostLoginManager.toUrlEncoded(c))
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  private static msUntilExpiry(token: string): number {
    const payload = PostLoginManager.parseJwt(token);
    return new Date(payload.exp * 1000).getTime() - new Date().getTime();
  }

  private static toHexadecimal(char: string): string {
    return char.charCodeAt(0).toString(16).padStart(2, '0');
  }

  private static toUrlEncoded(char: string): string {
    return `%${PostLoginManager.toHexadecimal(char)}`;
  }
}
