import React from 'react';
import { makeStyles } from 'tss-react/mui';
import './WithRipple.css';

type Props = {
  onClick: Function;
  children: React.ReactNode;
  'data-testid'?: string;
};

const useStyles = makeStyles()({
  rippleContainer: {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    transition: 'background 200ms',
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
});

export function WithRipple({
  onClick,
  children,
  'data-testid': testId,
}: Props) {
  const { classes } = useStyles();

  function createRipple(
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) {
    const targetElement = event.currentTarget;

    const diameter = Math.max(
      targetElement.clientWidth,
      targetElement.clientHeight,
    );
    const radius = diameter / 2;

    const circle = document.createElement('span');
    circle.style.width = circle.style.height = `${diameter}px`;

    let centerX: number;
    let centerY: number;

    if ((event as React.MouseEvent).pageX) {
      const mouseEvent = event as React.MouseEvent;
      centerX = mouseEvent.pageX - targetElement.offsetLeft - radius;
      const scrollY = document.querySelector('#root main')?.scrollTop || 0;
      centerY = mouseEvent.pageY - targetElement.offsetTop - radius + scrollY;
    } else {
      centerX = targetElement.clientWidth / 2 - radius;
      centerY = targetElement.clientHeight / 2 - radius;
    }

    circle.style.left = `${centerX}px`;
    circle.style.top = `${centerY}px`;

    const oldRipple = targetElement.getElementsByClassName('ripple')[0];
    if (oldRipple) {
      oldRipple.remove();
    }

    circle.classList.add('ripple');
    targetElement.appendChild(circle);
  }

  function handleClick(
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) {
    createRipple(event);
    setTimeout(() => {
      onClick();
    }, 150);
  }

  return (
    <div
      data-testid={testId}
      className={classes.rippleContainer}
      onClick={handleClick}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === 'Enter' || event.code === 'Space') {
          event.preventDefault();
          handleClick(event);
        }
      }}
      role="button"
      tabIndex={0}
      children={children}
    />
  );
}
