import { createApiRef } from '@backstage/core-plugin-api';
import { CrossChargingOverviewType, ReportsService } from '@mb.io/tdms-common';
import { actionPermissions } from '../permissions';
import { ApiClient } from './ApiClient';
import { API_VERSION } from './constants';

export const reportsApiRef = createApiRef<ReportsService>({
  id: 'plugin.tdms-next.reports-api',
});

export class ReportsApiClient extends ApiClient implements ReportsService {
  getCrossChargingOverview(): Promise<CrossChargingOverviewType[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/reports/charging`,
      },
      actionPermissions.crossChargingOverview.read,
    );
  }

  async exportCrossChargingOverview(format?: string): Promise<any> {
    const response: Response = await this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/reports/charging/export/${format?.toUpperCase()}`,
        responseAsJson: false,
      },
      actionPermissions.crossChargingOverview.read,
    );
    const blob = await response.blob();
    this.downloadBlob(blob, `CostOverviewReport.${format}`);
  }

  listEmailReport(body: {
    packageSlugs: string[];
    permissionSlugs: string[];
    providers: string[];
    roleNames: string[];
  }): Promise<string[]> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/emails/reports`,
        body,
      },
      actionPermissions.emailAnnouncements.read,
    );
  }

  sendCustomEmails(body: {
    inputTenantEmailFilterDto: {
      packageSlugs: string[];
      permissionSlugs: string[];
      providers: string[];
      roleNames: string[];
    };
    html: string;
    subject: string;
    testRecipientEmail?: string;
  }): Promise<string> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/emails/send`,
        body,
      },
      actionPermissions.emailAnnouncements.update,
    );
  }

  private downloadBlob(blob: Blob, name: string) {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);
    // Create a link element
    const link = document.createElement('a');

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
    document.body.removeChild(link);
  }
}
