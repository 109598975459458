export type Codelab = {
  id: string;
  summary: string;
  updated?: string;
  author?: string[];
  title?: string;
  status?: string;
  feedbackLink?: string;
  source: string;
  url: string;
  duration?: number;
  weight?: number;
  highlight?: boolean;
  tags?: string[];
  categories?: string[];
  environments?: string[];
};

export type Link = {
  provider: string; // Udemy, Confluence, etc
  url: string;
  previewUrl?: string;
  learningPath?: boolean;
};

export type Presentation = {
  event?: string;
  year?: number;
  videoUrl?: string;
  slideUrl?: string;
};

export const TUTORIAL = 'tutorial';
export const SNIPPET = 'snippet';
export const COURSE = 'course';
export const PAGE = 'page';
export const PRESENTATION = 'presentation';

export const ACADEMY_TYPES = [TUTORIAL, SNIPPET, COURSE, PAGE, PRESENTATION];
