import { FEATURE_IDENTITY_SCANNING } from '@mercedes-benz/user-settings-common';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useOptOutState } from '../../hooks';

const useStyles = makeStyles({ name: 'IdentityScanningBanner' })(theme => ({
  root: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  // showing on top
  topPosition: {
    position: 'relative',
    marginBottom: theme.spacing(6),
    marginTop: -theme.spacing(3),
    zIndex: 'unset',
  },
  content: {
    width: '100%',
    maxWidth: 'inherit',
    flexWrap: 'nowrap',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.banner.text,
    '& a': {
      color: theme.palette.banner.link,
    },
  },
  warning: {
    backgroundColor: theme.palette.banner.warning ?? theme.palette.banner.error,
  },
}));

export type Props = {
  fixed?: boolean;
};

export const IdentityScanningBanner = (props: Props) => {
  const { optOut } = useOptOutState(FEATURE_IDENTITY_SCANNING);
  const { classes, cx } = useStyles();
  const { fixed } = props;

  return optOut ? (
    <Snackbar
      anchorOrigin={
        fixed
          ? { vertical: 'bottom', horizontal: 'center' }
          : { vertical: 'top', horizontal: 'center' }
      }
      open
      classes={{
        root: cx(classes.root, !fixed && classes.topPosition),
      }}
    >
      <SnackbarContent
        classes={{
          root: cx(classes.content, classes.warning),
          message: classes.message,
        }}
        message={
          <Typography>
            Backstage identity scanning is disabled for your user, meaning that
            Backstage will not track your user information or relate your user
            with any entity, team or system.
            <br />
            🔐 You are using Backstage in <b>read-only</b> mode (no ownership,
            no permissions, no system roles, etc).
          </Typography>
        }
      />
    </Snackbar>
  ) : (
    <></>
  );
};
