import Grid from '@mui/material/Grid';
import {
  EntityLayout,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  hasCatalogProcessingErrors,
} from '@backstage/plugin-catalog';
import { EntityFeedbackResponseContent } from '@backstage-community/plugin-entity-feedback';
import { SystemRolesEntityCard } from '@mercedes-benz/user-settings';
import React from 'react';
import { EntityAboutCard } from '../../about-card';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';

export const SystemRolePage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch" direction="row">
        <EntitySwitch>
          <EntitySwitch.Case if={hasCatalogProcessingErrors}>
            <Grid item xs={12}>
              <EntityProcessingErrorsPanel />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
        <Grid item md={6} className="grid-item">
          <EntityAboutCard />
        </Grid>
        <Grid item md={12} className="grid-item">
          <SystemRolesEntityCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
