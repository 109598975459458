import { Entity } from '@backstage/catalog-model';
import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import {
  BLACKDUCK_ANNOTATION,
  BLACKDUCK_PLUGIN_ID,
} from '@mercedes-benz/blackduck-common';
import { BlackDuckApiClient, blackDuckApiRef } from './api';
import { rootRouteRef } from './routes';

export const isBlackDuckAvailable = (entity: Entity) =>
  Boolean(entity?.metadata.annotations?.[BLACKDUCK_ANNOTATION]);

export const blackDuckPlugin = createPlugin({
  id: BLACKDUCK_PLUGIN_ID,
  apis: [
    createApiFactory({
      api: blackDuckApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new BlackDuckApiClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const EntityBlackDuckContent = blackDuckPlugin.provide(
  createComponentExtension({
    name: 'BlackDuckContentPage',
    component: {
      lazy: () =>
        import('./components/BlackDuckContentPage').then(
          m => m.BlackDuckContentPage,
        ),
    },
  }),
);
