import { RESOURCE_TYPE_CATALOG_ENTITY } from '@backstage/plugin-catalog-common/alpha';
import { ResourcePermission } from '@backstage/plugin-permission-common';

/**
 * @public
 */
export const ownershipPermission: ResourcePermission<'catalog-entity'> = {
  name: 'ownership.permission',
  attributes: {},
  resourceType: RESOURCE_TYPE_CATALOG_ENTITY,
  type: 'resource',
};
