import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { DwsApi } from './DwsApi';
import { WebHook, WebHookRequest, WebHookResponse } from './types';
import { systemRoleFetcher } from '@mercedes-benz/permission';
import {
  ActionType,
  RoleActionPayload,
} from '@mercedes-benz/permission-common';

export class DwsApiClient implements DwsApi {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }

  getAllWebHooks(): Promise<WebHookResponse[]> {
    return this.submitRequest({
      path: `/webhook`,
      method: 'GET',
      responseAsJson: true,
    });
  }

  async createNewWebHook(webHook: WebHook): Promise<Response> {
    return await this.submitRequest({
      path: '/webhook',
      method: 'POST',
      body: webHook,
      responseAsJson: false,
    });
  }

  async deleteWebHook(name: string): Promise<void> {
    await this.submitRequest({
      path: `/webhook/${name}`,
      method: 'DELETE',
      responseAsJson: false,
    });
  }

  async updateWebHook(
    webHook: WebHook,
    webHookNameId: string,
  ): Promise<Response> {
    return await this.submitRequest({
      path: `/webhook/${webHookNameId}`,
      method: 'PUT',
      body: webHook,
      responseAsJson: false,
    });
  }

  async getAllTenantsByUser(): Promise<string[]> {
    return await this.submitRequest({
      path: `/user/membership`,
      method: 'GET',
      responseAsJson: true,
    });
  }

  private async submitRequest({
    path,
    method,
    body,
    responseAsJson,
  }: WebHookRequest): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('dws')}${path}`;

    const headers: Record<string, string> = {
      'content-type': 'application/json',
    };

    const ref: RoleActionPayload = {
      actionType: ActionType.ROLE,
      details: {
        checkRoleEverywhere: true,
        role: {
          allOf: ['PO', 'USER'],
        },
      },
    };

    const response = await systemRoleFetcher(
      this.fetchApi.fetch,
      url,
      {
        method,
        headers,
        body: JSON.stringify(body),
      },
      ref,
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return responseAsJson ? response.json() : response;
  }
}
