import Chip from '@mui/material/Chip';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { ItemCardHeader, LinkButton } from '@backstage/core-components';
import { ExploreTool } from '@backstage-community/plugin-explore-common';
import React from 'react';

type Props = {
  card: ExploreTool;
};

export const ToolCard = ({ card }: Props) => {
  const { title, description, url, lifecycle, tags } = card;

  return (
    <Card key={title}>
      <CardMedia>
        <ItemCardHeader title={title} subtitle={lifecycle} />
      </CardMedia>
      <CardContent>
        {description ?? 'Description missing'}
        {tags && (
          <Box marginTop={2}>
            {tags.map((item, idx) => (
              <Chip size="small" key={idx} label={item} />
            ))}
          </Box>
        )}
      </CardContent>
      <CardActions>
        <LinkButton color="primary" to={url} disabled={!url}>
          Explore
        </LinkButton>
      </CardActions>
    </Card>
  );
};
