export type Provider = {
  name: string;
  tenants: TenantRole[];
};

export type Booking = {
  name: string;
  slug: string;
  permissions: BookingServicePermission[];
  services?: BookingService[];
};

export type BookingServicePermission = {
  id: number;
  name: string;
  slug: string;
  description?: string;
  serviceName: string;
  serviceSlug: string;
};

export type BookingService = {
  name: string;
  slug: string;
  properties: Record<string, string>;
};

export type TenantRole = {
  name: string;
  roles: string[];
  bookings: Booking[];
};

export enum ExternalSystem {
  TDMS = 'TDMS',
}
