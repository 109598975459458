import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';
import React, { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';
import { LinkButton } from '@backstage/core-components';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles()(theme => ({
  image: {
    display: 'block',
    borderRadius: '2px',
    width: '100%',
    height: 'auto',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    zIndex: 1,
  },
  button: {
    display: 'inline-flex',
  },
  secondaryButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    display: 'inline-flex',
  },
  alignItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type ButtonProps = {
  text: string;
  link?: string;
  target?: string;
  icon?: ReactNode;
  onClick?: () => void;
};

type HomeIllustrationProps = Record<string, any> & { prefersDarkMode: boolean };

type HeroComponentProps = {
  heading: string;
  isBoxedLayout?: boolean;
  image?: string;
  ImageComponent?: React.ComponentType<
    React.PropsWithChildren<HomeIllustrationProps>
  >;
  children?: ReactNode;
  button?: ButtonProps;
  secondaryButton?: ButtonProps;
  className?: string;
};

export const HeroComponent = ({
  heading,
  isBoxedLayout = true,
  image,
  ImageComponent,
  children,
  button,
  secondaryButton,
  className,
}: HeroComponentProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const darkMode = theme.palette.mode === 'dark';

  return (
    <Grid container spacing={2} className={className}>
      <Grid
        item
        sm={12}
        md={6}
        className={!isBoxedLayout ? classes.alignItems : ''}
      >
        <Box paddingRight={4}>
          <Typography variant="h1" gutterBottom>
            {heading}
          </Typography>
          {children && (
            <Typography variant="subtitle1" component="div" gutterBottom>
              {children}
            </Typography>
          )}
        </Box>
        <Box display="flex" marginTop={4}>
          {button &&
            (button.link ? (
              <LinkButton
                variant="contained"
                color="primary"
                size="large"
                to={button.link}
                target={button.target}
                className={classes.button}
              >
                {button.text}
              </LinkButton>
            ) : (
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={button.onClick}
                className={classes.button}
              >
                {button.text}
              </Button>
            ))}
          {secondaryButton &&
            (secondaryButton.link ? (
              <LinkButton
                variant="text"
                size="large"
                className={classes.secondaryButton}
                to={secondaryButton.link}
                target={secondaryButton.target}
              >
                {secondaryButton.text}
                {secondaryButton.icon ?? <ArrowForward />}
              </LinkButton>
            ) : (
              <Button
                size="large"
                variant="text"
                className={classes.secondaryButton}
                onClick={secondaryButton.onClick}
              >
                {secondaryButton.text}
                {secondaryButton.icon ?? <DoneIcon />}
              </Button>
            ))}
        </Box>
      </Grid>
      {!isMobile && (
        <Grid item sm={12} md={6}>
          {ImageComponent && (
            <ImageComponent
              prefersDarkMode={darkMode}
              style={{ position: 'relative', zIndex: 1, maxHeight: '650px' }}
            />
          )}
          {image && <img src={image} alt={heading} className={classes.image} />}
        </Grid>
      )}
    </Grid>
  );
};
