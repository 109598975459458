import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { SEAMLESS_PLUGIN_ID } from '@mercedes-benz/seamless-common';
import {
  SeamlessApiClient,
  seamlessApiClientRef,
} from './api/seamless-api.client';
import { rootRouteRef } from './routes';

export const aemPlugin = createPlugin({
  id: SEAMLESS_PLUGIN_ID,
  apis: [
    createApiFactory({
      api: seamlessApiClientRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new SeamlessApiClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const AemPage = aemPlugin.provide(
  createRoutableExtension({
    name: 'AemPage',
    component: () =>
      import('./components/AemManifestPage').then(m => m.AemManifestPage),
    mountPoint: rootRouteRef,
  }),
);

export const PreviewPage = aemPlugin.provide(
  createRoutableExtension({
    name: 'PreviewPage',
    component: () =>
      import('./components/ComponentPreviewPage').then(
        m => m.ComponentPreviewPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
