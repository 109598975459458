import Grid from '@mui/material/Grid';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ContentPageLayout } from '@mb.io/core-components';
import {
  EntityGithubPullRequestsOverviewCard,
  EntityGithubPullRequestsTable,
  isGithubPullRequestsAvailable,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import React from 'react';

export const GithubPullRequestsContentPage = () => {
  const { entity } = useEntity();

  return (
    <ContentPageLayout
      title="Pull Requests"
      themeId="tool"
      supportTitle="Plugin to show a project's pull requests on GitHub"
    >
      <Grid container spacing={3} alignItems="stretch">
        {isGithubPullRequestsAvailable(entity) && (
          <Grid item xs={12} md={6}>
            <EntityGithubPullRequestsOverviewCard />
          </Grid>
        )}
        <Grid item xs={12}>
          <EntityGithubPullRequestsTable />
        </Grid>
      </Grid>
    </ContentPageLayout>
  );
};
