import { InfoCard } from '@backstage/core-components';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import ReactJson from 'react-json-view';
import { ManifestProps } from '../AemManifestPage';

export const ManifestJSONCard = ({ manifestFile }: ManifestProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <InfoCard title="Manifest JSON Data Viewer" key="test">
      <ReactJson
        theme={isDarkMode ? 'shapeshifter' : 'shapeshifter:inverted'}
        collapsed={2}
        src={manifestFile ?? {}}
      />
    </InfoCard>
  );
};
