import { JSONSchema6 } from 'json-schema';
import Schema from './seamless.schema.json';

export * from './relations';
export { SeamlessEntityPolicy } from './seamless-entity-policy';
export type { SeamlessAlphaEntity as SeamlessEntity } from './seamless.alpha.entity';
export * from './types';
export * from './seamless-entity-validations';

/**
 * @public
 */
export const SeamlessAlphaSchema: JSONSchema6 = Schema as Omit<
  JSONSchema6,
  'examples'
>;
