import { CatalogApi } from '@backstage/catalog-client';
import { DEFAULT_NAMESPACE } from '@backstage/catalog-model';
import { AlertApi, ConfigApi } from '@backstage/core-plugin-api';
import { ExploreApi } from '@backstage-community/plugin-explore';
import { GetExploreToolsResponse } from '@backstage-community/plugin-explore-common';
import { ContentEntity, ContentType } from '@mb.io/catalog-model';
import { parseResourceRef } from '@mb.io/core-components';

export class ToolsConfigApi implements ExploreApi {
  constructor(
    private readonly catalogApi: CatalogApi,
    private readonly alertApi: AlertApi,
    private readonly configApi: ConfigApi,
  ) {}

  async getTools(): Promise<GetExploreToolsResponse> {
    const config = this.configApi.getOptionalString('app.tools.ref');

    if (config) {
      const toolsConfigRef = parseResourceRef(config, {
        defaultKind: 'Content',
        defaultNamespace: DEFAULT_NAMESPACE,
      });

      const content: ContentEntity = (await this.catalogApi.getEntityByRef(
        toolsConfigRef,
      )) as ContentEntity;

      if (!content) {
        throw new Error(`Content entity for tools configurations not found!`);
      }

      if (content.spec.type === ContentType.JSON && content.spec.data) {
        try {
          return {
            tools: JSON.parse(content.spec.data),
          } as GetExploreToolsResponse;
        } catch (parsingException) {
          const message = `Malformed content for tools resource. Please contact support for more details.`;
          this.alertApi.post({
            message: message,
            severity: 'error',
          });
        }
      }
    } else {
      this.alertApi.post({
        message: `No configuration provided for the tools content. Please specify the content resource where the data should be fetched, providing 'app.tools' configuration property. e.g: 'app.tools: content:default/tools-resource-name'`,
        severity: 'warning',
      });
    }
    return { tools: [] };
  }
}
