import { Entity, getEntitySourceLocation } from '@backstage/catalog-model';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

export const useGitHubConfig = (entity: Entity) => {
  const config = useApi(configApiRef);
  const providerConfigs =
    config.getOptionalConfigArray('integrations.github') ?? [];

  const location = getEntitySourceLocation(entity);

  if (location.type === 'url' && location.target.length > 0) {
    // Try to match a provider config with catalog location (being a github.com or github enterprise domain)
    const locationHost = new URL(location.target).host;
    return providerConfigs.find(
      providerConfig =>
        providerConfig?.getOptionalString('host') === locationHost,
    );
  }
  return providerConfigs[0];
};
