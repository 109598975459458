export const SEAMLESS_MONOREPO = 'mono-repo';

export const SEAMLESS_TYPESCRIPT_NODEJS = 'tsc-node';

export const SEAMLESS_TYPESCRIPT_WEB = 'tsc-web';

export const SEAMLESS_VUE_PACKAGE = 'vue-package';

export const SEAMLESS_VUE_LIBRARY = 'vue-library';

export const SEAMLESS_VUE_COMPONENT = 'vue-component';

export const SEAMLESS_STENCIL_PACKAGE = 'stencil-package';

export const SEAMLESS_STENCIL_COMPONENT = 'stencil-component';

export const SEAMLESS_WORKBENCH_COMPONENT = 'workbench-component';

export const SEAMLESS_PACKAGE_CONTAINED_TYPES = [
  SEAMLESS_VUE_COMPONENT,
  SEAMLESS_STENCIL_COMPONENT,
  SEAMLESS_WORKBENCH_COMPONENT,
];

export const SEAMLESS_PROJECT_CONTAINED_TYPES = [
  SEAMLESS_TYPESCRIPT_NODEJS,
  SEAMLESS_TYPESCRIPT_WEB,
  SEAMLESS_VUE_PACKAGE,
  SEAMLESS_VUE_LIBRARY,
  SEAMLESS_STENCIL_PACKAGE,
  ...SEAMLESS_PACKAGE_CONTAINED_TYPES,
];

export const SEAMLESS_TYPES = [
  SEAMLESS_MONOREPO,
  ...SEAMLESS_PROJECT_CONTAINED_TYPES,
];
