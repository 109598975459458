import {
  DefaultCardHeader,
  OverviewField,
  useCardClasses,
} from '@mb.io/core-components';
import { Artifact, RepositoryId } from '@mb.io/harbor-common';
import LayersIcon from '@mui/icons-material/Layers';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { withStyles } from 'tss-react/mui';
import { ScanButton } from '../ScanButton';

const StyledChip = withStyles(Chip, {
  root: {
    borderRadius: '5px',
    maxWidth: '100%',
  },
});

type Props = {
  variant: 'gridItem' | 'fullHeight';
  repositoryId: RepositoryId;
  artifact: Artifact;
};

export const ArtifactOverviewCard = ({
  variant,
  artifact,
  repositoryId,
}: Props) => {
  const { cardClass, cardContentClass } = useCardClasses(variant);
  const latestArtifactUrl = `${artifact.repoUrl}/artifacts-tab/artifacts/${artifact.digest}`;

  return (
    <Card className={cardClass}>
      <DefaultCardHeader
        title="Latest Image"
        action={
          <>
            <ScanButton
              artifactSha256={artifact.digest}
              repositoryId={repositoryId}
            />
            <IconButton
              aria-label="View Latest Image"
              title="View Latest Image"
              href={latestArtifactUrl}
              target="_blank"
              size="large"
            >
              <LayersIcon />
            </IconButton>
          </>
        }
      />
      <Divider />
      <CardContent className={cardContentClass}>
        <Grid container>
          <OverviewField
            label="Author"
            defaultValue="No Author"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={artifact?.extraAttributes?.author}
          />
          <OverviewField
            label="Created At"
            defaultValue="No Creation Date"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={artifact?.extraAttributes?.created}
          />
          <OverviewField
            label="Pushed At"
            defaultValue="No push time"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={artifact.pushTime}
          />
          <OverviewField
            label="Size"
            defaultValue="No image size"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={artifact.size}
            suffix="MB"
          />
          <OverviewField
            label="Architecture"
            defaultValue="No Architecture"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={artifact?.extraAttributes?.architecture}
          />
          <OverviewField
            label="OS"
            defaultValue="No OS"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={artifact?.extraAttributes?.os}
          />
          <OverviewField
            label="Environment"
            defaultValue="No Environment"
            gridSizes={{ xs: 12, sm: 6 }}
          >
            {(artifact?.extraAttributes?.environment || []).map(envEntry => (
              <StyledChip key={envEntry} size="small" label={envEntry} />
            ))}
          </OverviewField>
          <OverviewField
            label="Tags"
            defaultValue="No Tags"
            gridSizes={{ xs: 12, sm: 6 }}
          >
            {(artifact.tags || []).map(tag => (
              <StyledChip key={tag} size="small" label={tag} />
            ))}
          </OverviewField>
        </Grid>
      </CardContent>
    </Card>
  );
};
