import { exploreApiRef } from '@backstage-community/plugin-explore';
import React from 'react';
import useAsync from 'react-use/esm/useAsync';
import { ToolCard } from '../ToolCard';

import {
  Content,
  ContentHeader,
  EmptyState,
  ItemCardGrid,
  Progress,
  SupportButton,
  WarningPanel,
} from '@backstage/core-components';

import { useApi } from '@backstage/core-plugin-api';

const Body = () => {
  const exploreToolsConfigApi = useApi(exploreApiRef);
  const {
    value: tools,
    loading,
    error,
  } = useAsync(async () => {
    const response = await exploreToolsConfigApi.getTools();
    return response.tools;
  }, [exploreToolsConfigApi]);

  // Order tools by name
  tools?.sort((a, b) => {
    if (a.title < b.title) return -1;
    return 1;
  });

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <WarningPanel title="Failed to load tools" />;
  }

  if (!tools?.length) {
    return (
      <EmptyState
        missing="info"
        title="No tools to display"
        description="You haven't added any tools yet."
      />
    );
  }

  return (
    <ItemCardGrid>
      {tools.map((tool, index) => (
        <ToolCard key={index} card={tool} />
      ))}
    </ItemCardGrid>
  );
};

type ToolExplorerContentProps = {
  title?: string;
};

export const ToolExplorerContent = ({ title }: ToolExplorerContentProps) => (
  <Content noPadding>
    <ContentHeader title={title ?? 'Tools'}>
      <SupportButton>Discover the tools in your ecosystem.</SupportButton>
    </ContentHeader>
    <Body />
  </Content>
);
