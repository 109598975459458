import Typography from '@mui/material/Typography';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { CodeSnippet } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ContentEntity } from '@mb.io/catalog-model';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { ContentPageLayout } from '../ContentPageLayout';

const useStyles = makeStyles()(theme => ({
  code: {
    borderRadius: 6,
    margin: `${theme.spacing(2)} 0px`,
    background: theme.palette.mode === 'dark' ? '#444' : '#fff',
  },
}));

type Props = {
  title?: string;
  supportTitle?: string;
  themeId?: string;
};

export const RowContentPage = ({ title, supportTitle, themeId }: Props) => {
  const { classes } = useStyles();
  const { entity } = useEntity<ContentEntity>();

  const contentTitle = title ?? `${entity.spec.type.toUpperCase()} Content`;

  return (
    <ContentPageLayout
      title={contentTitle}
      themeId={themeId}
      supportTitle={supportTitle}
    >
      {entity.spec.data ? (
        <div className={classes.code}>
          <CodeSnippet
            text={entity.spec.data}
            language={entity.spec.type.toLowerCase()}
            showLineNumbers
            showCopyCodeButton
            customStyle={{ background: 'inherit', fontSize: '115%' }}
          />
        </div>
      ) : (
        <Typography variant="body1">
          No data provided for content entity {stringifyEntityRef(entity)}.
          Please provide valid content for type {entity.spec.type}.
        </Typography>
      )}
    </ContentPageLayout>
  );
};
