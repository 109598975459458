import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  icon: {
    fill: theme.palette.getContrastText(theme.palette.grey.A400),

    '&.primary': {
      fill: theme.palette.primary.light,
    },
    '&.secondary': {
      fill: theme.palette.primary.main,
    },
    '&.focused': {
      fontWeight: 'bold',
    },
  },
}));

export const useThemedIconClasses = () => {
  const { classes, cx } = useStyles();
  return cx(classes.icon, 'focused', 'primary', 'secondary');
};
