import { Entity, EntityPolicy } from '@backstage/catalog-model';
import { EntityValidator } from '../../common';
import { Kind } from '../../kind-type';
import { InfrastructureEntity } from './index';
import { INFRASTRUCTURE_VALIDATIONS_SPEC } from './validations/infrastructure-entity-validations';

/**
 * Ensures that the format of individual fields of the InfrastructureEntity entity envelope
 * is valid, by applying a Infrastructure validation spec {@link INFRASTRUCTURE_VALIDATIONS_SPEC}.
 */
export class InfrastructureEntityPolicy implements EntityPolicy {
  constructor(
    private readonly validator = new EntityValidator<InfrastructureEntity>(
      INFRASTRUCTURE_VALIDATIONS_SPEC,
    ),
  ) {
    this.validator = validator;
  }

  async enforce(entity: InfrastructureEntity): Promise<Entity> {
    if (entity.kind === Kind.INFRASTRUCTURE) {
      this.validator.applyDefaults(entity);
    }
    return entity;
  }
}
