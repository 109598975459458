import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

interface CallToActionComponentProps {
  subheading: string;
  heading: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  buttonIcon?: React.ReactNode;
}

export const CallToActionComponent = ({
  subheading,
  heading,
  description,
  buttonText,
  buttonLink,
  buttonIcon,
}: CallToActionComponentProps) => {
  return (
    <Container maxWidth="md" style={{ margin: '0 auto 100px' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6" color="primary" gutterBottom>
          {subheading}
        </Typography>
        <Typography variant="h2" component="div" gutterBottom>
          {heading}
        </Typography>
        <Typography variant="body1" paragraph>
          {description}
        </Typography>
        <Button
          startIcon={buttonIcon}
          variant="outlined"
          color="primary"
          target="_blank"
          href={buttonLink}
        >
          {buttonText}
        </Button>
      </Box>
    </Container>
  );
};
