import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { SIPCentralApiClient, sipCentralApiRef } from './api';
import { SonarApiClient, sonarApiRef } from './api/sonarApi';
import { JenkinsApiClient, jenkinsApiRef } from './api/jenkinsApi';
import { GithubApiClient, githubApiRef } from './api/githubApi';
import { rootRouteRef } from './routes';

export const sipCentralPlugin = createPlugin({
  id: 'sip-central',
  apis: [
    createApiFactory({
      api: sipCentralApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new SIPCentralApiClient({ discoveryApi, fetchApi }),
    }),
    createApiFactory({
      api: sonarApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new SonarApiClient({ discoveryApi, fetchApi }),
    }),
    createApiFactory({
      api: jenkinsApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new JenkinsApiClient({ discoveryApi, fetchApi }),
    }),
    createApiFactory({
      api: githubApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new GithubApiClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const SipCentralPage = sipCentralPlugin.provide(
  createRoutableExtension({
    name: 'SipCentralPage',
    component: () => import('./App').then(m => m.App),
    mountPoint: rootRouteRef,
  }),
);
