import { Repository } from '../types';

export const useDependabotAlertGroups = (repository: Repository) => {
  const allIssues =
    repository.vulnerabilityAlerts.nodes.filter(
      entry => entry.state === 'DISMISSED' || entry.state === 'OPEN',
    ) || [];
  const dismissedIssues = repository.vulnerabilityAlerts.nodes.filter(
    entry => entry.state === 'DISMISSED',
  );
  const openIssues =
    repository.vulnerabilityAlerts.nodes.filter(
      entry => entry.state === 'OPEN',
    ) || [];
  const criticalIssues = openIssues.filter(
    node => node.securityVulnerability.severity === 'CRITICAL',
  );
  const highIssues = openIssues.filter(
    node => node.securityVulnerability.severity === 'HIGH',
  );
  const mediumIssues = openIssues.filter(
    node => node.securityVulnerability.severity === 'MODERATE',
  );
  const lowIssues = openIssues.filter(
    node => node.securityVulnerability.severity === 'LOW',
  );

  return {
    allIssues,
    dismissedIssues,
    openIssues,
    criticalIssues,
    highIssues,
    mediumIssues,
    lowIssues,
  };
};
