import { useApi } from '@backstage/core-plugin-api';
import { ManifestFile } from '@mercedes-benz/seamless-common';
import { useState } from 'react';
import useAsync from 'react-use/esm/useAsync';
import { seamlessApiClientRef } from '../api/seamless-api.client';
import { VersionMetadata } from '../models/manifest-file';

interface ManifestState {
  manifestFile: ManifestFile | undefined;
  manifestFileList: ManifestFile[] | undefined;
  versionList: VersionMetadata[] | undefined;
}

const globalState: Record<string, ManifestState | undefined> = {};

type UseManifestFileType = {
  manifestFile?: ManifestFile;
  manifestFileList?: ManifestFile[];
  versionList?: VersionMetadata[];
  loading?: boolean;
  error?: Error;
};

export const useSeamlessManifests = (
  aemPluginName: string,
  version?: string,
): UseManifestFileType => {
  const [manifestFile, setManifestFile] = useState<ManifestFile | undefined>();
  const [manifestFileList, setManifestFileList] = useState<
    ManifestFile[] | undefined
  >();
  const [versionList, setVersionList] = useState<
    VersionMetadata[] | undefined
  >();

  const seamlessApiClient = useApi(seamlessApiClientRef);

  const { error, loading } = useAsync(async () => {
    const componentFileList =
      await seamlessApiClient.getManifestFiles(aemPluginName);
    const manifestVersionList: VersionMetadata[] = componentFileList.map(
      manifestData => {
        return {
          label: manifestData.version,
          value: manifestData.version,
        };
      },
    );

    if (version) {
      const manifestData = manifestFileList?.find(file => {
        return file.version === version;
      });
      setManifestFile(manifestData);
    } else {
      setManifestFile(componentFileList[0]);
    }
    setManifestFileList(componentFileList);
    setVersionList(manifestVersionList);

    globalState[aemPluginName] = {
      manifestFile: componentFileList[0],
      manifestFileList: componentFileList,
      versionList: manifestVersionList,
    };
  }, [
    setManifestFile,
    setManifestFileList,
    setVersionList,
    aemPluginName,
    version,
    seamlessApiClient,
  ]);

  return { error, manifestFile, manifestFileList, versionList, loading };
};
