/**
 * Global roles
 * 1. admin
 * 2. tenantadmin
 * 3. gbsadmin
 * 4. b2xcoreadmin
 */

/**
 * Tenant permissions
 * 1. PO
 * 2. PM
 * 3. BUDGET_APPROVER
 * 4. CONTROLLER
 * 5. ISA
 * 6. SECURITY_CHAMPION
 * 7. CLOUD_SECURITY_RESPONSIBLE
 * 8. PROGRAM_LEAD
 * 9. PROGRAM_FINANCE_LEAD
 * 10. PROGRAM_TECH_LEAD
 * 11. INFORMATION_SECURITY_OFFICER
 * 11. SITE_RELIABILITY_ENGINEER
 * 12. FRONTEND_DEVELOPER
 * 13. HARBOR_ADMIN
 * 14. BACKEND_DEVELOPER
 * 15. SCRUM_MASTER
 * 16. SERVICE_MANAGER
 * 17. OTHER
 */

// create roles/global roles to which you want the specific action to be accessible
import { ActionPermissionsType } from '@mb.io/tdms-common';
import { ActionType } from '@mercedes-benz/permission-common';

export const actionPermissions: ActionPermissionsType = {
  tenants: {
    read: {
      actionType: ActionType.ROLE,

      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
  },
  tenant: {
    read: {
      actionType: ActionType.ROLE,

      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
    update: {
      actionType: ActionType.ROLE,

      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
  },
  tenantMembers: {
    read: {
      actionType: ActionType.ROLE,

      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
    create: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    update: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    delete: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
  },
  tenantPermissions: {
    read: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
    create: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    update: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    delete: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
  },
  tenantBookings: {
    read: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
  },
  tenantUsers: {
    read: {
      actionType: ActionType.ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
  },
  providerPortfolio: {
    read: {
      actionType: ActionType.ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
  },
  backOffice: {
    read: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
  },
  backOfficeBookings: {
    read: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
    create: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
    update: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
    delete: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin'],
      },
    },
  },
  backOfficePackages: {
    read: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    create: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    update: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    delete: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
  },
  backOfficeServices: {
    read: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    create: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    update: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    delete: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
  },
  backOfficeServicePermissions: {
    read: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    create: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    update: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    delete: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
  },
  backOfficeServiceProperties: {
    read: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    create: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    update: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    delete: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
  },
  backOfficePermissions: {
    read: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
    update: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
  },
  support: {
    read: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
  },
  emailAnnouncements: {
    read: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
    update: {
      actionType: ActionType.ROLE,
      details: {
        role: ['PO', 'PM'],
        globalRoles: ['admin', 'tenantadmin'],
      },
    },
  },
  crossChargingOverview: {
    read: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
    update: {
      actionType: ActionType.GLOBAL_ROLE,
      details: {
        globalRoles: ['admin', 'tenantadmin', 'gbsadmin', 'b2xcoreadmin'],
      },
    },
  },
};

export * from './PermissionCheck';
export * from './PermissionContext';
