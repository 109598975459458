import { useApi } from '@backstage/core-plugin-api';
import { PREFERENCE_OPT_OUT } from '@mercedes-benz/user-settings-common';
import useAsync from 'react-use/esm/useAsync';
import { userSettingsApiRef } from '../api-refs';
import { OptOutPreference } from '../types';

export function useOptOutState(feature: string) {
  const userSettingsApi = useApi(userSettingsApiRef);

  const {
    value: preference,
    loading,
    error,
  } = useAsync(() => {
    return userSettingsApi.get({
      bucket: PREFERENCE_OPT_OUT,
      key: feature,
    });
  }, [userSettingsApi]);

  const optOut = preference?.value
    ? (preference?.value as OptOutPreference).optOut
    : undefined;

  return {
    optOut,
    loading,
    error,
  };
}
