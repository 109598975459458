import { RESOURCE_TYPE_CATALOG_ENTITY } from '@backstage/plugin-catalog-common/alpha';
import { ResourcePermission } from '@backstage/plugin-permission-common';

/**
 * This permission is used to determine if a user is allowed to execute an action. A user can
 * execute an action only if he has the ownership in this entity.
 * @public
 */
export const harborExecutePermission: ResourcePermission<'catalog-entity'> = {
  name: 'harbor.execute',
  attributes: {
    action: 'update',
  },
  resourceType: RESOURCE_TYPE_CATALOG_ENTITY,
  type: 'resource',
};
