import Box from '@mui/material/Box';
import {
  AbstractComponent,
  NavbarComponent,
} from '@mercedes-benz/plugin-landing-page';
import { default as React } from 'react';

type Link = {
  text: string;
  isPrimary?: boolean;
  to?: string;
  onClick?: () => void;
};

type Props = {
  children?: React.ReactNode;
  allowBack?: boolean;
  links?: Link[];
};

export const ScaffolderPageLayout = ({ children, allowBack, links }: Props) => {
  return (
    <>
      <Box padding={1}>
        <NavbarComponent backArrow={allowBack} links={links} />
      </Box>
      <AbstractComponent />
      {children}
    </>
  );
};
