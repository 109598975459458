import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';

import {
  actionEnum,
  Group,
  InstanceUsers,
  MultiUserGroupRequest,
  SearchUserGroupsResponse,
  SonarApi,
  UserGroupResponse,
} from '@mercedes-benz/sip-central-common';

export const sonarApiRef = createApiRef<SonarApi>({
  id: 'plugin.sip-central.sonar.api',
});

export type SonarRequest = {
  method: string;
  path: string;
  body?: object;
  responseAsJson?: boolean;
};

export class SonarApiClient implements SonarApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }
  getInstances(): any {
    return this.submitRequest({
      method: 'GET',
      path: `/sonar/instances`,
    });
  }

  searchUsers(username: string): Promise<InstanceUsers[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/sonar/users/${username}/search`,
    });
  }

  async addUserToGroup(
    addUserToGroupRequest: MultiUserGroupRequest,
  ): Promise<UserGroupResponse> {
    const res = await this.submitRequest({
      method: 'POST',
      path: `/sonar/user_groups/add_user`,
      body: addUserToGroupRequest,
      responseAsJson: false,
    });
    this.submitRequest({
      method: 'POST',
      path: `/audit-logs`,
      body: {
        currentUser: undefined,
        action: actionEnum.ADD_PERMISSION,
        activities: [
          {
            performedOn: addUserToGroupRequest.loginName,
            toolPermissions: {
              tool: 'Sonar',
              permissions: [
                {
                  instance: addUserToGroupRequest.instanceName,
                  groups: addUserToGroupRequest.groups,
                },
              ],
            },
          },
        ],
        actionDate: new Date().toJSON(),
      },
    });

    return res;
  }

  async removeUserFromGroup(
    removeUserFromGroupRequest: MultiUserGroupRequest,
  ): Promise<UserGroupResponse> {
    const res = await this.submitRequest({
      method: 'POST',
      path: `/sonar/user_groups/remove_user`,
      body: removeUserFromGroupRequest,
      responseAsJson: false,
    });

    this.submitRequest({
      method: 'POST',
      path: `/audit-logs`,
      body: {
        currentUser: undefined,
        action: actionEnum.REMOVE_PERMISSION,
        activities: [
          {
            performedOn: removeUserFromGroupRequest.loginName,
            toolPermissions: {
              tool: 'Sonar',
              permissions: [
                {
                  instance: removeUserFromGroupRequest.instanceName,
                  groups: removeUserFromGroupRequest.groups,
                },
              ],
            },
          },
        ],
        actionDate: new Date().toJSON(),
      },
    });

    return res;
  }

  searchUserGroups(
    groupName?: string,
    instanceName?: string,
  ): Promise<SearchUserGroupsResponse> {
    const queryParams: string[] = [];
    if (groupName !== undefined) {
      queryParams.push(`groupName=${groupName}`);
    }
    if (instanceName !== undefined) {
      queryParams.push(`instance=${instanceName}`);
    }
    const queryString = queryParams.join('&');
    const path = `/sonar/user_groups/search?${queryString}`;
    return this.submitRequest({
      method: 'GET',
      path: path,
    });
  }

  createUserGroup(teamId: string, instanceName?: string): Promise<Group[]> {
    return this.submitRequest({
      method: 'POST',
      path: `/sonar/user_groups/create`,
      body: { teamId, instanceName },
    });
  }

  private async submitRequest({
    path,
    method,
    body,
    responseAsJson = true,
  }: SonarRequest): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('sip-central')}${path}`;

    const headers: Record<string, string> = {
      'content-type': 'application/json',
    };
    const response = await this.fetchApi.fetch(url, {
      method,
      headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      return Promise.reject('Sonar');
    }
    return responseAsJson ? response.json() : response;
  }
}
