import { useEffect, useState } from 'react';
import { useUserFeatureFlagContext } from '../context';

type Props = {
  feature: string;
  defaultValue?: boolean;
};
/**
 *
 * @param feature - name of the feature
 * @param defaultValue - default value of the feature
 * @returns true false if feature is not enabled, true if enabled or does not exist
 */
export const useUserFeatureValue = ({
  feature,
  defaultValue,
}: Props): boolean => {
  const [userFeatureValue, setUserFeatureValue] = useState(false);
  const context = useUserFeatureFlagContext();

  useEffect(() => {
    if (context.features[feature] === undefined && defaultValue === false) {
      setUserFeatureValue(false);
      return;
    }
    if (context.features[feature] === undefined) {
      setUserFeatureValue(true);
      return;
    }
    setUserFeatureValue(context.features[feature]!);
  }, [context, feature, defaultValue]);

  return userFeatureValue;
};
