import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import { EntityValidationPage } from '../../entity-validator/EntityValidationPage';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  DialogTabPanel,
  dialogTabProps,
} from '../DialogTabPanel/DialogTabPanel';
import { CatalogAnalyzer } from '@mercedes-benz/plugin-catalog-analyzer';

const useStyles = makeStyles()(theme => ({
  fullHeightDialog: {
    height: 'calc(100% - 64px)',
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    flexShrink: 0,
  },
  tab: {
    alignItems: 'start',
  },
  infoIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  backdrop: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgb(255 255 255 / 85%)'
        : 'rgb(66 66 66 / 85%)',
  },
}));

export function ValidationsDialog(props: {
  open: boolean;
  onClose: () => void;
}) {
  const { classes } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="entity-inspector-dialog-title"
      PaperProps={{ className: classes.fullHeightDialog }}
      slotProps={{
        backdrop: {
          classes: {
            root: classes.backdrop,
          },
        },
      }}
    >
      <DialogTitle id="entity-inspector-dialog-title">
        Entity Validation
      </DialogTitle>
      <DialogContent dividers style={{ padding: 0 }}>
        <div className={classes.root}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            aria-label="Template editor options"
            className={classes.tabs}
          >
            <Tab
              label="Validate Entity"
              className={classes.tab}
              {...dialogTabProps(0)}
            />
            <Tab
              label="Analyze Location"
              className={classes.tab}
              {...dialogTabProps(1)}
            />
          </Tabs>

          <DialogTabPanel value={activeTab} index={0}>
            <EntityValidationPage />
          </DialogTabPanel>
          <DialogTabPanel value={activeTab} index={1}>
            <CatalogAnalyzer />
          </DialogTabPanel>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
