import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityFeedbackResponseContent } from '@backstage-community/plugin-entity-feedback';
import React from 'react';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';
import { GenericOverviewContent } from '../generic/OverviewContent';
import { TechdocsContent } from '../generic/TechdocsContent';

export const DocumentationPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {GenericOverviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {TechdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
