import Grid from '@mui/material/Grid';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import {
  EntityBazaarInfoCard,
  isBazaarAvailable,
} from '@backstage-community/plugin-bazaar';
import {
  EntityHasSubcomponentsCard,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  hasCatalogProcessingErrors,
  isKind,
  isOrphan,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  EnhancedEntityLinksCard,
  TeamContactsCard,
  and,
  canConsumeAPIs,
  canProvideAPIs,
  isContainer,
  not,
} from '@mb.io/core-components';
import React from 'react';
import { EntityAboutCard } from '../../about-card';
import { SeamlessHasSubcomponentsCard } from '../../seamless/SeamlessHasSubcomponentsCard';
import {
  EntityEndOfLifeCard,
  isEndOfLifeAvailable,
} from '@mercedes-benz/endoflife';

type OverviewContentProps = {
  children: React.ReactElement | React.ReactElement[];
  hideEmptyLinks?: boolean;
};

export const OverviewContent = ({
  children,
  hideEmptyLinks,
}: OverviewContentProps) => (
  <Grid container spacing={3} alignItems="stretch" direction="row">
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <Grid item md={6} xs={12} className="grid-item">
      <EntityAboutCard />
    </Grid>

    <Grid item className="grid-item" md={6} xs={12}>
      <Grid
        container
        alignItems="stretch"
        direction="column"
        wrap="nowrap"
        style={{ padding: 0 }}
      >
        <Grid item xs={12} style={{ padding: 0 }}>
          <TeamContactsCard />
        </Grid>
        <Grid
          item
          className="grid-item"
          xs={12}
          style={{ padding: 0, paddingTop: '1em' }}
        >
          <EnhancedEntityLinksCard hideEmpty={hideEmptyLinks} />
        </Grid>
      </Grid>
    </Grid>
    {children}
  </Grid>
);

export const GenericOverviewContent = (
  <OverviewContent>
    <Grid item xs={12} className="grid-item">
      <EntityCatalogGraphCard variant="gridItem" height={400} maxDepth={1} />
    </Grid>
  </OverviewContent>
);

export const ComponentOverviewContent = (
  <OverviewContent>
    <>
      <EntitySwitch>
        <EntitySwitch.Case if={isEndOfLifeAvailable}>
          <Grid item xs={12} className="grid-item">
            <EntityEndOfLifeCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <Grid item xs={12} className="grid-item">
        <EntityCatalogGraphCard variant="gridItem" height={400} maxDepth={1} />
      </Grid>
      <EntitySwitch>
        <EntitySwitch.Case if={isBazaarAvailable}>
          <Grid item xs={12} className="grid-item">
            <EntityBazaarInfoCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={canProvideAPIs}>
          <Grid item md={12} xl={6}>
            <EntityProvidedApisCard />
          </Grid>
        </EntitySwitch.Case>

        <EntitySwitch.Case if={canConsumeAPIs}>
          <Grid item md={12} xl={6}>
            <EntityConsumedApisCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={and(isKind('seamless'), not(isContainer))}>
          <Grid item md={12} xl={6}>
            <SeamlessHasSubcomponentsCard variant="gridItem" />
          </Grid>
        </EntitySwitch.Case>
        <EntitySwitch.Case if={not(isKind('seamless'))}>
          <Grid item md={12} xl={6}>
            <EntityHasSubcomponentsCard variant="gridItem" />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </>
  </OverviewContent>
);
