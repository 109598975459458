import { useCallback } from 'react';

async function replaceSvg() {
  const img: HTMLImageElement | null = document.querySelector(
    'img[alt^="Girl dropping mic"]',
  );
  const imgURL = img?.src;
  if (imgURL) {
    const response = await fetch(imgURL);
    const svgText = await response.text();
    const parser = new DOMParser();
    const svgXml = parser.parseFromString(svgText, 'text/xml');
    const paths = svgXml.getElementsByTagName('path');

    // replace colors
    const leftShape = paths[0];
    leftShape.setAttribute('fill', '#4F4F4F');

    const rightShape = paths[1];
    rightShape.setAttribute('fill', '#424242');

    const pathsArr = Array.from(paths);
    const skirtShape = pathsArr.find(
      path => path.getAttribute('fill') === '#F038A5',
    );
    skirtShape?.setAttribute('fill', '#0078D6');

    const skirtShape2 = paths[6];
    skirtShape2.setAttribute('fill', '#003156');

    // Replace original SVG with themed one
    const svg = svgXml.getElementsByTagName('svg')[0];
    const base64Str = window.btoa(new XMLSerializer().serializeToString(svg));
    img.src = `data:image/svg+xml;base64, ${base64Str}`;
  }
}

async function handleErrorPageMutation(mutation: MutationRecord) {
  const mutationsArr = Array.from(mutation.addedNodes);

  // ERROR 404: NotFoundError: Page not found
  let errorPage = mutationsArr.find((node: any) => {
    const keys = Object.keys(node);
    const propsKey = keys.find((key: string) => key.includes('reactProps'));
    return propsKey
      ? node[propsKey].className?.includes('BackstageErrorPage')
      : null;
  });

  // ERROR 404: PAGE NOT FOUND
  // for direct access of URLs, ErrorPage is injected through innerHTML
  if (!errorPage) {
    mutationsArr.forEach((node: any) => {
      if (node.innerHTML?.includes('BackstageErrorPage')) {
        errorPage = node;
      }
    });
  }

  if (errorPage) {
    await replaceSvg();
  }
}

// Callback function to execute when mutations are observed
async function onMutationCallback(
  mutations: MutationRecord[],
  _: MutationObserver,
) {
  mutations.forEach(mutation => {
    if (mutation.type === 'childList') {
      handleErrorPageMutation(mutation);
    }
  });
}

const registerThemingObserver = (cb: MutationCallback) => {
  // Select the node that will be observed for mutations
  const targetNode = document.querySelector('body');

  // Options for the observer (which mutations to observe)
  const config = { childList: true, subtree: true };

  // Create an observer instance linked to the callback function
  const observer: MutationObserver = new MutationObserver(cb);

  // Start observing the target node for configured mutations
  if (targetNode) observer.observe(targetNode, config);
};

/**
 * Changes colors of MicDrop-SVG on BackStageErrorPage as soon as it is loaded
 */
export function useErrorPageStyle() {
  const memoizedCb: MutationCallback = useCallback(onMutationCallback, []);
  registerThemingObserver(memoizedCb);
}
