import {
  discoveryApiRef,
  useApi,
  useRouteRefParams,
} from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { AcademyEntity } from '@mb.io/catalog-model';
import {
  ANNOTATION_ACADEMY_ID,
  ANNOTATION_ACADEMY_PROJECT,
} from '@mercedes-benz/academy-common';
import { tutorialRouteRef } from '../routes';

export function useAcademyEntity(
  namespaceIdInput?: string,
  tutorialNameInput?: string,
) {
  const { tutorialName, namespaceId } = useRouteRefParams(tutorialRouteRef);

  const currentTutorialName = tutorialName ?? tutorialNameInput;
  const currentNamespaceId = namespaceId ?? namespaceIdInput;

  const catalogApi = useApi(catalogApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const { loading, error, value } = useAsync(async () => {
    if (currentTutorialName && currentNamespaceId) {
      const result = await catalogApi.getEntityByRef({
        kind: 'Academy',
        namespace: currentNamespaceId ?? 'default',
        name: currentTutorialName,
      });

      const entity = result as AcademyEntity;

      const academyBaseUrl = await discoveryApi.getBaseUrl('academy');

      // Resolve index page and images urls
      const project =
        entity.metadata?.annotations?.[ANNOTATION_ACADEMY_PROJECT];
      const tutorialId = entity.metadata?.annotations?.[ANNOTATION_ACADEMY_ID];

      const indexUrl = `${academyBaseUrl}/content/static/${project}/${tutorialId}/index.html`;
      const imgUrl = `${academyBaseUrl}/content/static/${project}/${tutorialId}/img/`;

      return {
        entity,
        indexUrl,
        imgUrl,
        academyBaseUrl: academyBaseUrl,
      };
    }
    return undefined;
  }, [currentTutorialName, currentNamespaceId]);

  return {
    loading,
    error,
    entity: value?.entity,
    indexUrl: value?.indexUrl,
    imgUrl: value?.imgUrl,
    academyBaseUrl: value?.academyBaseUrl,
  };
}
