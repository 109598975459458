import { Codelab } from '@mb.io/catalog-model';

export interface Model {
  id?: string;
  createdAt?: string;
}

export interface RequestOptions {
  token?: string;
  responseAsJson?: boolean;
}

export interface Page<T extends Model> {
  items: T[];
  currentPage: number;
  totalPages: number;
  totalResults: number;
  pageSize: number;
  hasNextPage: boolean;
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum TrainingType {
  MANAGED = 'MANAGED',
  SELF = 'SELF',
}

export enum TrainingStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface TrainingStep {
  order: number;
  academyEntityRef: string;
  checkpoint?: boolean;
  notes?: string;
}

export interface Training extends Model {
  name: string;
  description: string;
  steps: TrainingStep[];
  tags: string[];
  communities: string[];
  domains: string[];
}

export interface AcademyUserRef {
  id: string;
  name: string;
  ref: string;
}

export interface Enrollment extends Model {
  trainingId: string;
  title: string;
  trainee: AcademyUserRef;
  trainer?: AcademyUserRef;
  creator: AcademyUserRef;
  trainingType: TrainingType;
  trainingSessionId?: string;
  status: TrainingStatus;
  startedAt?: string;
  completedAt?: string;
}

export interface TrainingSession extends Model {
  enrolmentId: string;
  startedAt: string;
  completedAt?: string;
  status: TrainingStatus;
  steps: TrainingSessionStep[];
}

export interface TrainingSessionStep extends TrainingStep {
  status: TrainingStatus;
  startedAt?: string;
  completedAt?: string;
}

export enum SessionOperation {
  START = 'START',
  FINISH = 'FINISH',
  START_AND_FINISH = 'START_AND_FINISH',
}

export type AcademyProjectMetadata = {
  academyName: string;
  contentDir: string;
  outputDir: string;
  backstageOwner: string;
  backstageSystem: string;
  backstageDomain: string;
  source: {
    server: string;
    repository: string;
    branch: string;
    sha: string;
  };
  tutorials: Codelab[];
};

export interface DeleteResponse {
  message: string;
}

export interface CheckResponse {
  exists: boolean;
}

export interface AcademySearchStep {
  title: string;
  text: string;
  duration: number;
  number: number;
}

export interface AcademySearchIndex {
  id: string;
  title: string;
  summary: string;
  steps: AcademySearchStep[];
}
