import { Entity } from '@backstage/catalog-model';
import { isEntityProvidedBy } from '../../utils';

export const ENTITY_EXTERNAL_PROVIDER_COMPARATOR =
  (higherPrecedenceProvider: string) => (entityA: Entity, entityB: Entity) => {
    if (isEntityProvidedBy(entityA, higherPrecedenceProvider)) {
      return -1;
    }
    if (isEntityProvidedBy(entityB, higherPrecedenceProvider)) {
      return 1;
    }
    return 0;
  };

export const ENTITY_NAME_COMPARATOR = (entityA: Entity, entityB: Entity) =>
  entityA.metadata.name === entityB.metadata.name;
