import { Entity } from '@backstage/catalog-model';
import { GITHUB_REPO_ANNOTATION } from '../types';

export const useGithubProjectEntity = (entity: Entity) => {
  const projectSlug = entity.metadata?.annotations?.[
    GITHUB_REPO_ANNOTATION
  ] as string;
  return {
    owner: projectSlug.split('/')[0],
    repo: projectSlug.split('/')[1],
  };
};
