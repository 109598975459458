import { CreateButtonProps, TableColumn } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import {
  CatalogTable,
  CatalogTableRow,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogFilterLayout,
  EntityKindPicker,
  EntityLifecyclePicker,
  EntityOwnerPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListPicker,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { PageLayout } from '../PageLayout';

const useStyles = makeStyles()(() => ({
  contentWrapper: {
    display: 'flex',
    minHeight: '90%',
    flex: 1,
  },
}));

export type DefaultExplorerIndexPageProps = {
  kind: string;
  title: string;
  subtitle: string;
  supportTitle: string;
  columns?: TableColumn<CatalogTableRow>[];
};

const ExplorerIndexPage = ({
  kind,
  title,
  subtitle,
  supportTitle,
  columns,
}: DefaultExplorerIndexPageProps) => {
  const { classes } = useStyles();

  const registerComponentLink = useRouteRef(
    catalogPlugin.externalRoutes.createComponent,
  );

  // useRouteRef does not support query params, so we need to append the custom filters here.
  const to = `${registerComponentLink?.()}`;
  const create: CreateButtonProps = {
    title: 'Create',
    to,
  };

  // search results are not enough to display the data, fetch the related entities.
  return (
    <PageLayout
      themeId="other"
      title={title}
      subtitle={subtitle}
      create={create}
      supportTitle={supportTitle}
    >
      <div className={classes.contentWrapper}>
        <CatalogFilterLayout>
          <CatalogFilterLayout.Filters>
            <EntityKindPicker initialFilter={kind} hidden />
            <EntityTypePicker />
            <UserListPicker />
            <EntityOwnerPicker />
            <EntityLifecyclePicker />
            <EntityTagPicker />
          </CatalogFilterLayout.Filters>
          <CatalogFilterLayout.Content>
            <CatalogTable columns={columns} />
          </CatalogFilterLayout.Content>
        </CatalogFilterLayout>
      </div>
    </PageLayout>
  );
};

export const DefaultExplorerIndexPage = (
  props: DefaultExplorerIndexPageProps,
) => <ExplorerIndexPage {...props} />;
