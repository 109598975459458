import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  DomainExplorerContent,
  ExploreLayout,
} from '@backstage-community/plugin-explore';
import React from 'react';
import { ToolExplorerContent } from './ToolExplorerContent';

export const ExplorePage = () => {
  const configApi = useApi(configApiRef);
  const organizationName =
    configApi.getOptionalString('organization.name') ?? 'Backstage';

  return (
    <ExploreLayout
      title={`Explore the ${organizationName} ecosystem`}
      subtitle="Discover solutions available in your ecosystem"
    >
      <ExploreLayout.Route path="domains" title="Domains">
        <DomainExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="tools" title="Tools">
        <ToolExplorerContent />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};
