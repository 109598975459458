import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { permissionApiRef } from '@backstage/plugin-permission-react';
import { ActionPermissionsType } from '@mb.io/tdms-common';
import {
  ActionPayload,
  RoleQueryOptions,
  systemRolePermission,
  SystemRolePermissionRequest,
} from '@mercedes-benz/permission-common';
import React, { createContext, ReactNode, useContext } from 'react';
import useAsync from 'react-use/esm/useAsync';
import { actionPermissions } from './index';

export type AccessType = 'read' | 'create' | 'delete' | 'update';

type PermissionContextType = {
  usePermission: (
    access: AccessType,
    kind: keyof ActionPermissionsType,
    tenant?: string,
  ) => { value?: boolean; loading: boolean };
  usePermissionGroup: (
    kind: keyof ActionPermissionsType,
    tenant?: string,
  ) => { value?: Record<AccessType, boolean>; loading: boolean };
};

export const PermissionContext = createContext<
  PermissionContextType | undefined
>(undefined);

export const PermissionContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const identity = useApi(identityApiRef);
  const { value: user } = useAsync(() => identity.getBackstageIdentity());
  const { value: credentials } = useAsync(() => identity.getCredentials());

  const api = useApi(permissionApiRef);

  const execPermission = async (permission: ActionPayload, tenant?: string) => {
    if (!credentials?.token) {
      return false;
    }

    (permission.details as RoleQueryOptions).tenant = tenant;
    const resourceRef = JSON.stringify({
      ...permission,
      userRef: user?.userEntityRef,
      token: credentials?.token,
    } as SystemRolePermissionRequest);
    const authorize = await api.authorize({
      permission: systemRolePermission,
      resourceRef,
    });

    return authorize.result === 'ALLOW';
  };
  const context: PermissionContextType = {
    usePermissionGroup: (kind, tenant) => {
      const permissions = actionPermissions[kind];

      return useAsync(async () => {
        const group: Record<AccessType, boolean> = {} as never;
        for (const [key, value] of Object.entries(permissions)) {
          group[key as AccessType] = await execPermission(value, tenant);
        }
        return group;
      }, [identity, user, credentials, api]);
    },
    usePermission: (access, kind, tenant) => {
      const permission = actionPermissions[kind][access];
      if (!permission) {
        throw new Error('Invalid kind or access defined.');
      }
      return useAsync(async () => {
        return execPermission(permission, tenant);
      }, [identity, user, credentials, api]);
    },
  };
  return (
    <PermissionContext.Provider value={context}>
      {children}
    </PermissionContext.Provider>
  );
};

export function usePermissionContext(): PermissionContextType {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error(
      'usePermissionContext must be used within GlobalFlagContextType, use <PermissionContextProvider /> before calling this hook',
    );
  }
  return context;
}
