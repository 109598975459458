import { LinkButton } from '@backstage/core-components';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBack from '@mui/icons-material/ArrowBack';

import Button from '@mui/material/Button';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()(theme => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'unset',
  },
  logo: {
    color: theme.palette.primary.main,
    flexGrow: 1,
    '& img': {
      height: '64px',
      width: 'auto',
    },
  },
  menuLink: {
    marginLeft: theme.spacing(4),
    color: theme.palette.text.primary,
  },
}));

type Link = {
  text: string;
  isPrimary?: boolean;
  to?: string;
  onClick?: () => void;
};

type NavbarProps = {
  logo?: string;
  backArrow?: boolean;
  links?: Link[];
};

export const NavbarComponent = ({ logo, links, backArrow }: NavbarProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar style={{ color: 'black' }}>
        <div className={classes.logo}>
          {backArrow && (
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </Button>
          )}
          {logo && <img src={logo} alt="" />}
        </div>
        {links?.map(link =>
          link.to ? (
            <LinkButton
              key={link.text}
              variant={link.isPrimary ? 'contained' : 'text'}
              to={link.to}
              className={classes.menuLink}
            >
              {link.text}
            </LinkButton>
          ) : (
            <Button
              key={link.text}
              variant={link.isPrimary ? 'contained' : 'text'}
              onClick={link.onClick}
              className={classes.menuLink}
            >
              {link.text}
            </Button>
          ),
        )}
      </Toolbar>
    </AppBar>
  );
};
