import { Entity } from '@backstage/catalog-model';
import { TableColumn } from '@backstage/core-components';
import { EntityTable } from '@backstage/plugin-catalog-react';
import { SeamlessEntity } from '@mb.io/catalog-model';

export const seamlessEntityHelpLink: string =
  'https://backstage.i.mercedes-benz.com/docs/default/component/backstage-user-doc/catalog/catalog-seamless/';

export const asSeamlessEntities = (entities: Entity[]): SeamlessEntity[] =>
  entities as SeamlessEntity[];

export const seamlessEntityColumns: TableColumn<SeamlessEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'seamless' }),
  EntityTable.columns.createOwnerColumn(),
  EntityTable.columns.createSpecTypeColumn(),
  EntityTable.columns.createSpecLifecycleColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
];
