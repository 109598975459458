import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import {
  catalogEntityRouteRef,
  catalogIndexRouteRef,
  rootRouteRef,
} from './routes';
import { HomeApiClient, homeApiRef } from './api';

export const homePlugin = createPlugin({
  id: 'home',
  apis: [
    createApiFactory({
      api: homeApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new HomeApiClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
  externalRoutes: {
    catalogIndex: catalogIndexRouteRef,
    catalogEntityRoute: catalogEntityRouteRef,
  },
});

export const HomePage = homePlugin.provide(
  createRoutableExtension({
    name: 'HomePage',
    component: () => import('./components/HomePage').then(m => m.HomePage),
    mountPoint: rootRouteRef,
  }),
);
