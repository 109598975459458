import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { usePermission } from '@backstage/plugin-permission-react';
import useAsync from 'react-use/esm/useAsync';
import {
  ActionPayload,
  systemRolePermission,
  SystemRolePermissionRequest,
} from '@mercedes-benz/permission-common';

export const useSystemRoleGuard = (ref: ActionPayload) => {
  const identity = useApi(identityApiRef);
  const { value: user } = useAsync(() => identity.getBackstageIdentity());
  const { value: credentials } = useAsync(() => identity.getCredentials());

  const resourceRef = JSON.stringify({
    ...ref,
    userRef: user?.userEntityRef,
    token: credentials?.token,
  } as SystemRolePermissionRequest);
  return usePermission({ permission: systemRolePermission, resourceRef });
};
