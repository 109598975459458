import { z } from 'zod';

export const TeamSchema = z.object({
  name: z.string(),
});

export const AppSchema = z.object({
  name: z.string(),
});

export const ProductOwnerSchema = z.object({
  id: z.string(),
});

export const BlackDuckSchema = z.object({
  policyViolations: z.number(),
  securityRisks: z.number(),
  licenseRisks: z.number(),
});

export const SonarSchema = z.object({
  failedChecks: z.number(),
});

export const HarborSchema = z.object({
  criticalVulnerabilities: z.number(),
  highVulnerabilities: z.number(),
  notScanned: z.number(),
});

export const SecHubSchema = z.object({
  redIssues: z.number(),
  yellowIssues: z.number(),
});

export const PhaseSchema = z.enum(['planned', 'operation', 'sundowned']);

export const ToolReferenceSchema = z.object({
  slug: z.string(),
  href: z.string(),
  // null if count is unknown, e.g. for an unscanned Harbor-Artifact
  count: z.number().nullable(),
  errorDescription: z.object({}).nullable().optional(),
});

export const ToolReferenceWithoutHrefSchema = ToolReferenceSchema.omit({
  href: true,
});

export const PolicyViolationWithToolRefSchema = z.object({
  name: z.string(),
  foundIn: ToolReferenceSchema.array(),
});

export const BdDetailsSchema = z.object({
  securityRisks: z.object({
    criticalIn: ToolReferenceSchema.array(),
    highIn: ToolReferenceSchema.array(),
  }),
  licenseRisks: z.object({
    highIn: ToolReferenceSchema.array(),
  }),
  policyViolations: z.object({
    blocker: PolicyViolationWithToolRefSchema.array(),
    major: PolicyViolationWithToolRefSchema.array(),
    critical: PolicyViolationWithToolRefSchema.array(),
  }),
});

export const HarborDetailsSchema = z.object({
  criticalVulIn: ToolReferenceSchema.array(),
  highVulIn: ToolReferenceSchema.array(),
  notScannedVulIn: ToolReferenceSchema.array(),
});

export const SonarDetailsSchema = z.object({
  coverageViolationIn: ToolReferenceSchema.array(),
  duplicateLinesViolationIn: ToolReferenceSchema.array(),
  maintainabilityViolationIn: ToolReferenceSchema.array(),
  reliabilityViolationIn: ToolReferenceSchema.array(),
  securityViolationIn: ToolReferenceSchema.array(),
  unreviewedHotspotsIn: ToolReferenceSchema.array(),
  blockerViolationIn: z.object({
    newBlockerViolation: ToolReferenceSchema.array(),
    overalBlockerViolation: ToolReferenceSchema.array(),
  }),
  criticalViolationIn: z.object({
    newCriticalViolationIn: ToolReferenceSchema.array(),
    overalCriticalViolationIn: ToolReferenceSchema.array(),
  }),
  majorViolationIn: z.object({
    newMajorViolationIn: ToolReferenceSchema.array(),
    overalMajorViolationIn: ToolReferenceSchema.array(),
  }),
});

export const SecHubDetailsSchema = z.object({
  redFindings: ToolReferenceWithoutHrefSchema.array(),
  yellowFindings: ToolReferenceWithoutHrefSchema.array(),
});

export const DetailsSchema = z.object({
  // Details can be null for unconfigured tools
  blackduck: BdDetailsSchema.nullable(),
  harbor: HarborDetailsSchema.nullable(),
  sonar: SonarDetailsSchema.nullable(),
  sechub: SecHubDetailsSchema.nullable(),
});

export const OverviewSchema = z.object({
  blackduck: BlackDuckSchema.nullable(),
  sonar: SonarSchema.nullable(),
  harbor: HarborSchema.nullable(),
  sechub: SecHubSchema.nullable(),
  totalIssues: z.number().nullable(),
});

export const StageSchema = z.enum(['dev', 'qa', 'prod']);

export const InfraSecurityStageSchema = z.enum([
  'prodAccounts',
  'nonProdAccounts',
]);

export const IsoViewStageSchema = z.enum(['dev', 'qa', 'prod']);

export const ClusterTabSchema = z.enum([
  'InfraSecurityTab',
  'ApplicationSecurityTab',
  'IsoViewTab',
]);

export const ProductSchema = z
  .object({
    name: z.string(),
    po: ProductOwnerSchema.optional(),
    repositories: z.number(),
    planningIt: z.string().nullable(),
    leanIXName: z.string().nullable(),
    team: TeamSchema.nullable(),
    phase: PhaseSchema,
    overview: z.object({
      dev: OverviewSchema.optional().nullable(),
      qa: OverviewSchema.optional().nullable(),
      prod: OverviewSchema,
    }),
    details: z.object({
      dev: DetailsSchema.optional().nullable(),
      qa: DetailsSchema.optional().nullable(),
      prod: DetailsSchema,
    }),
  })
  .refine(
    product => {
      if (product.overview.dev && !product.details.dev) {
        return false;
      }
      return !(product.overview.qa && !product.details.qa);
    },
    product => ({
      message: `Error in product: '${product.name}'. If 'product.overview[stage]' is provided, 'product.details[stage]' is required.`,
    }),
  );

export const UserInfoSchema = z.object({
  id: z.string().optional(),
});

export const SecurityHubSchema = z.object({
  critical: z.number(),
  high: z.number(),
  medium: z.number(),
  low: z.number(),
});

export const AccountOverviewSchema = z.object({
  accountId: z.string(),
  po: UserInfoSchema.optional(),
  csr: UserInfoSchema.optional(),
  securityHub: SecurityHubSchema.optional(),
  totalIssues: z.number().optional(),
});

export const Pro = z.object({
  accountId: z.string(),
  po: UserInfoSchema.optional(),
  csr: UserInfoSchema.optional(),
  securityHub: SecurityHubSchema.optional(),
  totalIssues: z.number().optional(),
});

export const InfrastructureSecuritySchema = z.object({
  nonProdAccounts: AccountOverviewSchema.array(),
  prodAccounts: AccountOverviewSchema.array(),
});

export const ClusterSchema = z
  .object({
    name: z.string(),
    id: z.number(),
    pcoId: z.string(),
    po: ProductOwnerSchema.optional(),
    products: ProductSchema.array(),
    infrastructureSecurity: InfrastructureSecuritySchema,
    isoView: ProductSchema.array(),
  })
  // refine-check will only run if all other checks pass
  .refine(
    cluster => {
      // if cluster.po is not set, require po in each of the products
      if (!cluster.po) {
        return cluster.products.every(product => !!product.po);
      }
      return true;
    },
    cluster => {
      const productsWithoutPo = cluster.products
        .filter(product => !product.po)
        .map(product => product.name);
      return {
        message: `Cluster '${
          cluster.name
        }' did not provide a PO, hence PO is required on Product-level. PO is missing for product(s): '${productsWithoutPo.join(
          ',',
        )}'`,
      };
    },
  );
