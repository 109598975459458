import {
  Content,
  ContentHeader,
  CreateButton,
  CreateButtonProps,
  Header,
  Page,
  SupportButton,
} from '@backstage/core-components';
import { default as React } from 'react';

type Props = {
  title: string;
  subtitle?: string;
  supportTitle?: string;
  contentTitle?: string;
  create?: CreateButtonProps;
  themeId?: string;
  children?: React.ReactNode;
};

export const PageLayout = ({
  title,
  subtitle,
  supportTitle,
  contentTitle,
  create,
  children,
  themeId,
}: Props) => {
  const showContentHeader = contentTitle || create || supportTitle;
  return (
    <Page themeId={themeId ?? 'home'}>
      <Header title={title} subtitle={subtitle} />
      <Content>
        {showContentHeader && (
          <ContentHeader title={contentTitle ?? ''}>
            {create && <CreateButton title={create.title} to={create.to} />}
            {supportTitle && <SupportButton>{supportTitle}</SupportButton>}
          </ContentHeader>
        )}
        {children}
      </Content>
    </Page>
  );
};
