import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import {
  AbstractComponent,
  HeroComponent,
} from '@mercedes-benz/plugin-landing-page';
import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import DarkStIllustration from '../../assets/st-banner-dark.svg';
import StIllustration from '../../assets/st-banner-light.svg';
import { ScaffolderCategoriesGrid } from '../ScaffolderCategoriesGrid';
import { makeStyles } from 'tss-react/mui';
import { ValidationsDialog } from '../../dialogs/ValidationsDialog/ValidationsDialog';
import { TemplateSearchDialog } from '../../dialogs/TemplateSearchDialog/TemplateSearchDialog';
import { useSearchParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles()(() => ({
  hero: {
    '& img': {
      height: '350px',
      boxShadow: 'none',
    },
  },
  heroText: {
    fontWeight: 'normal',
  },
  card: {
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

export const ScaffolderLandingContentPage = () => {
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [validatorOpen, setValidatorOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(
    searchParams.get('search') !== null,
  );

  const darkMode = theme.palette.mode === 'dark';

  const handleValidatorOpen = () => {
    setValidatorOpen(true);
  };

  const handleValidatorClose = () => {
    setValidatorOpen(false);
  };

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Box padding={isMobile ? 4 : 6}>
          <HeroComponent
            className={classes.hero}
            heading="Software Templates"
            image={darkMode ? DarkStIllustration : StIllustration}
            button={{
              text: isMobile ? 'Create' : 'Create Something',
              onClick: handleSearchOpen,
            }}
            secondaryButton={{
              text: 'Validate',
              onClick: handleValidatorOpen,
            }}
          >
            <p className={classes.heroText}>
              Are used to create new software components through Backstage.
              These templates are intended as a starting point to build on for
              different use cases.
            </p>
            <br />
            <p className={classes.heroText}>
              Embrace the power of standardization and harness the complexity of
              production heterogeneity when creating{' '}
              <TypeAnimation
                sequence={[
                  'backend services.',
                  2000,
                  'frontend components.',
                  2000,
                  'CI-CD pipelines.',
                  2000,
                  'Kubernetes Resources.',
                  2000,
                  'AWS Infrastructure.',
                  2000,
                  'documentation projects.',
                  2000,
                ]}
                speed={20}
                style={{ color: darkMode ? '#ffff00' : '#2196f3' }}
                repeat={Infinity}
              />
            </p>
          </HeroComponent>

          <Box marginTop={12} position="relative" zIndex={1}>
            <ScaffolderCategoriesGrid />
          </Box>
          <AbstractComponent type="secondary" />
        </Box>
      </Container>
      <ValidationsDialog open={validatorOpen} onClose={handleValidatorClose} />
      <TemplateSearchDialog open={searchOpen} onClose={handleSearchClose} />
    </div>
  );
};
