import { createApiRef } from '@backstage/core-plugin-api';
import { ExtendedUserType, Role, User, UsersService } from '@mb.io/tdms-common';
import { actionPermissions } from '../permissions';
import { ApiClient } from './ApiClient';
import { API_VERSION } from './constants';

export const usersApiRef = createApiRef<UsersService>({
  id: 'plugin.tdms-next.users-api',
});

export class UsersApiClient extends ApiClient implements UsersService {
  getUsers(): Promise<User[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/users`,
      },
      actionPermissions.tenantUsers.read,
    );
  }

  queryUsers(
    query: string,
    page: number,
    size: number,
  ): Promise<{ content: User[] }> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/users/find?query=${query}&page=${page}&size=${size}`,
      },
      actionPermissions.tenantUsers.read,
    );
  }

  getRoles(): Promise<Role[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/roles`,
      },
      actionPermissions.tenants.read,
    );
  }

  getUsersWithRoles(): Promise<ExtendedUserType[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/permissions/roles/all`,
      },
      actionPermissions.tenantUsers.read,
    );
  }

  setUserRoles(daimlerId: string, body: string[]): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/permissions/roles/${daimlerId}`,
        body,
      },
      actionPermissions.tenantMembers.update,
    );
  }
}
