import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  Job,
  JobReport,
  JobUUID,
  SecHubApiService,
  Stage,
} from '@mercedes-benz/sechub-common';

export const sechubApiRef = createApiRef<SecHubApiService>({
  id: 'plugin.sechub.api',
});

export type SecHubRequest = {
  method: string;
  path: string;
  responseAsJson?: boolean;
};

export const splitProjectPhase = (projectId: string | undefined): string[] => {
  if (!projectId?.includes('@')) {
    throw new Error('Invalid projectId args');
  }
  return projectId.split('@');
};

export class SecHubApiClient implements SecHubApiService {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }

  getJob({ projectId, instanceName }: Stage): Promise<Job> {
    const projectIdSlugParts = projectId.includes('@')
      ? splitProjectPhase(projectId)
      : [projectId];
    const projectIdWithStage = projectIdSlugParts[0];
    const stage = projectIdSlugParts[1];

    let path = `/project/${encodeURIComponent(projectIdWithStage)}/jobs?instance=${encodeURIComponent(instanceName ?? '')}`;
    if (stage) {
      path += `&stage=${encodeURIComponent(stage)}`;
    }
    return this.submitRequest({
      method: 'GET',
      path,
    });
  }

  getJobReport({
    jobUUID,
    projectId,
    instanceName,
  }: JobUUID): Promise<JobReport> {
    const projectIdSlugParts = projectId.includes('@')
      ? splitProjectPhase(projectId)
      : [projectId];
    const projectIdWithStage = projectIdSlugParts[0];

    return this.submitRequest({
      method: 'GET',
      path: `/project/${encodeURIComponent(
        projectIdWithStage,
      )}/report/${encodeURIComponent(jobUUID)}?instance=${encodeURIComponent(
        instanceName || '',
      )}`,
    });
  }

  private async submitRequest({
    path,
    method,
    responseAsJson = true,
  }: SecHubRequest): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('sechub')}${path}`;
    const headers: Record<string, string> = {};
    const response = await this.fetchApi.fetch(url, { method, headers });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return responseAsJson ? response.json() : response;
  }
}
