import { RELATION_DEPENDS_ON } from '@backstage/catalog-model';
import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import React from 'react';
import {
  asSeamlessEntities,
  seamlessEntityColumns,
  seamlessEntityHelpLink,
} from '../seamless-constants';

type Props = {
  variant?: 'gridItem';
};

export const DependsOnSeamlessCard = ({ variant = 'gridItem' }: Props) => {
  return (
    <RelatedEntitiesCard
      variant={variant}
      title="Depends on Seamless Components"
      entityKind="Seamless"
      relationType={RELATION_DEPENDS_ON}
      columns={seamlessEntityColumns}
      asRenderableEntities={asSeamlessEntities}
      emptyMessage="No Seamless components is a dependency of this component."
      emptyHelpLink={seamlessEntityHelpLink}
    />
  );
};
