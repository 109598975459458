import { createRoutableExtension } from '@backstage/core-plugin-api';
import { techdocsPlugin } from '@backstage/plugin-techdocs';

export const EntityTechdocsContent = techdocsPlugin.provide(
  createRoutableExtension({
    name: 'EntityTechdocsContent',
    component: () =>
      import('./EmbeddedDocsRouter').then(m => m.EmbeddedDocsRouter),
    mountPoint: techdocsPlugin.routes.entityContent,
  }),
);
