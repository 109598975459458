import React, { useEffect, useState } from 'react';
import { Content, Header, HeaderLabel, Page } from '@backstage/core-components';
import { loadScripts, loadStylesheets, sanitizeHtml } from '../../utils/misc';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useAcademyEntity } from '../../hooks/useAcademyEntity';
import LinearProgress from '@mui/material/LinearProgress';
import { CodelabOverlays } from './CodelabOverlays';

const useStyles = makeStyles(theme => ({
  codelabs: {
    position: 'relative',
    height: '100%',
    '& #previous-step': {
      background:
        theme.palette.mode === 'light'
          ? 'rgb(255, 255, 255)'
          : 'rgb(26, 26, 26)',
    },
    '& #codelab-title': {
      position: 'absolute',
      background:
        theme.palette.mode === 'light'
          ? 'rgb(255, 255, 255)'
          : 'rgb(26, 26, 26)',
      color:
        theme.palette.mode === 'light'
          ? 'rgb(26, 26, 26)'
          : 'rgb(255, 255, 255)',
    },
    '& google-codelab': {
      '& .steps': {
        backgroundColor:
          theme.palette.mode === 'light'
            ? 'rgb(255, 255, 255)'
            : 'rgb(0, 0, 0)',
      },
      '& .metadata': {
        backgroundColor:
          theme.palette.mode === 'light'
            ? 'rgb(255, 255, 255)'
            : 'rgb(0, 0, 0)',
      },
      '& #codelab-nav-buttons #arrow-back': {
        color:
          theme.palette.mode === 'light'
            ? 'rgb(26, 26, 26)'
            : 'rgb(255, 255, 255)',
      },
      '& #steps': {
        background:
          theme.palette.mode === 'light'
            ? 'rgb(255, 255, 255)'
            : 'rgb(26, 26, 26)',
      },
      '& #drawer ol': {
        '& li[selected] a:focus': {
          background:
            theme.palette.mode === 'light'
              ? 'rgb(198,198,198)'
              : 'rgb(59, 59, 59)',
        },
        '& li[completed] a': {
          color:
            theme.palette.mode === 'light'
              ? 'rgb(26, 26, 26)'
              : 'rgb(255, 255, 255)',
          background:
            theme.palette.mode === 'light'
              ? 'rgb(255, 255, 255)'
              : 'rgb(0,0,0)',
        },
        '& li a': {
          background:
            theme.palette.mode === 'light'
              ? 'rgb(255, 255, 255)'
              : 'rgb(26, 26, 26)',
          color:
            theme.palette.mode === 'light'
              ? 'rgb(26, 26, 26)'
              : 'rgb(255, 255, 255)',
        },
      },
    },
    '& google-codelab-step': {
      background:
        theme.palette.mode === 'light'
          ? 'rgb(255, 255, 255) !important'
          : 'rgb(0, 0, 0) !important',
    },
    '& google-codelab-step .instructions': {
      maxWidth: '1200px !important',
      margin: '0 auto 90px !important',
      borderRadius: '4px !important',
      background:
        theme.palette.mode === 'light'
          ? 'rgb(255, 255, 255) !important'
          : 'rgb(26, 26, 26) !important',

      '& :not(pre) > code': {
        background:
          theme.palette.mode === 'light'
            ? 'rgb(230, 230, 230)'
            : 'rgb(26, 26, 26)',
      },
    },
  },
}));

const stylesheetUrls = [
  '/academy/material-icons.css?v=1.0.0',
  '/academy/codelab-elements.css?v=1.0.0',
  'https://fonts.googleapis.com/css2?family=Material+Icons', // Added this as loading font-face from /academy/material-icons.woff2 is slow
];

const scripts = ['codelab-elements.js', 'native-shim.js', 'prettify.js'];

function addDataTestIds(htmlFile: Document): Document {
  const previousStep = htmlFile.querySelector('a#previous-step');
  if (previousStep) {
    previousStep.setAttribute('data-testid', 'previous-step-button');
  }

  const nextStepLink = htmlFile.querySelector('a#next-step');
  if (nextStepLink) {
    nextStepLink.setAttribute('data-testid', 'next-step-button');
  }

  return htmlFile;
}

async function renderCodelab(url: string) {
  let htmlString = await (await fetch(url)).text();

  // Parse the HTML string to a DOM object
  const parser = new DOMParser();
  const htmlFile = parser.parseFromString(htmlString, 'text/html');

  const htmlFileWithTestIds = addDataTestIds(htmlFile);

  const serializer = new XMLSerializer();
  htmlString = serializer.serializeToString(htmlFileWithTestIds);

  const academyEntity = htmlString.match(
    /<google-codelab codelab-gaid=".*?">(.*?)<\/google-codelab>/gs,
  );
  if (!academyEntity || academyEntity.length === 0) {
    throw new Error('No codelab found');
  }

  return sanitizeHtml(academyEntity[0]);
}

type CodelabProps = {
  namespaceId: string;
  tutorialName: string;
  showHeader?: boolean;
};

export function Codelab({
  namespaceId,
  tutorialName,
  showHeader = true,
}: Readonly<CodelabProps>) {
  const [loading, setLoading] = useState(false);
  const [codelabHtml, setCodelabHtml] = useState<undefined | string>(undefined);
  const theme = useTheme();
  const classes = useStyles(theme);
  const { entity, imgUrl, indexUrl, academyBaseUrl } = useAcademyEntity(
    namespaceId,
    tutorialName,
  );

  useEffect(() => {
    if (!entity || !imgUrl || !indexUrl) {
      return;
    }

    setLoading(true);

    renderCodelab(indexUrl)
      .then(htmlString => {
        // Replace image relative path to Academy content API image url
        const finalHtmlString = htmlString.replace(/img\//g, imgUrl);

        setCodelabHtml(finalHtmlString);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        throw new Error(err);
      });
  }, [entity, imgUrl, indexUrl]);

  useEffect(() => {
    let scriptUrls: string[];

    if (academyBaseUrl) {
      scriptUrls = scripts.map(
        scriptName =>
          `${academyBaseUrl}/content/static/academy_src/${scriptName}`,
      );
      loadScripts(scriptUrls);
      loadStylesheets(stylesheetUrls);
    }

    // Cleanup when unmounting
    return () => {
      stylesheetUrls.forEach(stylesheetUrl => {
        const linkElement = document.querySelector(
          `link[href="${stylesheetUrl}"]`,
        );
        if (linkElement) {
          document.head.removeChild(linkElement);
        }
      });

      scriptUrls?.forEach(scriptUrl => {
        const scriptElement = document.querySelector(
          `script[src="${scriptUrl}"]`,
        );
        if (scriptElement) {
          document.body.removeChild(scriptElement);
        }
      });
    };
  }, [academyBaseUrl]);

  return (
    <Page themeId="tool">
      {showHeader && (
        <Header
          title="Academy"
          subtitle="Let's Learn Something New: Unlock Your Full Potential"
        >
          <HeaderLabel label="Owner" value="Nebula" />
          <HeaderLabel label="Lifecycle" value="Alpha" />
        </Header>
      )}
      <Content>
        {loading && <LinearProgress />}
        {entity && <CodelabOverlays entity={entity} />}
        {codelabHtml && (
          <div
            className={classes.codelabs}
            dangerouslySetInnerHTML={{ __html: codelabHtml as any }}
          />
        )}
      </Content>
    </Page>
  );
}
