import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

export const datadogRumPlugin = createPlugin({
  id: 'datadog-rum',
});

export const DatadogRum = datadogRumPlugin.provide(
  createComponentExtension({
    name: 'DatadogRum',
    component: {
      lazy: () => import('./components/DatadogRum').then(m => m.DatadogRum),
    },
  }),
);
