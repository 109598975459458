import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const infrastructurePlugin = createPlugin({
  id: 'infrastructure',
  routes: {
    root: rootRouteRef,
  },
});

export const AwsInfraOverviewCard = infrastructurePlugin.provide(
  createComponentExtension({
    name: 'AwsInfraOverviewCard',
    component: {
      lazy: () =>
        import('./components/aws-infra-overview-card').then(
          m => m.AwsInfraOverviewCard,
        ),
    },
  }),
);

export const InfraOverviewCard = infrastructurePlugin.provide(
  createComponentExtension({
    name: 'InfraOverviewCard',
    component: {
      lazy: () =>
        import('./components/infra-overview-card').then(
          m => m.InfraOverviewCard,
        ),
    },
  }),
);

export const ElasticacheContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'OpenSearchContentPage',
    component: () =>
      import('./components/elasticache/').then(m => m.ElasticacheContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const OpenSearchContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'OpenSearchContentPage',
    component: () =>
      import('./components/open-search/').then(m => m.OpenSearchContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const DocumentDBContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'DocumentDBContentPage',
    component: () =>
      import('./components/document-db/').then(m => m.DocumentDBContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const EFSContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'EFSContentPage',
    component: () => import('./components/efs/').then(m => m.EFSContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const EKSContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'EKSContentPage',
    component: () => import('./components/eks/').then(m => m.EKSContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const GatewayContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'GatewayContentPage',
    component: () =>
      import('./components/gateway/').then(m => m.GatewayContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const DynamoDBTableContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'DynamoDBTableContentPage',
    component: () =>
      import('./components/dynamodb/').then(m => m.DynamoDBTableContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const DynamoDBStreamContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'DynamoDBStreamContentPage',
    component: () =>
      import('./components/dynamodb/').then(m => m.DynamoDBStreamContentPage),
    mountPoint: rootRouteRef,
  }),
);
export const LambdaContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'LambdaContentPage',
    component: () =>
      import('./components/lambda/').then(m => m.LambdaContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const MemoryDBContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'MemoryDBContentPage',
    component: () =>
      import('./components/memory-db/').then(m => m.MemoryDBContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const MQBrokerContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'MQBrokerContentPage',
    component: () =>
      import('./components/mq/').then(m => m.MQBrokerContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const RDSContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'RDSContentPage',
    component: () => import('./components/rds/').then(m => m.RDSContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const SNSTopicContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'SNSTopicContentPage',
    component: () =>
      import('./components/sns/').then(m => m.SNSTopicContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const SQSQueueContentPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'SQSQueueContentPage',
    component: () =>
      import('./components/sqs/').then(m => m.SQSQueueContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const AccountInfrastructureCard = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'AccountInfrastructureCard',
    component: () =>
      import('./components/account-infrastructure-card/').then(
        m => m.AccountInfrastructureCard,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const SystemInfrastructureCard = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'SystemInfrastructureCard',
    component: () =>
      import('./components/system-infrastructure-card/').then(
        m => m.SystemInfrastructureCard,
      ),
    mountPoint: rootRouteRef,
  }),
);
