import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type ColumnBreakpoints = Record<Breakpoint, number>;

const colDefaults: ColumnBreakpoints = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 2,
  xl: 3,
};

export function useDynamicColumns(
  cols: ColumnBreakpoints | number | undefined,
): number {
  const matches: (Breakpoint | null)[] = [
    useMediaQuery((theme: Theme) => theme.breakpoints.up('xl')) ? 'xl' : null,
    useMediaQuery((theme: Theme) => theme.breakpoints.up('lg')) ? 'lg' : null,
    useMediaQuery((theme: Theme) => theme.breakpoints.up('md')) ? 'md' : null,
    useMediaQuery((theme: Theme) => theme.breakpoints.up('sm')) ? 'sm' : null,
    useMediaQuery((theme: Theme) => theme.breakpoints.up('xs')) ? 'xs' : null,
  ];

  let numOfCols = 1;

  if (typeof cols === 'number') {
    numOfCols = cols;
  } else {
    const breakpoint = matches.find(k => k !== null) ?? 'xs';
    numOfCols = cols?.[breakpoint] ?? colDefaults[breakpoint];
  }

  return numOfCols;
}
