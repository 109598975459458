export function useSlice<T = any>(data: T[], limit: number) {
  return data.length > limit
    ? {
        data: data.slice(0, limit),
        hasMore: true,
      }
    : {
        data,
        hasMore: false,
      };
}
