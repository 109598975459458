import { KubernetesValidatorFunctions } from '@backstage/catalog-model';
import {
  EntityValidation,
  isRequiredOnTypes,
  isValidNpmArtifact,
  nameValidationMessage,
  typeValidationMessage,
} from '../../common';
import {
  NPM_ARTIFACT_ANNOTATION,
  NPM_REGISTRY_ANNOTATION,
} from './annotations/types';
import {
  SEAMLESS_PACKAGE_CONTAINED_TYPES,
  SEAMLESS_PROJECT_CONTAINED_TYPES,
  SEAMLESS_TYPES,
  SeamlessEntity,
} from '../index';

export const SEAMLESS_VALIDATIONS_SPEC: EntityValidation<SeamlessEntity>[] = [
  {
    field: 'spec.type',
    message: entity =>
      `Type '${
        entity.spec.type
      }' is not supported for Seamless kind ${typeValidationMessage(entity)}.`,
    validate: entity => SEAMLESS_TYPES.includes(entity.spec.type),
  },
  {
    field: 'spec.project',
    message: entity =>
      `Attribute project is required for Seamless type '${
        entity.spec.type
      }' ${typeValidationMessage(entity)}.`,
    validate: entity =>
      isRequiredOnTypes(entity, 'project', SEAMLESS_PROJECT_CONTAINED_TYPES),
  },
  {
    field: 'spec.project',
    message: entity =>
      `Attribute project is invalid ${typeValidationMessage(
        entity,
      )}. ${nameValidationMessage('spec.project')}`,
    validate: entity =>
      !entity.spec.project ||
      KubernetesValidatorFunctions.isValidObjectName(entity.spec.project),
  },
  {
    field: 'spec.package',
    message: entity =>
      `Attribute package is required for Seamless type '${
        entity.spec.type
      }' ${typeValidationMessage(entity)}.`,
    validate: entity =>
      isRequiredOnTypes(entity, 'package', SEAMLESS_PACKAGE_CONTAINED_TYPES),
  },
  {
    field: 'spec.package',
    message: entity =>
      `Attribute package is invalid ${typeValidationMessage(
        entity,
      )}. ${nameValidationMessage('spec.package')}`,
    validate: entity =>
      !entity.spec.package ||
      KubernetesValidatorFunctions.isValidObjectName(entity.spec.package),
  },
  {
    field: `metadata.annotations[npmjs.com/registry-url]`,
    message: entity =>
      `Invalid value "${
        entity.metadata.annotations?.[NPM_REGISTRY_ANNOTATION]
      }" for ${NPM_REGISTRY_ANNOTATION} annotation ${typeValidationMessage(
        entity,
      )}. It must be a valid URL`,
    validate: entity => {
      const artifact = entity.metadata.annotations?.[NPM_REGISTRY_ANNOTATION];
      return !artifact || isValidNpmArtifact(artifact);
    },
  },
  {
    field: `metadata.annotations[npmjs.com/artifact]`,
    message: entity =>
      `Invalid value "${
        entity.metadata.annotations?.[NPM_ARTIFACT_ANNOTATION]
      }" for 'npmjs.com/artifact' annotation ${typeValidationMessage(
        entity,
      )}. Allowed format is <scope/name>, where 'scope' starts with @ and only allows characters from a-z, while 'name' allowed characters are a-z and number 0-9 and -. Min 4 to 255 chars.`,
    validate: entity => {
      const artifact = entity.metadata.annotations?.[NPM_ARTIFACT_ANNOTATION];
      return !artifact || isValidNpmArtifact(artifact);
    },
  },
];
