import { JSONSchema6 } from 'json-schema';
import Schema from './schema/academy.alpha.schema.json';

export { AcademyEntityPolicy } from './policy/academy-entity.policy';
export type { AcademyAlphaEntity } from './academy.alpha.entity';
export type { AcademyAlphaEntity as AcademyEntity } from './academy.alpha.entity';

export * from './types';

export const AcademyAlphaSchema: JSONSchema6 = Schema as Omit<
  JSONSchema6,
  'examples'
>;
