import {
  AcademyProjectMetadata,
  CheckResponse,
  MetadataApi,
  RequestOptions,
} from '@mercedes-benz/academy-common';
import { DiscoveryApi, FetchApi } from '../types';
import { BaseClient } from '../base.client';
import { Codelab } from '@mb.io/catalog-model';

/**
 * A frontend and backend compatible client for communicating with the Academy Metadata/Content API
 *
 * @public
 */
export class MetadataClient extends BaseClient implements MetadataApi {
  constructor(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    super(discoveryApi, fetchApi, '/content');
  }

  async getItemMetadata(
    projectKey: string,
    academyId: string,
    options?: RequestOptions,
  ): Promise<Codelab> {
    return this.request({
      method: 'GET',
      path: `/metadata/${encodeURIComponent(projectKey)}/${encodeURIComponent(academyId)}`,
      options,
    });
  }

  async getProjectMetadata(
    projectKey: string,
    options?: RequestOptions,
  ): Promise<AcademyProjectMetadata> {
    return this.request({
      method: 'GET',
      path: `/metadata/${encodeURIComponent(projectKey)}`,
      options,
    });
  }

  async hasContentAvailable(
    projectKey: string,
    academyId: string,
    options?: RequestOptions,
  ): Promise<CheckResponse> {
    return this.request({
      method: 'GET',
      path: `/check/${encodeURIComponent(projectKey)}/${encodeURIComponent(academyId)}`,
      options,
    });
  }

  getProjects(options?: RequestOptions): Promise<string[]> {
    return this.request({
      method: 'GET',
      path: `/projects`,
      options,
    });
  }
}
