import { createComponentExtension } from '@backstage/core-plugin-api';
import { AboutCardProps, catalogPlugin } from '@backstage/plugin-catalog';
import React from 'react';

export const EntityAboutCard: (props: AboutCardProps) => React.JSX.Element =
  catalogPlugin.provide(
    createComponentExtension({
      name: 'EntityAboutCard',
      component: {
        lazy: () => import('./AboutCard').then(m => m.AboutCard),
      },
    }),
  );
