/**
 * A relation with a Seamless Project or Package entity, typically from a component.
 * Reversed direction of {@link RELATION_CONTAINS}.
 */
export const RELATION_CONTAINED_IN = 'containedIn';

/**
 * A relation with a collection of Seamless components being contained by a Project or Package.
 * Reversed direction of {@link RELATION_CONTAINED_IN}.
 */
export const RELATION_CONTAINS = 'contains';

/**
 * Relates a Seamless Project with his child packages.
 *
 * Reversed direction of {@link RELATION_PACKAGE_OF}.
 */
export const RELATION_HAS_PACKAGE = 'hasPackage';

/**
 * Relates a Seamless Package with a Seamless Project
 *
 * Reversed direction of {@link RELATION_HAS_PACKAGE}.
 */
export const RELATION_PACKAGE_OF = 'packageOf';
