import { Entity, EntityPolicy } from '@backstage/catalog-model';
import { EntityValidator } from '../../common';
import { SeamlessEntity } from './index';
import { SEAMLESS_VALIDATIONS_SPEC } from './seamless-entity-validations';

/**
 * Ensures that the format of individual fields of the Seamless entity envelope
 * is valid, by applying a Seamless validation spec {@link SEAMLESS_VALIDATIONS_SPEC}.
 */
export class SeamlessEntityPolicy implements EntityPolicy {
  constructor(
    private readonly validator = new EntityValidator<SeamlessEntity>(
      SEAMLESS_VALIDATIONS_SPEC,
    ),
  ) {
    this.validator = validator;
  }

  async enforce(entity: SeamlessEntity): Promise<Entity> {
    if (entity.kind === 'Seamless') {
      this.validator.applyDefaults(entity);
    }
    return entity;
  }
}
