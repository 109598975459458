import { createApiRef } from '@backstage/core-plugin-api';
import {
  EntitiesService,
  IdentityEntities,
  OrgEntities,
} from '@mb.io/tdms-common';
import { actionPermissions } from '../permissions';
import { ApiClient } from './ApiClient';
import { API_VERSION } from './constants';

export const entitiesApiRef = createApiRef<EntitiesService>({
  id: 'plugin.tdms-next.entities-api',
});

export class EntitiesApiClient extends ApiClient implements EntitiesService {
  getOrgEntities<T = OrgEntities>(): Promise<T> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/entities`,
      },
      actionPermissions.tenants.read,
    );
  }

  getIdentityEntities(): Promise<IdentityEntities> {
    return this.getOrgEntities();
  }
}
