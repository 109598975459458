import Typography from '@mui/material/Typography';
import { DismissableBanner, Link } from '@backstage/core-components';
import React from 'react';

export const TenantOnboardingBanner = () => (
  <DismissableBanner
    message={
      <Typography>
        📚 Do you need help onboarding components of your tenant?{' '}
        <Link
          to="https://backstage.i.mercedes-benz.com/academy/default/tutorial/dh-io-techdocs-academy-backstage-overview"
          color="textPrimary"
        >
          Follow this tenant onboarding instructions.
        </Link>
      </Typography>
    }
    variant="info"
    id="tenant-onboarding-link"
  />
);
