import { parseEntityRef } from '@backstage/catalog-model';
import { ResponseError } from '@backstage/errors';
import { JsonObject } from '@backstage/types';
import {
  PREFERENCE_OPT_OUT,
  SettingsFilter,
  SettingsRequestOptions,
  USER_SETTINGS_API_PATH,
  UserSetting,
  UserSettingData,
  UserSettingsApi,
  settingEndpoint,
} from '@mercedes-benz/user-settings-common';

type Discovery = { getBaseUrl: (pluginId: string) => Promise<string> };
type FetchApi = { fetch: typeof fetch };

/**
 * A frontend and backend compatible client for communicating with the User
 * settings API.
 *
 * @public
 */
export class UserSettingsClient implements UserSettingsApi {
  private readonly discoveryApi: Discovery;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: Discovery; fetchApi?: FetchApi }) {
    this.fetchApi = options.fetchApi ?? { fetch };
    this.discoveryApi = options.discoveryApi;
  }

  async delete(
    filter: SettingsFilter,
    options: SettingsRequestOptions,
  ): Promise<void> {
    const { bucket, key } = filter;
    await this.submitRequest('DELETE', settingEndpoint(bucket, key), options);
  }

  async get(
    filter: SettingsFilter,
    options: SettingsRequestOptions,
  ): Promise<UserSetting | undefined> {
    const { bucket, key } = filter;
    return this.submitRequest('GET', settingEndpoint(bucket, key), options);
  }

  async getAll(
    filter: SettingsFilter,
    options: SettingsRequestOptions,
  ): Promise<UserSetting[]> {
    const { bucket, key } = filter;
    return (
      (await this.submitRequest(
        'GET',
        `${settingEndpoint(bucket, key)}/all`,
        options,
      )) ?? []
    );
  }

  async getUsersOptOut(
    optOutFeature: string,
    token: string,
  ): Promise<string[]> {
    const usersOptOutPreferences = await this.getAll(
      {
        bucket: PREFERENCE_OPT_OUT,
        key: optOutFeature,
      },
      { token },
    );

    return usersOptOutPreferences
      .filter(
        usersOptOutPreference =>
          (usersOptOutPreference.value as JsonObject).optOut === true,
      )
      .map(userPreference =>
        parseEntityRef(userPreference.userEntityRef).name.toUpperCase(),
      );
  }

  async set(
    data: UserSettingData,
    options: SettingsRequestOptions,
  ): Promise<UserSetting | undefined> {
    const { bucket, key, value } = data;

    const body = JSON.stringify({
      value,
    });

    return await this.submitRequest<UserSetting>(
      'PUT',
      settingEndpoint(bucket, key),
      options,
      body,
    );
  }

  private async submitRequest<T>(
    method: string,
    path: string,
    options?: SettingsRequestOptions,
    body?: unknown,
  ): Promise<T | undefined> {
    const url = `${await this.discoveryApi.getBaseUrl(
      USER_SETTINGS_API_PATH,
    )}${path}`;
    const response = await this.fetchApi.fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
      body: body as never,
    });

    if (options?.required && !response.ok) {
      throw await ResponseError.fromResponse(response);
    } else if (!response.ok) {
      if (response.status === 404) {
        return undefined;
      }
      throw await ResponseError.fromResponse(response);
    }
    return response.status !== 204 ? await response.json() : undefined;
  }
}
