import { isValidScope, scopeRegex } from '@mb.io/catalog-model';
import { FieldValidation } from '@rjsf/utils';

export const validateNpmScope = (
  value: string,
  validation: FieldValidation,
) => {
  if (!isValidScope(value)) {
    validation.addError(
      `Invalid NPM scope ${value}. Should start with @ and match ${scopeRegex}`,
    );
  }
};
