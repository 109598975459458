import { RELATION_MEMBER_OF, UserEntity } from '@backstage/catalog-model';
import { getEntityRelations } from '@backstage/plugin-catalog-react';

export const useUserGroups = (user: UserEntity | undefined): string[] => {
  return (
    getEntityRelations(user, RELATION_MEMBER_OF)
      ?.filter(member => member.kind.toLocaleLowerCase('en-US') === 'group')
      .map(group => group.name) ?? []
  );
};
