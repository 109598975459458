import {
  CompoundEntityRef,
  DEFAULT_NAMESPACE,
  Entity,
} from '@backstage/catalog-model';

export type SoftEntityName = {
  kind?: string;
  namespace?: string;
  name: string;
};

export function parseEntityRef(ref: string): SoftEntityName {
  const match = /^([^:/]+:)?([^:/]+\/)?([^:/]+)$/.exec(ref.trim());
  if (!match) {
    throw new TypeError(
      `Entity reference "${ref}" was not on the form [<kind>:][<namespace>/]<name>`,
    );
  }

  return {
    kind: match[1]?.slice(0, -1),
    namespace: match[2]?.slice(0, -1),
    name: match[3],
  };
}

export function parseResourceRef(
  ref: string,
  context: { defaultKind: string; defaultNamespace: string },
): CompoundEntityRef {
  const entityRef = parseEntityRef(ref);

  return {
    kind: entityRef.kind ?? context.defaultKind,
    namespace: entityRef.namespace ?? context.defaultNamespace,
    name: entityRef.name,
  };
}

export const parseEntityRefs = (refs: string[]): SoftEntityName[] =>
  refs.map(ref => parseEntityRef(ref));

export function entityRefToQueryParams(
  entityRef: Entity | CompoundEntityRef,
): CompoundEntityRef {
  let kind;
  let namespace;
  let name;

  if ('metadata' in entityRef) {
    kind = entityRef.kind;
    namespace = entityRef.metadata.namespace;
    name = entityRef.metadata.name;
  } else {
    kind = entityRef.kind;
    namespace = entityRef.namespace;
    name = entityRef.name;
  }

  return {
    name,
    kind: kind.toLocaleLowerCase('en-US'),
    namespace: namespace?.toLocaleLowerCase('en-US') ?? DEFAULT_NAMESPACE,
  };
}
