import { EntitySwitch, isComponentType } from '@backstage/plugin-catalog';
import React from 'react';
import { DefaultEntityPage } from '../default/DefaultEntityPage';
import { DocumentationPage } from './DocumentationPage';
import { LibraryPage } from './LibraryPage';
import { ServicePage } from './ServicePage';
import { WebsitePage } from './WebsitePage';

export const ComponentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {ServicePage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {WebsitePage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('documentation')}>
      {DocumentationPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('library')}>
      {LibraryPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{DefaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
