import { Entity } from '@backstage/catalog-model';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { useGithubProjectEntity, useGithubUrl } from '@mb.io/core-components';
import { graphql } from '@octokit/graphql';
import { GraphQlResponse } from '@octokit/graphql/dist-types/types';
import useAsync from 'react-use/esm/useAsync';

export const useDependabotAlerts = (entity: Entity) => {
  const { owner, repo } = useGithubProjectEntity(entity);
  const auth = useApi(githubAuthApiRef);
  const { hostname, graphqlBaseUrl } = useGithubUrl(entity);

  const query = `query GetDependabotAlertsWidget($name: String!, $owner: String!) {
    repository(name: $name, owner: $owner) {
      vulnerabilityAlerts(first: 100) {
        totalCount
        nodes {
          createdAt
          id
          dismissedAt
          vulnerableManifestPath
          state
          securityVulnerability {
            vulnerableVersionRange
            package {
              name
            }
            firstPatchedVersion {
              identifier
            }
            severity
            advisory {
              description
            }
          }
        }
      }
    }
  }`;

  const { value, loading, error } = useAsync(async (): Promise<any> => {
    const token = await auth.getAccessToken(['repo']);
    const gqlEndpoint = graphql.defaults({
      baseUrl: graphqlBaseUrl,
      headers: {
        authorization: `token ${token}`,
      },
    });
    const { repository } = await gqlEndpoint<
      GraphQlResponse<Record<string, any>>
    >(query, {
      name: repo,
      owner: owner,
    });
    return repository;
  }, []);

  const repoLocation = { hostname, owner, repo };
  return {
    value,
    loading,
    error,
    repoLocation,
  };
};
