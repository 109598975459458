import { InfoCard, StructuredMetadataTable } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import Chip from '@mui/material/Chip';
import React from 'react';
import { major } from 'semver';
import { ManifestProps } from '../AemManifestPage';

export const ManifestSummaryCard = ({ manifestFile }: ManifestProps) => {
  const config = useApi(configApiRef);
  const aemUrlPrefix = manifestFile?.version
    ? `${config.getString(
        'integrations.seamless.aem.url',
      )}${manifestFile?.name}/v${major(
        manifestFile?.version,
      )}/${manifestFile?.name}`
    : '';
  const metadata = {
    name: manifestFile?.name.toUpperCase(),
    version: manifestFile?.version,
    manifestVersion: manifestFile?.manifestVersion,
    title: manifestFile?.aemComponentConfig.title,
    description: manifestFile?.aemComponentConfig.description,
    market_availability: (
      <>
        {manifestFile?.config.marketAvailability?.map(
          (
            item: { country: string | undefined },
            index: React.Key | null | undefined,
          ) => <Chip key={index} label={item.country} />,
        )}
      </>
    ),
    styles: manifestFile?.config.files?.styles?.length ?? 'No Styles',
    scripts: manifestFile?.config.files?.scripts?.length ?? 'No Scripts',
    dependencies: Object.entries(manifestFile?.config?.dependencies ?? {})?.map(
      ([name, version]) => (
        <Chip label={`${name}:${version}`} variant="outlined" />
      ),
    ),
    isUserSessionRequired: manifestFile?.config.isUserSessionRequired,
    integrationType: manifestFile?.config.integrationType,
    aemUrl: (
      <a target="_blank" href={aemUrlPrefix}>
        <Chip
          label={`${manifestFile?.name}:${manifestFile?.version}`}
          variant="outlined"
        />
      </a>
    ),
  };

  return (
    <InfoCard title="AEM Manifest Summary" key="test">
      <StructuredMetadataTable
        data-testid="metadata"
        metadata={metadata}
        dense
      />
    </InfoCard>
  );
};
