import { RELATION_HAS_PART } from '@backstage/catalog-model';
import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import React from 'react';
import {
  asSeamlessEntities,
  seamlessEntityColumns,
  seamlessEntityHelpLink,
} from '../seamless-constants';

type Props = {
  variant?: 'gridItem';
};

export const SeamlessHasSubcomponentsCard = ({
  variant = 'gridItem',
}: Props) => {
  return (
    <RelatedEntitiesCard
      variant={variant}
      title="Has subcomponents"
      entityKind="Seamless"
      relationType={RELATION_HAS_PART}
      columns={seamlessEntityColumns}
      asRenderableEntities={asSeamlessEntities}
      emptyMessage="No subcomponent is part of this component"
      emptyHelpLink={seamlessEntityHelpLink}
    />
  );
};
