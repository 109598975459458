import { GridSize } from '@mui/material/Grid';
import { useElementFilter } from '@backstage/core-plugin-api';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { DefaultGrid } from '../DefaultGrid';

type Props = {
  children: Array<React.JSX.Element | boolean>;
  xs?: GridSize | boolean;
  sm?: GridSize | boolean;
  md?: GridSize | boolean;
  lg?: GridSize | boolean;
  xl?: GridSize | boolean;
  className?: string;
};

const useStyles = makeStyles<{ childCount: number }>()(
  (_theme, { childCount }) => ({
    root: {
      '&>div': {
        // max-width is required for `text-overflow: ellipsis` to work
        maxWidth: `${Math.round((100 / childCount) * 100) / 100}%`,
      },
    },
  }),
);

/**
 * Wrapper to control responsive behavior of OvervieField-Group
 */
export function OverviewFieldGroup({
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  className,
}: Props) {
  const childElements = useElementFilter(children, c => c.getElements());
  const { classes } = useStyles({ childCount: childElements.length });
  const childrenWithProps = React.Children.map(childElements, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { responsive: true } as any);
    }
    return child;
  });

  return (
    <DefaultGrid
      variant="itemContainer"
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      className={className ? `${classes.root} ${className}` : classes.root}
      role="row"
    >
      {childrenWithProps}
    </DefaultGrid>
  );
}
