import React, { JSX } from 'react';

import { GitHubActionsIcon } from './GitHubActionsIcon';
import { AwsIcon } from './AwsIcon';
import { BackstageIcon } from './BackstageIcon';
import { BackendIcon } from './BackendIcon';
import { KubernetesIcon } from './KubernetsIcon';
import WebIcon from '@mui/icons-material/Web';
import DocumentationIcon from '@mui/icons-material/AutoStories';
import { BlueBoxIcon } from './BlueBoxIcon';

export const CATEGORY_ICONS: Record<string, JSX.Element> = {
  cicd: <GitHubActionsIcon />,
  infrastructure: <AwsIcon />,
  catalog: <BackstageIcon />,
  backend: <BackendIcon />,
  kubernetes: <KubernetesIcon />,
  seamless: <WebIcon />,
  documentation: <DocumentationIcon />,
  bluebox: <BlueBoxIcon />,
};
