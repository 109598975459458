import {
  Enrollment,
  Page,
  ProgressSessionRequest,
  RequestOptions,
  SessionsApi,
  SortOrder,
  StartSessionRequest,
  TrainingSession,
} from '@mercedes-benz/academy-common';
import { DiscoveryApi, FetchApi } from '../types';
import { BaseClient } from '../base.client';

/**
 * A frontend and backend compatible client for communicating with the Academy Sessions API
 *
 * @public
 */
export class SessionsClient extends BaseClient implements SessionsApi {
  constructor(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    super(discoveryApi, fetchApi, '/sessions');
  }

  async start(
    request: StartSessionRequest,
    options?: RequestOptions,
  ): Promise<TrainingSession> {
    return this.request({
      method: 'POST',
      path: `/start`,
      body: JSON.stringify(request),
      options,
    });
  }

  async progress(
    request: ProgressSessionRequest,
    options?: RequestOptions,
  ): Promise<TrainingSession> {
    return this.request({
      method: 'POST',
      path: `/progress`,
      body: JSON.stringify(request),
      options,
    });
  }

  async getAll(
    pageNumber: number = 1,
    pageSize: number = 10,
    sortBy: keyof Enrollment = 'createdAt',
    sortOrder = SortOrder.DESC,
    options?: RequestOptions,
  ): Promise<Page<TrainingSession>> {
    return this.request({
      method: 'GET',
      path: `/page=${encodeURIComponent(pageNumber)}&pageSize=${encodeURIComponent(pageSize)}&sortBy=${encodeURIComponent(sortBy)}&sortOrder=${encodeURIComponent(sortOrder)}`,
      options,
    });
  }

  async getById(
    sessionId: string,
    options?: RequestOptions,
  ): Promise<TrainingSession> {
    return this.request({
      method: 'GET',
      path: `/${encodeURIComponent(sessionId)}`,
      options,
    });
  }
}
