import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { TDMSRequest } from '@mb.io/tdms-common';
import { systemRoleFetcher } from '@mercedes-benz/permission';
import { ActionPayload } from '@mercedes-benz/permission-common';

export class ApiClient {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }

  protected async submitRequest(
    { path, method, body, responseAsJson = true }: TDMSRequest,
    ref?: ActionPayload,
  ): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('tdms')}${path}`;

    const headers: Record<string, string> = {
      'content-type': 'application/json',
    };
    const response = await systemRoleFetcher(
      this.fetchApi.fetch,
      url,
      {
        method,
        headers,
        body: JSON.stringify(body),
      },
      ref,
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return responseAsJson ? response.json() : response;
  }
}
