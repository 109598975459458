import { EntityLayout } from '@backstage/plugin-catalog';
import React from 'react';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';
import { GenericOverviewContent } from '../generic/OverviewContent';
import { TechdocsContent } from '../generic/TechdocsContent';
import { Codelab } from '@mercedes-benz/academy';
import { useParams } from 'react-router';
import { Entity } from '@backstage/catalog-model';

function CodelabRoute() {
  const { namespace, name } = useParams<{
    namespace: string;
    name: string;
  }>();

  return (
    <Codelab
      showHeader={false}
      namespaceId={namespace ?? ''}
      tutorialName={name ?? ''}
    />
  );
}

function isTutorialType(type: string) {
  return (entity: Entity) => {
    return entity?.spec?.type?.toString().toLowerCase() === type.toLowerCase();
  };
}

export const AcademyPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {GenericOverviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {TechdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route
      if={isTutorialType('tutorial')}
      path="/codelab"
      title="Detail"
    >
      <CodelabRoute />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
