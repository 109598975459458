import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import {
  ANNOTATION_VIEW_URL,
  RELATION_OWNED_BY,
} from '@backstage/catalog-model';
import { getEntityRelations, useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';
import { useCardClasses } from '../../hooks';
import { SupportChannels } from '../SupportChannels';
import { TeamMembersContact } from '../TeamMembersContact';

type Props = {
  variant?: 'gridItem' | 'fullHeight';
};

export const TeamContactsCard = ({ variant = 'gridItem' }: Props) => {
  const { entity } = useEntity();
  const { cardClass, cardContentClass } = useCardClasses(variant);

  const owners = getEntityRelations(entity, RELATION_OWNED_BY);

  const entityUrl = entity.metadata.annotations?.[ANNOTATION_VIEW_URL];

  return (
    <Card className={cardClass}>
      {owners.length > 0 && (
        <CardHeader
          title="Support"
          subheader={
            <SupportChannels owner={owners[0]} originUrl={entityUrl} />
          }
        />
      )}
      {owners.length > 0 && <Divider />}
      <CardContent className={cardContentClass}>
        <TeamMembersContact entity={entity} />
      </CardContent>
    </Card>
  );
};
