import React, { MouseEvent, useCallback, useState } from 'react';

import {
  TechDocsAddonLocations as locations,
  useTechDocsAddons,
  useTechDocsReaderPage,
} from '@backstage/plugin-techdocs-react';
import Button from '@mui/material/Button';
import { ToolbarProps } from '@mui/material/Toolbar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import SettingsIcon from '@mui/icons-material/Settings';

const useStyles = makeStyles()(theme => ({
  root: {
    gridArea: 'pageSubheader',
    flexDirection: 'column',
    minHeight: 'auto',
    padding: theme.spacing(3, 3, 0),
    '@media print': {
      display: 'none',
    },
  },
}));

/**
 * Renders the reader page subheader.
 * Please use the Tech Docs add-ons to customize it
 * @public
 */
export const TechDocsPageSubHeader = (props: {
  toolbarProps?: ToolbarProps;
  parentPath?: string;
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const {
    entityMetadata: { value: entityMetadata, loading: entityMetadataLoading },
  } = useTechDocsReaderPage();

  const addons = useTechDocsAddons();

  const subheaderAddons = addons.renderComponentsByLocation(
    locations.Subheader,
  );

  const settingsAddons = addons.renderComponentsByLocation(locations.Settings);

  if (!subheaderAddons && !settingsAddons) return null;

  // No entity metadata = 404. Don't render subheader on 404.
  if (entityMetadataLoading === false && !entityMetadata) return null;

  return (
    <Toolbar classes={classes} {...props.toolbarProps}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          onClick={() => {
            if (props.parentPath) {
              navigate(props.parentPath);
            } else {
              navigate(-1);
            }
          }}
        >
          <ArrowBack />
        </Button>
        <div>
          {subheaderAddons}
          {settingsAddons ? (
            <>
              <Tooltip title="Settings">
                <IconButton
                  aria-controls="tech-docs-reader-page-settings"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="tech-docs-reader-page-settings"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted
              >
                <div>{settingsAddons}</div>
              </Menu>
            </>
          ) : null}
        </div>
      </Grid>
    </Toolbar>
  );
};
