import { Entity } from '@backstage/catalog-model';
import { TableColumn } from '@backstage/core-components';
import { EntityTable } from '@backstage/plugin-catalog-react';
import { ContentEntity } from '@mb.io/catalog-model';

export const contentEntityHelpLink: string =
  'https://backstage.i.mercedes-benz.com/docs/default/component/backstage-user-doc/catalog/catalog-content/';

export const asContentEntities = (entities: Entity[]): ContentEntity[] =>
  entities as ContentEntity[];

export const contentEntityColumns: TableColumn<ContentEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'content' }),
  EntityTable.columns.createOwnerColumn(),
  EntityTable.columns.createSpecTypeColumn(),
  EntityTable.columns.createSpecLifecycleColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
];
