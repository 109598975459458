import {
  ACADEMY_TYPES,
  AcademyEntity,
  COURSE,
  PAGE,
  PRESENTATION,
  SNIPPET,
  TUTORIAL,
} from '../index';
import {
  EntityValidation,
  isRequiredOnTypes,
  isValidUrl,
  typeValidationMessage,
} from '../../../common';

export const ACADEMY_VALIDATIONS_SPEC: EntityValidation<AcademyEntity>[] = [
  {
    field: 'spec.type',
    message: entity =>
      `Type '${
        entity.spec.type
      }' is not supported for Academy kind ${typeValidationMessage(entity)}.`,
    validate: entity => ACADEMY_TYPES.includes(entity.spec.type),
  },
  {
    field: 'spec.link',
    message: entity =>
      `Attribute link is required for Academy type '${
        entity.spec.type
      }' ${typeValidationMessage(entity)}.`,
    validate: entity => isRequiredOnTypes(entity, 'link', [COURSE, PAGE]),
  },
  {
    field: 'spec.link.url',
    message: entity =>
      `Attribute url should be a valid url' ${typeValidationMessage(entity)}.`,
    validate: entity => {
      const url = entity.spec.link?.url;
      return !url || isValidUrl(url);
    },
  },
  {
    field: 'spec.link.previewUrl',
    message: entity =>
      `Attribute previewUrl should be a valid url' ${typeValidationMessage(entity)}.`,
    validate: entity => {
      const url = entity.spec.link?.previewUrl;
      return !url || isValidUrl(url);
    },
  },
  {
    field: 'spec.presentation',
    message: entity =>
      `Attribute presentation is required for Academy type '${
        entity.spec.type
      }' ${typeValidationMessage(entity)}.`,
    validate: entity =>
      isRequiredOnTypes(entity, 'presentation', [PRESENTATION]),
  },
  {
    field: 'spec.presentation',
    message: entity =>
      `Type '${entity.spec.type}' must have at least of of 'spec.presentation.videoUrl' or 'spec.presentation.slideUrl'.`,
    validate: entity =>
      entity.spec.type === 'presentation'
        ? entity.spec.presentation?.videoUrl !== undefined ||
          entity.spec.presentation?.slideUrl !== undefined
        : true,
  },
  {
    field: 'spec.presentation.videoUrl',
    message: entity =>
      `Attribute videoUrl should be a valid url' ${typeValidationMessage(entity)}.`,
    validate: entity => {
      const url = entity.spec.presentation?.videoUrl;
      return !url || isValidUrl(url);
    },
  },
  {
    field: 'spec.presentation.slideUrl',
    message: entity =>
      `Attribute slideUrl should be a valid url' ${typeValidationMessage(entity)}.`,
    validate: entity => {
      const url = entity.spec.presentation?.slideUrl;
      return !url || isValidUrl(url);
    },
  },
  {
    field: 'spec.snippet',
    message: entity =>
      `Attribute snippet is required for Academy type '${
        entity.spec.type
      }' ${typeValidationMessage(entity)}.`,
    validate: entity => isRequiredOnTypes(entity, 'snippet', [SNIPPET]),
  },
  {
    field: 'spec.codelab',
    message: entity =>
      `Attribute codelab is required for Academy type '${
        entity.spec.type
      }' ${typeValidationMessage(entity)}.`,
    validate: entity => isRequiredOnTypes(entity, 'codelab', [TUTORIAL]),
  },
];
