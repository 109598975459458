import { Repository } from '../types';
import { useDependabotAlertGroups } from './useDependabotAlertGroups';

export const useDependabotSeverityCount = (repository: Repository) => {
  const { criticalIssues, highIssues, mediumIssues, lowIssues } =
    useDependabotAlertGroups(repository);

  return {
    criticalCount: criticalIssues.length,
    highCount: highIssues.length,
    mediumCount: mediumIssues.length,
    lowCount: lowIssues.length,
  };
};
