import { DependabotContentPage } from '@mb.io/plugin-github-dependabot';
import { BackstageGuard, GitHubGuard } from '@mercedes-benz/permission';
import { ownershipPermission } from '@mercedes-benz/permission-common';
import React from 'react';
import { GithubInsightsContentPage } from '../../overlays/GithubInsightsContentPage';
import { GithubPullRequestsContentPage } from '../../overlays/GithubPullRequestsContentPage';

export const ProtectedGithubPullRequestsContentPage = () => (
  <GitHubGuard feature="Pull Requests">
    <BackstageGuard permission={ownershipPermission}>
      <GithubPullRequestsContentPage />
    </BackstageGuard>
  </GitHubGuard>
);

export const ProtectedGithubInsightsContentPage = () => (
  <GitHubGuard feature="Code Insights">
    <BackstageGuard permission={ownershipPermission}>
      <GithubInsightsContentPage />
    </BackstageGuard>
  </GitHubGuard>
);

export const ProtectedDependabotContentPage = () => (
  <GitHubGuard feature="Dependabot">
    <BackstageGuard permission={ownershipPermission}>
      <DependabotContentPage />
    </BackstageGuard>
  </GitHubGuard>
);
