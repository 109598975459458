import { Entity } from '@backstage/catalog-model';
import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { SECHUB_ANNOTATION } from '@mercedes-benz/sechub-common';
import { SecHubApiClient, sechubApiRef } from './api';

import { rootRouteRef } from './routes';

export const isSechubAvailable = (entity: Entity) =>
  Boolean(entity?.metadata.annotations?.[SECHUB_ANNOTATION]);

export const sechubPlugin = createPlugin({
  id: 'sechub',
  apis: [
    createApiFactory({
      api: sechubApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new SecHubApiClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const SechubPage = sechubPlugin.provide(
  createRoutableExtension({
    name: 'SechubPage',
    component: () =>
      import('./components/SechubContentPage').then(m => m.SechubContentPage),
    mountPoint: rootRouteRef,
  }),
);
