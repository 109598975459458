import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { SECCOMP_PLUGIN_ID } from '@mercedes-benz/seccomp-dashboard-common';
import { SecurityComplianceApiClient, securityComplianceApiRef } from './api';

import { rootRouteRef } from './routes';

export const secCompPlugin = createPlugin({
  id: SECCOMP_PLUGIN_ID,
  apis: [
    createApiFactory({
      api: securityComplianceApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new SecurityComplianceApiClient(fetchApi, discoveryApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const SecCompPage = secCompPlugin.provide(
  createRoutableExtension({
    name: 'SecCompPage',
    component: () =>
      import('./components/SecCompDashboard').then(m => m.SecCompDashboard),
    mountPoint: rootRouteRef,
  }),
);
