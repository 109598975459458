import { Entity } from '@backstage/catalog-model';
import { useGitHubConfig } from './useGitHubConfig';

export const useGithubUrl = (entity: Entity) => {
  const targetProviderConfig = useGitHubConfig(entity);
  const baseUrl =
    targetProviderConfig?.getOptionalString('apiBaseUrl') ||
    'https://api.github.com';
  const hostname =
    targetProviderConfig?.getOptionalString('host') || 'github.com';
  const graphqlBaseUrl =
    targetProviderConfig?.getOptionalString('graphqlBaseUrl') ||
    'https://api.github.com';
  return {
    hostname,
    baseUrl,
    graphqlBaseUrl,
  };
};
