import Grid from '@mui/material/Grid';
import { EntityLayout, EntitySwitch } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  INFRA_ACCOUNT,
  INFRA_CACHE,
  INFRA_DOCUMENTDB,
  INFRA_DYNAMODB_GLOBAL_TABLE,
  INFRA_DYNAMODB_STREAM,
  INFRA_DYNAMODB_TABLE,
  INFRA_EFS,
  INFRA_EKS,
  INFRA_GATEWAY,
  INFRA_LAMBDA,
  INFRA_MEMORYDB,
  INFRA_MQ,
  INFRA_RDS_CLUSTER,
  INFRA_RDS_INSTANCE,
  INFRA_S3,
  INFRA_SEARCH,
  INFRA_SNS,
  INFRA_SQS,
} from '@mb.io/catalog-model';
import { isType, isTypes, not } from '@mb.io/core-components';
import {
  AccountInfrastructureCard,
  DocumentDBContentPage,
  DynamoDBStreamContentPage,
  DynamoDBTableContentPage,
  EFSContentPage,
  EKSContentPage,
  ElasticacheContentPage,
  GatewayContentPage,
  InfraOverviewCard,
  LambdaContentPage,
  MQBrokerContentPage,
  MemoryDBContentPage,
  OpenSearchContentPage,
  RDSContentPage,
  SNSTopicContentPage,
  SQSQueueContentPage,
} from '@mercedes-benz/infrastructure';
import React from 'react';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';
import { OverviewContent } from '../generic/OverviewContent';
import {
  EntityEndOfLifeCard,
  isEndOfLifeAvailable,
} from '@mercedes-benz/endoflife';

export const InfrastructurePage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <OverviewContent hideEmptyLinks>
        <Grid item xs={12} className="grid-item">
          <InfraOverviewCard variant="gridItem" />
        </Grid>
        <EntitySwitch>
          <EntitySwitch.Case if={not(isType(INFRA_ACCOUNT))}>
            <Grid item xs={12} className="grid-item">
              <EntityCatalogGraphCard
                variant="gridItem"
                height={400}
                maxDepth={1}
              />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case if={isEndOfLifeAvailable}>
            <Grid item xs={12} className="grid-item">
              <EntityEndOfLifeCard />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
      </OverviewContent>
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isType(INFRA_ACCOUNT)}
      path="/infra"
      title="Infrastructure"
    >
      <Grid item xs={12} className="grid-item">
        <AccountInfrastructureCard variant="gridItem" />
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route
      if={not(isTypes([INFRA_DYNAMODB_GLOBAL_TABLE, INFRA_S3, INFRA_ACCOUNT]))}
      path="/details"
      title="Details"
    >
      <EntitySwitch>
        <EntitySwitch.Case if={isType(INFRA_SEARCH)}>
          <OpenSearchContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_CACHE)}>
          <ElasticacheContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_DOCUMENTDB)}>
          <DocumentDBContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_EFS)}>
          <EFSContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_EKS)}>
          <EKSContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_GATEWAY)}>
          <GatewayContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_DYNAMODB_TABLE)}>
          <DynamoDBTableContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_DYNAMODB_STREAM)}>
          <DynamoDBStreamContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_LAMBDA)}>
          <LambdaContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_MEMORYDB)}>
          <MemoryDBContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_MQ)}>
          <MQBrokerContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case
          if={isTypes([INFRA_RDS_INSTANCE, INFRA_RDS_CLUSTER])}
        >
          <RDSContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_SNS)}>
          <SNSTopicContentPage />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isType(INFRA_SQS)}>
          <SQSQueueContentPage />
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
