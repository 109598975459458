import Grid from '@mui/material/Grid';
import { MissingAnnotationEmptyState } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  ContentPageLayout,
  GITHUB_REPO_ANNOTATION,
} from '@mb.io/core-components';
import {
  ComplianceCard,
  ContributorsCard,
  LanguagesCard,
  ReadMeCard,
  ReleasesCard,
  isGithubInsightsAvailable,
} from '@roadiehq/backstage-plugin-github-insights';
import React from 'react';
import './GithubInsightsContentPage.css';

export const GithubInsightsContentPage = () => {
  const { entity } = useEntity();

  return isGithubInsightsAvailable(entity) ? (
    <ContentPageLayout
      title="GitHub Insights"
      themeId="tool"
      supportTitle="Plugin to show GitHub Insights"
    >
      <Grid container spacing={3} alignItems="stretch">
        <Grid item className="grid-item" xs={12} md={6}>
          <ComplianceCard />
        </Grid>
        <Grid item className="grid-item" xs={12} md={6}>
          <LanguagesCard />
        </Grid>
        <Grid item className="grid-item" xs={12} md={6}>
          <ContributorsCard />
        </Grid>
        <Grid item className="grid-item release-card" xs={12} md={6}>
          <ReleasesCard />
        </Grid>
        <Grid item className="grid-item" xs={12}>
          <ReadMeCard />
        </Grid>
      </Grid>
    </ContentPageLayout>
  ) : (
    <MissingAnnotationEmptyState annotation={GITHUB_REPO_ANNOTATION} />
  );
};
