import { SidebarDivider } from '@backstage/core-components';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  sidebar: {
    background: '#606060',
  },
});

export const SidebarSubmenuDivider = () => {
  const { classes } = useStyles();
  return <SidebarDivider className={classes.sidebar} />;
};
