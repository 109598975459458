import { useUserFeatureValue } from '@mb.io/plugin-platform-feature-flag';
import React, {
  ComponentPropsWithoutRef,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ shouldScroll: boolean }>()(
  (_theme, { shouldScroll }) => ({
    toggleScroll: {
      // Enable wrapping
      '& [class*=MuiTabs-flexContainer]': {
        flexWrap: shouldScroll ? 'nowrap' : 'wrap',
      },
      // Remove default underlining since it doesn't work with multiple lines of tabs
      '& [class*=MuiTabs-indicator]': {
        display: shouldScroll ? 'inline-block' : 'none',
      },
      // Underline selected tab
      '& [class*=BackstageHeaderTabs][class*=selected]': {
        borderBottomStyle: 'solid',
        borderBottomWidth: 'initial',
        borderBottomColor: '#0078d6',
      },
    },
  }),
);

type Props = {
  toggleName: string;
  minWidth?: number;
  children?: ReactNode;
} & ComponentPropsWithoutRef<'div'>;

export function ToggleableTabScroll({
  toggleName,
  minWidth = 980,
  children,
  ...rest
}: Props) {
  const [innerWidth, setInnerWidth] = useState(0);
  const shouldScroll = useUserFeatureValue({ feature: 'tabscroll' });

  // capture current window-size and listen for window-resizes
  useEffect(() => {
    setInnerWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setInnerWidth(window.innerWidth);
    });
  }, []);

  const { classes } = useStyles({
    shouldScroll: shouldScroll || innerWidth < minWidth,
  });

  return (
    <div className={classes.toggleScroll} {...rest}>
      {children}
    </div>
  );
}
