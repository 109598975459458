import {
  CompoundEntityRef,
  DEFAULT_NAMESPACE,
  Entity,
  getCompoundEntityRef,
  GroupEntity,
  RELATION_CHILD_OF,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { ContentEntity, Kind } from '@mb.io/catalog-model';
import useAsync from 'react-use/esm/useAsync';
import { CatalogApi } from '@backstage/catalog-client';

/**
 * Resolves the support entity for a given owner (team or org-team/project) considering team hierarchy.
 *
 * @param catalogApi - The Catalog API instance.
 * @param owner - The owner (Group with type 'team' or 'org-team') entity.
 * @returns A promise resolving to the support entity or undefined.
 */
async function resolveGroupSupport(
  catalogApi: CatalogApi,
  owner?: GroupEntity,
): Promise<ContentEntity | undefined> {
  if (!owner) return undefined;
  // If assigned to a team, it should resolve to parent org-team if it has any parent or return itself
  if (owner.spec.type === 'team') {
    // Try to resolve team specific support info
    const teamSupport = await getSupport(
      catalogApi,
      getCompoundEntityRef(owner),
    );
    if (teamSupport) {
      // If the specific team has a support content, return it
      return teamSupport;
    }
    // Otherwise try to find support info from parent teams or org-team, recursively
    const parents = getEntityRelations(owner, RELATION_CHILD_OF);
    if (parents.length > 0) {
      const parent = await catalogApi.getEntityByRef(parents[0]);
      return await resolveGroupSupport(catalogApi, parent as GroupEntity);
    }
  }
  return await getSupport(catalogApi, getCompoundEntityRef(owner));
}

/**
 * Gets the support entity for a given owner reference.
 *
 * @param catalogApi - The Catalog API instance.
 * @param ownerRef - The owner reference.
 * @returns A promise resolving to the support entity.
 */
async function getSupport(
  catalogApi: CatalogApi,
  ownerRef: CompoundEntityRef,
): Promise<ContentEntity | undefined> {
  const teamSupportResource: CompoundEntityRef = {
    kind: Kind.CONTENT,
    namespace: DEFAULT_NAMESPACE,
    name: `${ownerRef.name}-support`,
  };

  return (await catalogApi.getEntityByRef(
    teamSupportResource,
  )) as ContentEntity;
}

/**
 * React hook to retrieve a content entity with owner support information if it exists.
 *
 * @param ownerRef - Entity reference of the resource owner.
 * @returns An async function returning a promise with the support entity.
 * @example
 * const { loading, error, value: supportEntity } = useSupportEntity(ownerRef);
 */
export const useSupportEntity = (ownerRef: CompoundEntityRef) => {
  const catalogApi = useApi(catalogApiRef);

  return useAsync(async () => {
    // Owner can be a User or a UserGroup
    const owner = (await catalogApi.getEntityByRef(ownerRef)) as Entity;

    if (owner.kind === 'User') {
      // User ownership doesn't translate to parent ownership
      return await getSupport(catalogApi, getCompoundEntityRef(owner));
    } else if (owner.kind === 'Group') {
      // Try to get support entity for specific owner, being the tenant/org or a GitHub sub-team
      return await resolveGroupSupport(catalogApi, owner as GroupEntity);
    }
    // Just in case the owner is wrongly set (not a user or group)
    return undefined;
  }, [catalogApi]);
};
