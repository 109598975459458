import { InfoCard, InfoCardVariants } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  ImportFlows,
  ImportState,
  catalogImportApiRef,
} from '@backstage/plugin-catalog-import';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import Stepper from '@mui/material/Stepper';
import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAnalyzeState } from '../hooks';
import {
  StepConfiguration,
  StepperProvider,
  defaultGenerateStepper,
  defaultStepper,
} from './defaults';

const useStyles = makeStyles()(() => ({
  stepperRoot: {
    padding: 0,
  },
}));

/**
 * Props for {@link AnalyzeStepper}.
 *
 * @public
 */
export interface AnalyzeStepperProps {
  initialUrl?: string;
  generateStepper?: (
    flow: ImportFlows,
    defaults: StepperProvider,
  ) => StepperProvider;
  variant?: InfoCardVariants;
}

/**
 * The stepper that holds the different Analyze stages.
 *
 * @public
 */
export const AnalyzeStepper = (props: AnalyzeStepperProps) => {
  const {
    initialUrl,
    generateStepper = defaultGenerateStepper,
    variant,
  } = props;

  const catalogImportApi = useApi(catalogImportApiRef);
  const { classes } = useStyles();
  const state = useAnalyzeState({ initialUrl });

  const states = useMemo<StepperProvider>(
    () => generateStepper(state.activeFlow, defaultStepper),
    [generateStepper, state.activeFlow],
  );

  const render = (step: StepConfiguration) => {
    return (
      <Step>
        {step.stepLabel}
        <StepContent>{step.content}</StepContent>
      </Step>
    );
  };

  return (
    <InfoCard variant={variant}>
      <Stepper
        classes={{ root: classes.stepperRoot }}
        activeStep={state.activeStepNumber}
        orientation="vertical"
      >
        {render(
          states.analyze(
            state as Extract<ImportState, { activeState: 'analyze' }>,
            { apis: { catalogImportApi } },
          ),
        )}
        {render(
          states.prepare(
            state as Extract<ImportState, { activeState: 'prepare' }>,
            { apis: { catalogImportApi } },
          ),
        )}
        {render(
          states.review(
            state as Extract<ImportState, { activeState: 'review' }>,
            { apis: { catalogImportApi } },
          ),
        )}
      </Stepper>
    </InfoCard>
  );
};
