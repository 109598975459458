import Grid from '@mui/material/Grid';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import { EntityLayout } from '@backstage/plugin-catalog';
import {
  EntityFeedbackResponseContent,
  EntityLikeDislikeRatingsCard,
} from '@backstage-community/plugin-entity-feedback';
import { HarborContentPage } from '@mb.io/harbor';
import { SechubPage } from '@mercedes-benz/backstage-plugin-sechub';
import { BLACKDUCK_BACKEND_BASE_URL } from '@mercedes-benz/blackduck-common';
import { EntityBlackDuckContent } from '@mercedes-benz/plugin-blackduck';
import { EntitySonarqubeContent } from '@mercedes-benz/plugin-sonarqube-wrapper';
import React from 'react';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';
import { CICDContent } from '../generic/CICDContent';
import { dependenciesContent } from '../generic/DependenciesContent';
import { ComponentOverviewContent } from '../generic/OverviewContent';
import {
  ProtectedDependabotContentPage,
  ProtectedGithubInsightsContentPage,
  ProtectedGithubPullRequestsContentPage,
} from '../generic/ProtectedContent';
import { TechdocsContent } from '../generic/TechdocsContent';

export const ServicePage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <>
        {ComponentOverviewContent}
        <Grid item xs={12} md={6}>
          <EntityLikeDislikeRatingsCard />
        </Grid>
      </>
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {CICDContent}
    </EntityLayout.Route>

    <EntityLayout.Route path={BLACKDUCK_BACKEND_BASE_URL} title="BlackDuck">
      <EntityBlackDuckContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/sonarqube" title="SonarQube">
      <EntitySonarqubeContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/harbor" title="Harbor">
      <HarborContentPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/sechub" title="Sechub">
      <SechubPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      {dependenciesContent({
        canConsumeApis: true,
        dependsOnContent: false,
        dependsOnSeamless: false,
      })}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12} xl={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={12} xl={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {TechdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <ProtectedGithubPullRequestsContentPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/code-insights" title="Code Insights">
      <ProtectedGithubInsightsContentPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependabot" title="Dependabot">
      <ProtectedDependabotContentPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
