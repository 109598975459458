import {
  createExternalRouteRef,
  createRouteRef,
} from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'home',
});

export const catalogIndexRouteRef = createExternalRouteRef({
  id: 'catalog-index',
});

export const catalogEntityRouteRef = createExternalRouteRef({
  id: 'catalog-entity',
  params: ['namespace', 'kind', 'name'],
});
