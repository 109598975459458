import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const landingPagePlugin = createPlugin({
  id: 'landing-page',
  routes: {
    root: rootRouteRef,
  },
});

export const LandingPagePage = landingPagePlugin.provide(
  createRoutableExtension({
    name: 'LandingPagePage',
    component: () => import('./Home').then(m => m.HomeComponent),
    mountPoint: rootRouteRef,
  }),
);
