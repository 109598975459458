import React, { PropsWithChildren, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const useStyles = (props: ContentHeaderProps) =>
  makeStyles()(theme => ({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      textAlign: props.textAlign,
    },
    leftItemsBox: {
      flex: '1 1 auto',
      minWidth: 0,
      overflow: 'visible',
    },
    rightItemsBox: {
      flex: '0 1 auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
      minWidth: 0,
      overflow: 'visible',
    },
    description: {},
    title: {
      display: 'inline-flex',
      marginBottom: 0,
    },
  }));

/** @public */
export type ContentHeaderClassKey =
  | 'container'
  | 'leftItemsBox'
  | 'rightItemsBox'
  | 'description'
  | 'title';

type ContentHeaderTitleProps = {
  title?: string;
  className?: string;
  allowBack?: boolean | string;
  backFunction?: () => void;
};

const ContentHeaderTitle = ({
  title = 'Unknown page',
  className,
  allowBack,
  backFunction,
}: ContentHeaderTitleProps) => {
  const navigate = useNavigate();
  return allowBack ? (
    <Box>
      <Button
        style={{ minWidth: 0 }}
        onClick={() => {
          if (allowBack && backFunction) {
            backFunction();
          } else if (allowBack === true) {
            navigate(-1);
          } else {
            navigate(allowBack);
          }
        }}
      >
        <ArrowBack />
      </Button>
      <Typography
        variant="h4"
        component="h2"
        className={className}
        data-testid="header-title"
      >
        {title}
      </Typography>
    </Box>
  ) : (
    <Typography
      variant="h4"
      component="h2"
      className={className}
      data-testid="header-title"
    >
      {title}
    </Typography>
  );
};

type ContentHeaderProps = {
  title?: ContentHeaderTitleProps['title'];
  titleComponent?: ReactNode;
  description?: string;
  textAlign?: 'left' | 'right' | 'center';
  allowBack?: boolean | string;
  backFunction?: () => void;
};

/**
 *  A header at the top inside a {@link Content}.
 *
 * @public
 *
 */

export function ContentHeader(props: PropsWithChildren<ContentHeaderProps>) {
  const {
    description,
    title,
    titleComponent: TitleComponent = undefined,
    children,
    textAlign = 'left',
    allowBack,
    backFunction,
  } = props;
  const { classes } = useStyles({ textAlign })();

  const renderedTitle = TitleComponent ? (
    TitleComponent
  ) : (
    <ContentHeaderTitle
      title={title}
      className={classes.title}
      allowBack={allowBack}
      backFunction={backFunction}
    />
  );

  return (
    <>
      <Helmet title={title} />
      <Box className={classes.container}>
        <Box className={classes.leftItemsBox}>
          {renderedTitle}
          {description && (
            <Typography className={classes.description} variant="body2">
              {description}
            </Typography>
          )}
        </Box>
        <Box className={classes.rightItemsBox}>{children}</Box>
      </Box>
    </>
  );
}
