import { ExternalSystem, SystemRoleAlphaEntity } from '@mb.io/catalog-model';

export const DEFAULT_PROVIDER = 'NEBULA';

/**
 * @public
 */
export enum ActionType {
  ROLE = 'ROLE',
  GLOBAL_ROLE = 'GLOBAL_ROLE',
  PERMISSION = 'PERMISSION',
}

export type RoleCriteria =
  | string[]
  | {
      allOf?: string[];
      oneOf?: string[];
      noneOf?: string[];
    };

/**
 * @public
 */
export type RoleQueryOptions = {
  tenant?: string; // Optional, for tenant-specific queries
  role?: RoleCriteria;
  globalRoles?: string[];
  checkRoleEverywhere?: boolean;
};

export type GlobalRoleQueryOptions = {
  globalRoles?: string[];
};

/**
 * @public
 */
export type PermissionQueryOptions = {
  tenant: string;
  bookingSlug: string;
  permissionSlug?: string;
};

/**
 * @public
 */
export type PermissionActionPayload = {
  externalSystem?: ExternalSystem; // if not given, NEBULA
  provider?: string; // if not given TDMS
  actionType: ActionType.PERMISSION;
  details: PermissionQueryOptions;
};

/**
 * @public
 */
export type RoleActionPayload = {
  externalSystem?: ExternalSystem; // if not given, NEBULA
  provider?: string; // if not given TDMS
  actionType: ActionType.ROLE;
  details: RoleQueryOptions;
};

/**
 * @public
 */
export type GlobalRoleActionPayload = {
  externalSystem?: ExternalSystem; // if not given, NEBULA
  provider?: string; // if not given TDMS
  actionType: ActionType.GLOBAL_ROLE;
  details: GlobalRoleQueryOptions;
};
/**
 * @public
 */
export type ActionPayload =
  | GlobalRoleActionPayload
  | RoleActionPayload
  | PermissionActionPayload;

/**
 * @public
 */
export type SystemRolePermissionRequest = ActionPayload & {
  userRef: string;
  token: string;
};

/**
 * @public
 */
export type SystemRolePermissions = {
  entities: SystemRoleAlphaEntity[];
  request: SystemRolePermissionRequest;
};
