import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { ContentEntity } from '@mb.io/catalog-model';
import useAsync from 'react-use/esm/useAsync';
import { ScaffolderCategory } from '../types';

export const useScaffolderCategories = () => {
  const catalogApi = useApi(catalogApiRef);

  const {
    value: categories,
    loading,
    error,
  } = useAsync(async () => {
    const entity = (await catalogApi.getEntityByRef({
      kind: 'Content',
      namespace: 'default',
      name: 'software-templates-categories',
    })) as ContentEntity;

    if (!entity) {
      throw new Error(
        `Entity for software-templates-categories was not found!`,
      );
    }

    const { data } = entity.spec;
    if (!data) {
      throw new Error(`software-templates-categories data is empty!`);
    }

    return entity
      ? (JSON.parse(entity.spec.data!) as ScaffolderCategory[])
      : [];
  }, [catalogApi]);

  return {
    loading,
    error,
    categories,
  };
};
