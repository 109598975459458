import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  BdProject,
  BdVersion,
  BLACKDUCK_PLUGIN_ID,
  BlackDuckClient,
  ProjectIdAndInstanceName,
  ProjectNameAndInstanceName,
} from '@mercedes-benz/blackduck-common';

export const blackDuckApiRef = createApiRef<BlackDuckClient>({
  id: 'plugin.blackduck.api',
});

export type BlackDuckRequest = {
  method: string;
  path: string;
  responseAsJson?: boolean;
};

export class BlackDuckApiClient implements BlackDuckClient {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }

  getProject({
    projectName,
    instanceName,
  }: ProjectNameAndInstanceName): Promise<BdProject> {
    const projectsPath = `/projects/${encodeURIComponent(projectName)}`;
    const path = instanceName
      ? `${projectsPath}?instanceName=${encodeURIComponent(instanceName || '')}`
      : projectsPath;
    return this.submitRequest({
      method: 'GET',
      path,
    });
  }

  getVersions({
    projectId,
    instanceName,
  }: ProjectIdAndInstanceName): Promise<BdVersion[]> {
    const versionsPath = `/projects/${encodeURIComponent(projectId)}/versions`;
    const path = instanceName
      ? `${versionsPath}?instanceName=${encodeURIComponent(instanceName || '')}`
      : versionsPath;
    return this.submitRequest({
      method: 'GET',
      path,
    });
  }

  private async submitRequest({
    path,
    method,
    responseAsJson = true,
  }: BlackDuckRequest): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      BLACKDUCK_PLUGIN_ID,
    )}${path}`;
    const headers: Record<string, string> = {};

    const response = await this.fetchApi.fetch(url, { method, headers });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return responseAsJson ? response.json() : response;
  }
}
