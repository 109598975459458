import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';

import {
  actionEnum,
  JenkinsApi,
  JenkinsUserRole,
} from '@mercedes-benz/sip-central-common';

export const jenkinsApiRef = createApiRef<JenkinsApi>({
  id: 'plugin.sip-central.jenkins.api',
});

export type JenkinsRequest = {
  method: string;
  path: string;
  body?: object;
  responseAsJson?: boolean;
};

export class JenkinsApiClient implements JenkinsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }

  getInstances(): any {
    return this.submitRequest({
      method: 'GET',
      path: `/jenkins/instances`,
    });
  }

  searchUserRoles(username: string): Promise<JenkinsUserRole[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/jenkins/users/${username}/search`,
    });
  }

  async addUserToProjectRoles(
    instance: string,
    username: string,
    projectRoles: string[],
  ): Promise<void> {
    const res = await this.submitRequest({
      method: 'POST',
      path: `/jenkins/project_roles/add_user`,
      body: { instance, projectRoles, username },
      responseAsJson: false,
    });

    this.submitRequest({
      method: 'POST',
      path: `/audit-logs`,
      body: {
        currentUser: undefined,
        action: actionEnum.ADD_PERMISSION,
        activities: [
          {
            performedOn: username,
            toolPermissions: {
              tool: 'Jenkins',
              permissions: [{ instance: instance, roles: projectRoles }],
            },
          },
        ],
        actionDate: new Date().toJSON(),
      },
    });
    return res;
  }

  getProjectRolesByInstance(instanceName: string): Promise<string[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/jenkins/project_roles?instance=${instanceName}`,
    });
  }

  async removeUserFromProjectRoles(
    instance: string,
    username: string,
    projectRoles: string[],
  ): Promise<void> {
    const res = await this.submitRequest({
      method: 'POST',
      body: { instance, username, projectRoles },
      path: `/jenkins/project_roles/remove_user`,
      responseAsJson: false,
    });
    this.submitRequest({
      method: 'POST',
      path: `/audit-logs`,
      body: {
        currentUser: undefined,
        action: actionEnum.REMOVE_PERMISSION,
        activities: [
          {
            performedOn: username,
            toolPermissions: {
              tool: 'Jenkins',
              permissions: [{ instance: instance, roles: projectRoles }],
            },
          },
        ],
        actionDate: new Date().toJSON(),
      },
    });
    return res;
  }

  private async submitRequest({
    path,
    method,
    body,
    responseAsJson = true,
  }: JenkinsRequest): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('sip-central')}${path}`;

    const headers: Record<string, string> = {
      'content-type': 'application/json',
    };
    const response = await this.fetchApi.fetch(url, {
      method,
      headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      return Promise.reject('Jenkins');
    }
    return responseAsJson ? response.json() : response;
  }
}
