import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { keyframes } from 'tss-react';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { grey } from '@mercedes-benz/mui5-theme';

const useStyles = makeStyles()(theme => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    opacity: 1,
    bottom: 0,
    width: '100%',
    background: theme.palette.mode === 'light' ? 'white' : grey['20'],
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    animation: `${keyframes`
      100% {
        opacity: 1;
        bottom: 0;
      }
      0% {
        opacity: 0;
        bottom: -100px;
      }
      40% {
        opacity: 0.5;
        bottom: -50px;
      }
    `} 1s ease-in`,
  },
  backButton: {
    marginRight: '8px',
    '&:hover': {
      backgroundColor: '#f5f5f5', // Or any other color for hover state
    },
  },
}));

export const AliceErrorPage = () => {
  const { classes } = useStyles();
  return (
    <Container className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingY={4}
      >
        <Typography variant="h2" gutterBottom>
          Access Restricted
        </Typography>
        <Typography variant="body2" gutterBottom>
          It seems you don’t have the necessary permissions to access this
          resource. Please check with your Project Owner or IT department for
          access rights.
        </Typography>
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SupportAgentIcon />}
            href="https://jira.mercedes-benz.io/plugins/servlet/desk/portal/12?_=1"
            target="_blank"
          >
            Contact Support
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
