import { isValidArtifactName } from '@mb.io/catalog-model';
import { FieldValidation } from '@rjsf/utils';

export const validateNpmArtifactName = (
  value: string,
  validation: FieldValidation,
) => {
  if (!isValidArtifactName(value)) {
    validation.addError(
      `Invalid NPM artifact name ${value}. Allowed characters: a-z and number 0-9 and -. Min 4 to 255 chars`,
    );
  }
};
