import { FieldValidation } from '@rjsf/utils';

/*
 This is a validation function that will run when the form is submitted.
  You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\
*/
const ALPHA_NUMERIC_REGEX = /^[a-z0-9]+$/i;

export const validateAlphaNumericCharacters = (
  value: string,
  validation: FieldValidation,
) => {
  if (!new RegExp(ALPHA_NUMERIC_REGEX).test(value)) {
    validation.addError(
      'must start and end with an alphanumeric character, space and all other characters are not allowed!',
    );
  }
};
