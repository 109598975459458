import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'academy',
});

export const tutorialRouteRef = createSubRouteRef({
  id: 'academy:tutorial',
  parent: rootRouteRef,
  path: '/:namespaceId/tutorial/:tutorialName',
});

export const snippetRouteRef = createSubRouteRef({
  id: 'academy:snippet',
  parent: rootRouteRef,
  path: '/:namespaceId/snippet/:snippetName',
});
