import { makeStyles } from 'tss-react/mui';

const useCardStyles = makeStyles()({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
});

const useCardContentStyles = makeStyles()({
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
});

export const useCardClass = (variant: string) => {
  const { classes } = useCardStyles();

  let cardClass = '';
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard;
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard;
  }
  return cardClass;
};

export const useCardContentClass = (variant: string) => {
  const { classes } = useCardContentStyles();

  let cardContentClass = '';
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent;
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent;
  }
  return cardContentClass;
};

export const useCardClasses = (variant: string) => {
  const cardClass = useCardClass(variant);
  const cardContentClass = useCardContentClass(variant);
  return {
    cardClass,
    cardContentClass,
  };
};
