import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { UserSettingsClient } from '@mercedes-benz/user-settings-client';
import {
  userSettingsApiRef,
  userSettingsRouteRef,
} from '@mercedes-benz/user-settings-react';

export const settingsPlugin = createPlugin({
  id: 'user-settings',
  routes: {
    settingsPage: userSettingsRouteRef,
  },
  apis: [
    createApiFactory({
      api: userSettingsApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: deps => new UserSettingsClient(deps),
    }),
  ],
});

export const SettingsPage = settingsPlugin.provide(
  createRoutableExtension({
    name: 'SettingsPage',
    component: () =>
      import('./components/SettingsPage').then(m => m.SettingsPage),
    mountPoint: userSettingsRouteRef,
  }),
);

export const UserSettingsRolesCard = settingsPlugin.provide(
  createComponentExtension({
    name: 'UserSettingsRolesCard',
    component: {
      lazy: () => import('./components/').then(m => m.UserSettingsRolesCard),
    },
  }),
);

export const SystemRolesEntityCard = settingsPlugin.provide(
  createComponentExtension({
    name: 'SystemRolesEntityCard',
    component: {
      lazy: () => import('./components/').then(m => m.SystemRolesEntityCard),
    },
  }),
);

export const SidebarSettings = settingsPlugin.provide(
  createComponentExtension({
    name: 'SidebarSettings',
    component: {
      lazy: () => import('./components/').then(m => m.SidebarSettings),
    },
  }),
);
