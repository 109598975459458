import { AcademyEntity, SeamlessEntity } from '../../models';

export const scopeRegex = '@[a-z\\d][\\w-.]*';
export const nameRegex = /^(?=^.{4,255}$)([a-z][a-z0-9-]*)$/;
export const prefixRegex = /^(?=^.{2,24}$)([a-z][a-z0-9]*)$/;
const scopedRegex = new RegExp(scopeRegex, 'i');

export const nameValidationMessage = (attribute: string): string =>
  `${attribute} must be a string that is sequences of [a-zA-Z0-9] separated by any of [-_.], at most 63 characters in total.`;

export const typeValidationMessage = (
  entity: SeamlessEntity | AcademyEntity,
): string => `, on entity '${entity.spec.type}/${entity.metadata.name}'`;

export const isNotEmpty = (value?: any): boolean => {
  if (!value) return false;
  if (typeof value === 'string') {
    return value.length > 0;
  }
  return true;
};

export const isRequiredOnTypes = (
  entity: SeamlessEntity | AcademyEntity,
  attribute: string,
  types: string[],
): boolean => {
  const required = types.includes(entity.spec.type);
  return !required || (required && isNotEmpty((entity.spec as any)[attribute]));
};

/**
 * validates a scope. A scope is valid if it starts with < @ > and contains a letter with lowercase. (also allowed . or -)
 * @param scope a scope to validate
 * @returns {true} if scope is valid
 * @returns {false} if scope is invalid
 */
export const isValidScope = (scope: string): boolean =>
  new RegExp(scopedRegex).test(scope) && scope.startsWith('@');

export const isValidPrefix = (prefix: string): boolean =>
  prefix === '' || !!prefix.match(prefixRegex);

export const isValidArtifactName = (artifactName: string): boolean =>
  !!artifactName.match(nameRegex);

export const isValidUrl = (url: string): boolean => {
  try {
    return new URL(url) !== undefined;
  } catch (_) {
    return false;
  }
};

export const isValidNpmArtifact = (npmArtifact: string): boolean => {
  const splitIndex = npmArtifact.indexOf('/');
  if (splitIndex < 0) {
    return false;
  }

  const scope = npmArtifact.substring(0, splitIndex).trim();
  const name = npmArtifact.substring(splitIndex + 1).trim();

  return isValidScope(scope) && isValidArtifactName(name);
};
