import '@backstage/cli/asset-types';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { TssCacheProvider } from 'tss-react';
import App from './App';

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const tssCache = createCache({
  key: 'tss',
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <CacheProvider value={muiCache}>
    <TssCacheProvider value={tssCache}>
      <App />
    </TssCacheProvider>
  </CacheProvider>,
);
