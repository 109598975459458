import { useEntity } from '@backstage/plugin-catalog-react';
import React, { ReactNode } from 'react';
import { isOwned } from '../../conditions';
import { useUserIdentityFromContext } from '../../hooks';

type Props = {
  children?: ReactNode;
};

export const WhenOwned = ({ children }: Props) => {
  const { entity } = useEntity();
  const { user } = useUserIdentityFromContext();

  return isOwned(user, entity) ? <div>{children}</div> : null;
};
