import { createApiRef } from '@backstage/core-plugin-api';
import {
  BookingsService,
  EntitiesService,
  PackagesService,
  ReportsService,
  ServicesService,
  TdmsClient,
  TenantsService,
  UsersService,
} from '@mb.io/tdms-common';

export const tdmsApiRef = createApiRef<TdmsClient>({
  id: 'plugin.tdms-next.api',
});

type TdmsServiceDependencies = {
  bookingsService: BookingsService;
  entitiesService: EntitiesService;
  packagesService: PackagesService;
  reportsService: ReportsService;
  servicesService: ServicesService;
  tenantsService: TenantsService;
  usersService: UsersService;
};

export class TDMSApiClient implements TdmsClient {
  constructor(
    private readonly bookingsService: BookingsService,
    private readonly entitiesService: EntitiesService,
    private readonly packagesService: PackagesService,
    private readonly reportsService: ReportsService,
    private readonly servicesService: ServicesService,
    private readonly tenantsService: TenantsService,
    private readonly usersService: UsersService,
  ) {}

  static create(dependencies: TdmsServiceDependencies) {
    const {
      bookingsService,
      entitiesService,
      packagesService,
      reportsService,
      servicesService,
      tenantsService,
      usersService,
    } = dependencies;
    return new TDMSApiClient(
      bookingsService,
      entitiesService,
      packagesService,
      reportsService,
      servicesService,
      tenantsService,
      usersService,
    );
  }
  bookings(): BookingsService {
    return this.bookingsService;
  }

  entities(): EntitiesService {
    return this.entitiesService;
  }

  packages(): PackagesService {
    return this.packagesService;
  }

  reports(): ReportsService {
    return this.reportsService;
  }

  services(): ServicesService {
    return this.servicesService;
  }

  tenants(): TenantsService {
    return this.tenantsService;
  }

  users(): UsersService {
    return this.usersService;
  }
}
