import Grid from '@mui/material/Grid';
import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntityTeamPullRequestsContent } from '@backstage-community/plugin-github-pull-requests-board';
import {
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { TenantOnboardingBanner } from '@mb.io/core-components';
import { BackstageGuard } from '@mercedes-benz/permission';
import { ownershipPermission } from '@mercedes-benz/permission-common';
import React from 'react';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';

export const GroupPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TenantOnboardingBanner />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <BackstageGuard permission={ownershipPermission}>
        <EntityTeamPullRequestsContent />
      </BackstageGuard>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard variant="fullHeight" height={800} maxDepth={1} />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
