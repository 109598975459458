import {
  DefaultCardHeader,
  SeverityGauge,
  useCardClasses,
} from '@mb.io/core-components';
import { Artifact, RepositoryId } from '@mb.io/harbor-common';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React from 'react';
import { EmptyScan } from '../EmptyScan';

type Props = {
  title: string;
  artifact: Artifact;
  repositoryId: RepositoryId;
};

export const VulnerabilityScoreCard = ({
  title,
  artifact,
  repositoryId,
}: Props) => {
  const { cardClass, cardContentClass } = useCardClasses('gridItem');

  return (
    <Card className={cardClass}>
      <DefaultCardHeader title={title} />
      <CardContent className={cardContentClass}>
        {artifact.scanOverview.scanned ? (
          <SeverityGauge severity={artifact.scanOverview.severity!!} />
        ) : (
          <EmptyScan
            repositoryId={repositoryId}
            artifactSha256={artifact.digest}
            title="Not Scanned"
            description="The latest docker image is not scanned. Please trigger a security scan."
          />
        )}
      </CardContent>
    </Card>
  );
};
