import Typography from '@mui/material/Typography';
import { CodeSnippet } from '@backstage/core-components';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const ENTITY_YAML = `metadata:
  name: example
  links:
    - url: https://dashboard.example.com
      title: My Dashboard
      icon: dashboard`;

/** @public */
export type EntityLinksEmptyStateClassKey = 'code';

const useStyles = makeStyles({ name: 'EnhancedEntityLinksEmptyState' })(
  theme => ({
    code: {
      borderRadius: 6,
      margin: `${theme.spacing(2)} 0px`,
      background: theme.palette.mode === 'dark' ? '#444' : '#fff',
    },
  }),
);

export function EntityLinksEmptyState() {
  const { classes } = useStyles();

  return (
    <>
      <Typography variant="body1">
        No links defined for this entity. You can add links to your entity YAML
        or globally through the team support content resource, as shown in the
        highlighted example below:
      </Typography>
      <div className={classes.code}>
        <CodeSnippet
          text={ENTITY_YAML}
          language="yaml"
          showLineNumbers
          highlightedNumbers={[3, 4, 5, 6]}
          customStyle={{ background: 'inherit', fontSize: '115%' }}
        />
      </div>
    </>
  );
}
