import Grid from '@mui/material/Grid';
import { EntityHasApisCard } from '@backstage/plugin-api-docs';
import {
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityLayout,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  EnhancedEntityLinksCard,
  TeamContactsCard,
  TenantOnboardingBanner,
} from '@mb.io/core-components';
import { SystemInfrastructureCard } from '@mercedes-benz/infrastructure';
import React from 'react';
import { EntityAboutCard } from '../../about-card';
import { HasContentsCard } from '../../content/HasContentsCard';
import { HasSeamlessCard } from '../../seamless/HasSeamlessCard';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';

export const SystemPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          <TenantOnboardingBanner />
        </Grid>
        <Grid item md={6} xs={12} className="grid-item">
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item className="grid-item" md={6} xs={12}>
          <Grid
            container
            alignItems="stretch"
            direction="column"
            wrap="nowrap"
            style={{ padding: 0 }}
          >
            <Grid item xs={12} style={{ padding: 0 }}>
              <TeamContactsCard />
            </Grid>
            <Grid
              item
              className="grid-item"
              xs={12}
              style={{ padding: 0, paddingTop: '1em' }}
            >
              <EnhancedEntityLinksCard />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <SystemInfrastructureCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <HasContentsCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <HasSeamlessCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard variant="gridItem" height={800} maxDepth={1} />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
