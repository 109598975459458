import Grid from '@mui/material/Grid';
import { EntityConsumedApisCard } from '@backstage/plugin-api-docs';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
} from '@backstage/plugin-catalog';
import React from 'react';
import { DependsOnContentsCard } from '../../content/DependsOnContentsCard';
import { DependsOnSeamlessCard } from '../../seamless/DependsOnSeamlessCard';

export const dependenciesContent = (args: {
  canConsumeApis: boolean;
  dependsOnContent: boolean;
  dependsOnSeamless: boolean;
}) => (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item md={12} xl={6}>
      <EntityDependsOnComponentsCard variant="gridItem" />
    </Grid>
    <Grid item md={12} xl={6}>
      <EntityDependsOnResourcesCard variant="gridItem" />
    </Grid>
    {args.dependsOnContent && (
      <Grid item md={12} xl={6}>
        <DependsOnContentsCard variant="gridItem" />
      </Grid>
    )}
    {args.dependsOnSeamless && (
      <Grid item md={12} xl={6}>
        <DependsOnSeamlessCard variant="gridItem" />
      </Grid>
    )}
    {args.canConsumeApis && (
      <Grid item md={12} xl={6}>
        <EntityConsumedApisCard variant="gridItem" />
      </Grid>
    )}
  </Grid>
);
