import {
  BackstageIdentityApi,
  ConfigApi,
  IdentityApi,
  OAuthApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
} from '@backstage/core-plugin-api';

export class AuthenticationManager {
  constructor(
    private readonly configApi: ConfigApi,
    private readonly identityApi: IdentityApi,
    private readonly oidcAuth: OAuthApi &
      OpenIdConnectApi &
      ProfileInfoApi &
      BackstageIdentityApi &
      SessionApi,
  ) {}

  isUnauthorized(info: RequestInfo | URL, response: Response) {
    // skip GitHub refresh which is handled internally inside the application itself to get a refresh token.
    // eslint-disable-next-line no-debugger

    const url = (info as unknown as Request).url ?? info.toString();
    const base = this.configApi.getString('app.baseUrl');

    if (!url.startsWith(base) && !url.includes('localhost')) {
      return false; // ignore external calls.
    }

    return (
      response.status === 401 &&
      !url.includes('auth/github/refresh') &&
      !url.includes('/jenkins') &&
      !url.includes('auth/oidc/refresh') // let keep the handler to handle refresh failures.
    );
  }

  async getBearerToken() {
    const identity = await this.identityApi.getCredentials();
    return `Bearer ${identity.token}`;
  }

  async signOut() {
    await this.identityApi.signOut();
    await this.oidcAuth.signOut();
  }
}
