import {
  RELATION_MEMBER_OF,
  stringifyEntityRef,
  UserEntity,
} from '@backstage/catalog-model';
import { BackstageUserIdentity, useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/esm/useAsync';
import { parseEntityRef } from '../utils';

/**
 * React hook to retrieve user owned entities based on the authenticated user.
 *
 * @param allowedKinds - used to limit scope when searching for owned entities. Example ['Component', 'API']. Default is ['Component', 'API', 'Seamless']
 * @param identity - Backstage User identity representing the logged-in user.
 *
 * @author manuel.santos@mercedes-benz.io
 * */
export const useOwnedEntities = (
  identity: BackstageUserIdentity,
  allowedKinds?: string[],
) => {
  const catalogApi = useApi(catalogApiRef);

  const {
    loading,
    error,
    value: ownedEntities,
  } = useAsync(async () => {
    const userRef = parseEntityRef(identity.userEntityRef);

    const user = (await catalogApi.getEntityByRef({
      kind: 'User',
      namespace: userRef.namespace ?? 'default',
      name: userRef.name,
    })) as UserEntity;

    if (user) {
      const groups = getEntityRelations(user, RELATION_MEMBER_OF).map(
        entityRef => stringifyEntityRef(entityRef),
      );

      const response = await catalogApi.getEntities({
        filter: {
          kind: allowedKinds ?? [
            'Component',
            'API',
            'Seamless',
            'Infrastructure',
          ],
          'relations.ownedBy': [...groups, `user:${user.metadata.name}`],
        },
        fields: ['kind', 'spec.type'],
      });

      return {
        user,
        items: response.items,
      };
    }
    throw new Error(`User ${userRef.name} not found.`);
  }, [catalogApi, identity]);

  return {
    owner: ownedEntities?.user,
    entities: ownedEntities?.items,
    loading,
    error,
  };
};
