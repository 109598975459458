import { ActionPayload, encodePayload } from '@mercedes-benz/permission-common';

export const systemRoleFetcher = (
  sourceFetch: typeof fetch,
  url: string,
  requestInit: RequestInit,
  ref?: ActionPayload,
) => {
  if (!ref) {
    return sourceFetch(url, requestInit);
  }

  const headers: Record<string, unknown> = (requestInit.headers as never) || {};
  headers['role-permission'] = encodePayload(ref);

  requestInit.headers = headers as never;

  return sourceFetch(url, requestInit);
};
