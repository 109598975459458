import { Entity, EntityPolicy } from '@backstage/catalog-model';
import { EntityValidator } from '../../common';
import { Kind } from '../../kind-type';
import { CONTENT_ENTITY_VALIDATIONS_SPEC } from './content-entity.validations';
import { ContentAlphaEntity } from './content.alpha.entity';

export class ContentEntityPolicy implements EntityPolicy {
  constructor(
    private readonly validator = new EntityValidator<ContentAlphaEntity>(
      CONTENT_ENTITY_VALIDATIONS_SPEC,
    ),
  ) {
    this.validator = validator;
  }

  async enforce(entity: Entity): Promise<Entity> {
    if (entity.kind === Kind.CONTENT) {
      this.validator.applyDefaults(entity as ContentAlphaEntity);
    }
    return entity;
  }
}
