import { CatalogTable } from '@backstage/plugin-catalog';
import {
  EntityListProvider,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { DefaultExplorerIndexPage } from '@mb.io/core-components';
import React from 'react';

export const SeamlessTable = () => {
  const { filters } = useEntityList();

  return (
    <DefaultExplorerIndexPage
      title="Seamless Explorer"
      subtitle="A place to find everything you need to create & integrate micro frontends."
      supportTitle="Explorer for seamless components"
      kind="Seamless"
      columns={[
        {
          ...CatalogTable.columns.createNameColumn({
            defaultKind: filters.kind?.value,
          }),
          width: 'auto',
        },
        { ...CatalogTable.columns.createSystemColumn(), width: 'auto' },
        { ...CatalogTable.columns.createOwnerColumn(), width: 'auto' },
        { ...CatalogTable.columns.createSpecTypeColumn(), width: 'auto' },
        {
          ...CatalogTable.columns.createMetadataDescriptionColumn(),
          width: 'auto',
        },
        { ...CatalogTable.columns.createTagsColumn(), width: 'auto' },
      ]}
    />
  );
};

export const SeamlessIndexPage = () => (
  <EntityListProvider>
    <SeamlessTable />
  </EntityListProvider>
);
