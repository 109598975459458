import { OAuth2 } from '@backstage/core-app-api';
import {
  alertApiRef,
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  errorApiRef,
  fetchApiRef,
  githubAuthApiRef,
  identityApiRef,
  OAuthApi,
  oauthRequestApiRef,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
  storageApiRef,
} from '@backstage/core-plugin-api';
import { ScmIntegrations } from '@backstage/integration';
import {
  ScmAuth,
  scmAuthApiRef,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { exploreApiRef } from '@backstage-community/plugin-explore';
import {
  DefaultShortcutsApi,
  shortcutsApiRef,
} from '@backstage-community/plugin-shortcuts';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { UserSettingsStorage } from '@backstage/plugin-user-settings';
import { TechRadarCustomClient } from './components/tech-radar/tech-radar-custom.client';
import { ToolsConfigApi } from './components/tools/tools-config-api';

// `ProfileInfoApi & BackstageIdentityApi & SessionApi` are required for sign-in
// Include `OAuthApi & OpenIdConnectApi` only if applicable
export const oidcAuthRef: ApiRef<
  OAuthApi &
    OpenIdConnectApi &
    ProfileInfoApi &
    BackstageIdentityApi &
    SessionApi
> = createApiRef({
  id: 'internal.auth.oidc',
});

export const apis: AnyApiFactory[] = [
  // see: https://github.com/backstage/backstage/blob/master/plugins/catalog-import/CHANGELOG.md#070
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      configApi: configApiRef,
      gheAuthApi: createApiRef<
        OAuthApi & ProfileInfoApi & BackstageIdentityApi & SessionApi
      >({
        id: 'core.auth.github',
      }),
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ githubAuthApi, gheAuthApi, configApi }) =>
      ScmAuth.merge(
        ScmAuth.forGithub(githubAuthApi),
        ScmAuth.forGithub(gheAuthApi, {
          host: ScmIntegrations.fromConfig(configApi)
            .github.list()
            .find(({ config }) => config.host.includes('mercedes-benz'))!.config
            .host,
        }),
      ),
  }),
  createApiFactory({
    api: storageApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      errorApi: errorApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef,
    },
    factory: deps => UserSettingsStorage.create(deps),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: techRadarApiRef,
    deps: { catalogApi: catalogApiRef },
    factory: ({ catalogApi }) => new TechRadarCustomClient(catalogApi),
  }),
  createApiFactory({
    api: shortcutsApiRef,
    deps: { storageApi: storageApiRef },
    factory: ({ storageApi }) =>
      new DefaultShortcutsApi(storageApi.forBucket('shortcuts')),
  }),
  // oauth2
  createApiFactory({
    api: oidcAuthRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      OAuth2.create({
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'oidc',
          title: 'Single Sign On',
          icon: () => null,
        },
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  // Tools configs
  createApiFactory({
    api: exploreApiRef,
    deps: {
      catalogApi: catalogApiRef,
      alertApi: alertApiRef,
      configApi: configApiRef,
    },
    factory: ({ catalogApi, alertApi, configApi }) =>
      new ToolsConfigApi(catalogApi, alertApi, configApi),
  }),
];
