import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/esm/useAsync';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';

export const useTemplates = () => {
  const catalogApi = useApi(catalogApiRef);

  const {
    value: entities,
    loading,
    error,
  } = useAsync(async () => {
    const response = await catalogApi.getEntities({
      filter: {
        kind: 'Template',
      },
    });

    return response ? (response.items as TemplateEntityV1beta3[]) : [];
  }, [catalogApi]);

  return {
    loading,
    error,
    entities: entities ?? [],
  };
};
