import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useEntityPermission } from '@backstage/plugin-catalog-react/alpha';
import { RepositoryId, harborExecutePermission } from '@mb.io/harbor-common';
import PolicyIcon from '@mui/icons-material/Policy';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { harborApiRef } from '../../api';

type Props = {
  repositoryId: RepositoryId;
  artifactSha256: string;
};

export const ScanButton = ({ artifactSha256, repositoryId }: Props) => {
  const harborApi = useApi(harborApiRef);
  const alertApi = useApi(alertApiRef);
  const { entity } = useEntity();

  // checks the permission if user is allowed to execute a scan action, only the owners are allowed to execute this action.
  const { allowed, loading } = useEntityPermission(harborExecutePermission);

  const onScan = async () => {
    try {
      await harborApi.scan(
        {
          ...repositoryId,
          artifactSha256,
        },
        {
          name: entity.metadata.name,
          namespace: entity.metadata.namespace ?? 'default',
          kind: entity.kind,
        },
      );

      alertApi.post({
        message: `Security Scan scheduled  for artifact ${artifactSha256} on repository ${repositoryId.projectName}/${repositoryId.repoName}`,
        severity: 'info',
      });
    } catch (scanError) {
      alertApi.post({
        message: `Failed to schedule security scan for artifact ${artifactSha256} on repository ${repositoryId.projectName}/${repositoryId.repoName}`,
        severity: 'error',
      });
    }
  };

  return (
    <IconButton
      aria-label="Scan"
      title="Schedule security scan"
      onClick={onScan}
      disabled={loading || !allowed}
      size="large"
    >
      <PolicyIcon />
    </IconButton>
  );
};
