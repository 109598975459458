import { CompoundEntityRef } from '@backstage/catalog-model';
import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  Artifact,
  ArtifactId,
  BuildEntry,
  HarborApi,
  InstanceName,
  LATEST,
  Project,
  ProjectId,
  Repository,
  RepositoryId,
  VulnerabilitiesHolder,
} from '@mb.io/harbor-common';

export const harborApiRef = createApiRef<HarborApi>({
  id: 'plugin.harbor.api',
});

export type HarborRequest = {
  method: string;
  path: string;
  responseAsJson?: boolean;
};

export class HarborApiClient implements HarborApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }

  getProjects({ instanceName }: InstanceName): Promise<Project[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/projects?instance=${encodeURIComponent(instanceName || '')}`,
    });
  }

  getRepositories({
    projectName,
    instanceName,
  }: ProjectId): Promise<Repository[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/projects/${encodeURIComponent(
        projectName,
      )}/repositories?instance=${encodeURIComponent(instanceName || '')}`,
    });
  }

  getRepository({
    projectName,
    repoName,
    instanceName,
  }: RepositoryId): Promise<Repository> {
    return this.submitRequest({
      method: 'GET',
      path: `/projects/${encodeURIComponent(
        projectName,
      )}/repositories/${encodeURIComponent(
        repoName,
      )}?instance=${encodeURIComponent(instanceName || '')}`,
    });
  }

  getArtifacts({
    projectName,
    repoName,
    instanceName,
  }: RepositoryId): Promise<Artifact[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/projects/${encodeURIComponent(
        projectName,
      )}/repositories/${encodeURIComponent(
        repoName,
      )}/artifacts?instance=${encodeURIComponent(instanceName || '')}`,
    });
  }

  getLatestArtifact(repositoryId: RepositoryId): Promise<Artifact> {
    return this.getArtifact({
      ...repositoryId,
      artifactSha256: LATEST,
    });
  }

  getArtifact({
    projectName,
    repoName,
    artifactSha256,
    instanceName,
  }: ArtifactId): Promise<Artifact> {
    return this.submitRequest({
      method: 'GET',
      path: `/projects/${encodeURIComponent(
        projectName,
      )}/repositories/${encodeURIComponent(
        repoName,
      )}/artifacts/${encodeURIComponent(
        artifactSha256,
      )}?instance=${encodeURIComponent(instanceName || '')}`,
    });
  }

  getVulnerabilities({
    projectName,
    repoName,
    artifactSha256,
    instanceName,
  }: ArtifactId): Promise<VulnerabilitiesHolder> {
    return this.submitRequest({
      method: 'GET',
      path: `/projects/${encodeURIComponent(
        projectName,
      )}/repositories/${encodeURIComponent(
        repoName,
      )}/vulnerabilities/${encodeURIComponent(
        artifactSha256,
      )}?instance=${encodeURIComponent(instanceName || '')}`,
    });
  }

  getBuildHistory({
    projectName,
    repoName,
    artifactSha256,
    instanceName,
  }: ArtifactId): Promise<BuildEntry[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/projects/${encodeURIComponent(
        projectName,
      )}/repositories/${encodeURIComponent(
        repoName,
      )}/build-history/${encodeURIComponent(
        artifactSha256,
      )}?instance=${encodeURIComponent(instanceName || '')}`,
    });
  }

  scan(
    { projectName, repoName, artifactSha256, instanceName }: ArtifactId,
    entityRef: CompoundEntityRef,
  ): Promise<any> {
    return this.submitRequest({
      method: 'POST',
      path: `/entity/${entityRef.namespace ?? 'default'}/${entityRef.kind}/${
        entityRef.name
      }/projects/${encodeURIComponent(
        projectName,
      )}/repositories/${encodeURIComponent(
        repoName,
      )}/artifacts/${encodeURIComponent(
        artifactSha256,
      )}/scan?instance=${encodeURIComponent(instanceName || '')}`,
      responseAsJson: false,
    });
  }

  private async submitRequest({
    path,
    method,
    responseAsJson = true,
  }: HarborRequest): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('harbor')}${path}`;

    const headers: Record<string, string> = {};

    const response = await this.fetchApi.fetch(url, { method, headers });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return responseAsJson ? response.json() : response;
  }
}
