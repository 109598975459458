import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { CardHeaderProps } from '@mui/material/CardHeader';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

type Props = {
  title: string;
  actionTitle?: string;
  actionHref?: string;
  nested?: boolean;
} & CardHeaderProps;

const useStyles = makeStyles<{ nested: boolean }>()((_theme, { nested }) => ({
  root: {
    width: '100%',
    // center vertically by removing negative margins
    '& [class*=MuiCardHeader-action]': {
      marginTop: '0px',
      marginBottom: '0px',
    },
    // remove unnecessary padding
    '& [class*=MuiButton-text]': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    ...(nested && {
      paddingLeft: 0,
      paddingRight: '2px',
    }),
  },
}));

export function DefaultCardHeader({
  title,
  actionHref,
  actionTitle,
  nested = false,
  className,
  ...otherProps
}: Props) {
  const { classes } = useStyles({ nested });
  return (
    <CardHeader
      className={className ? `${classes.root} ${className}` : classes.root}
      disableTypography={nested}
      title={nested ? <Typography variant="h6">{title}</Typography> : title}
      action={
        actionHref && (
          <Button href={actionHref} target="_blank" endIcon={<OpenInNew />}>
            {actionTitle}
          </Button>
        )
      }
      {...otherProps}
    />
  );
}
