import { Repository } from '../types';
import { useDependabotSeverityCount } from './useDependabotSeverityCount';

export type SeverityCounter = {
  severity: string;
  count: number;
  weight: number;
};

export const useDependabotHighestSeverity = (
  repository: Repository,
): string => {
  const { criticalCount, highCount, mediumCount, lowCount } =
    useDependabotSeverityCount(repository);

  const severityCounters: SeverityCounter[] = [
    {
      severity: 'Critical',
      count: criticalCount,
      weight: 4,
    },
    {
      severity: 'High',
      count: highCount,
      weight: 3,
    },
    {
      severity: 'Medium',
      count: mediumCount,
      weight: 2,
    },
    {
      severity: 'Low',
      count: lowCount,
      weight: 1,
    },
  ];

  severityCounters.sort((counterA, counterB) => {
    if (counterA.count === 0) return 1;
    if (counterB.count === 0) return -1;
    return counterB.weight - counterA.weight;
  });
  const highestCounter = severityCounters[0];

  return highestCounter.count === 0 ? 'None' : highestCounter.severity;
};
