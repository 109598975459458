import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  wrapper: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));
