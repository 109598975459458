import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { CatalogIcon, DocsIcon, Link } from '@backstage/core-components';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { searchPlugin, SearchType } from '@backstage/plugin-search';
import {
  SearchBar,
  SearchFilter,
  SearchResult,
  SearchResultPager,
  useSearch,
} from '@backstage/plugin-search-react';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ToolSearchResultListItem } from '@backstage-community/plugin-explore';
import HandymanIcon from '@mui/icons-material/Handyman';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { EsgSearchResultListItem } from '@mercedes-benz/ecosystem-guidelines';
import { ifTypes, kinds } from './utils';
import SchoolIcon from '@mui/icons-material/School';
import { AcademySearchResultListItem } from '@mercedes-benz/academy';

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    gap: theme.spacing(1),
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
    '&> button': {
      marginTop: theme.spacing(1),
    },
  },
  container: {
    borderRadius: 30,
    display: 'flex',
    height: '2.4em',
    padding: theme.spacing(1),
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  input: {
    flex: 1,
  },
  dialogActionsContainer: { padding: theme.spacing(1, 3) },
  viewResultsLink: { verticalAlign: '0.5em' },
}));

const rootRouteRef = searchPlugin.routes.root;

function filterRaws(types: string[]) {
  if (ifTypes(types, ['tools'])) {
    return 12;
  } else if (ifTypes(types, ['techdocs', 'guidelines', 'academy'])) {
    return 8;
  }
  return 6;
}

export const SearchModal = ({ toggleModal }: { toggleModal: () => void }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const catalogApi = useApi(catalogApiRef);

  const { term, types } = useSearch();
  const searchBarRef = useRef<HTMLInputElement | null>(null);
  const searchPagePath = `${useRouteRef(rootRouteRef)()}?query=${term}`;

  useEffect(() => {
    searchBarRef?.current?.focus();
  });

  const handleSearchBarKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement | HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        toggleModal();
        navigate(searchPagePath);
      }
    },
    [navigate, toggleModal, searchPagePath],
  );

  const kindsFilters = kinds(types);

  return (
    <>
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          <SearchBar
            className={classes.input}
            inputProps={{ ref: searchBarRef }}
            onKeyDown={handleSearchBarKeyDown}
          />

          <IconButton aria-label="close" onClick={toggleModal} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <SearchType.Tabs
              defaultValue="software-catalog"
              types={[
                {
                  value: 'software-catalog',
                  name: 'Software Catalog',
                },
                {
                  value: 'techdocs',
                  name: 'Documentation',
                },
                {
                  value: 'guidelines',
                  name: 'Guidelines',
                },
                {
                  value: 'academy',
                  name: 'Academy',
                },
                {
                  value: 'tools',
                  name: 'Tools',
                },
              ]}
            />
          </Grid>
          <Grid item container>
            {!ifTypes(types, ['tools']) && (
              <Grid item xs={2}>
                <SearchFilter.Autocomplete
                  className={classes.filter}
                  label="Owner"
                  name="owner"
                  values={async () => {
                    // Return a list of entities which are documented.
                    const { items } = await catalogApi.getEntities({
                      fields: ['metadata.name'],
                      filter: {
                        kind: 'Group',
                      },
                    });

                    const names = items.map(entity => entity.metadata.name);
                    names.sort((a, b) => a.localeCompare(b));

                    return names;
                  }}
                />
              </Grid>
            )}
            {kindsFilters.length > 0 && (
              <Grid item xs={2}>
                <SearchFilter.Autocomplete
                  className={classes.filter}
                  label="Kind"
                  name="kind"
                  values={kindsFilters}
                />
              </Grid>
            )}
            {!ifTypes(types, ['tools']) && (
              <Grid item xs={2}>
                <SearchFilter.Autocomplete
                  className={classes.filter}
                  label="Lifecycle"
                  name="lifecycle"
                  values={['experimental', 'production', 'dev', 'prod', 'poc']}
                />
              </Grid>
            )}
            <Grid
              item
              xs={filterRaws(types)}
              container
              direction="row-reverse"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Button
                  to={searchPagePath}
                  onClick={toggleModal}
                  endIcon={<ArrowForwardIcon />}
                  component={Link}
                  color="primary"
                >
                  View Full Results
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <SearchResult>
              <CatalogSearchResultListItem icon={<CatalogIcon />} />
              <TechDocsSearchResultListItem icon={<DocsIcon />} />
              <EsgSearchResultListItem icon={<AutoStoriesIcon />} />
              <ToolSearchResultListItem icon={<HandymanIcon />} />
              <AcademySearchResultListItem icon={<SchoolIcon />} />
            </SearchResult>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActionsContainer}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <SearchResultPager />
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
