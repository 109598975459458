import { Theme, useTheme } from '@mui/material/styles';
import { black, green, grey, red, white } from '@mercedes-benz/mui5-theme';
import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { makeStyles } from 'tss-react/mui';
import { labelStyles } from '../shared-styles';

export type SeverityStyle = {
  backgroundColor: string;
  color: string;
};

type Palette = {
  none: SeverityStyle;
  low: SeverityStyle;
  medium: SeverityStyle;
  high: SeverityStyle;
  critical: SeverityStyle;
};

type Props = {
  severity: string;
  palette?: Palette;
};

const useStyles = makeStyles<{ palette: Palette }>()((theme, { palette }) => ({
  gaugeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // propagate Theme-FontFamily to <ReactSpeedometer />
    '& .segment-value, .current-value': {
      ...labelStyles(theme),
      fontFamily: theme.typography?.fontFamily,
      textTransform: 'unset',
      fill:
        theme.palette.mode === 'light'
          ? `rgba(0, 0, 0, 0.54) !important`
          : `${grey[90]} !important`,
    },
    // customize label colors
    '& [class=speedometer] [class=label] text:nth-child(1)': {
      fill: `${palette.none.color} !important`,
    },
    '& [class=speedometer] [class=label] text:nth-child(2)': {
      fill: `${palette.low.color} !important`,
    },
    '& [class=speedometer] [class=label] text:nth-child(3)': {
      fill: `${palette.medium.color} !important`,
    },
    '& [class=speedometer] [class=label] text:nth-child(4)': {
      fill: `${palette.high.color} !important`,
    },
    '& [class=speedometer] [class=label] text:nth-child(5)': {
      fill: `${palette.critical.color} !important`,
    },
  },
}));

export function getSeverity(severity: string) {
  let severityNumber: number;
  switch (severity) {
    case 'Low':
      severityNumber = 150;
      break;
    case 'Medium':
      severityNumber = 250;
      break;
    case 'High':
      severityNumber = 350;
      break;
    case 'Critical':
      severityNumber = 450;
      break;
    default:
      severityNumber = 50;
      break;
  }
  return severityNumber;
}

const getDefaultPalette = (theme: Theme): Palette => ({
  none: {
    backgroundColor: green[55],
    color: white,
  },
  low: {
    backgroundColor: theme.palette.mode === 'light' ? red[85] : red[70],
    color: black,
  },
  medium: {
    backgroundColor: theme.palette.mode === 'light' ? red[60] : red[55],
    color: black,
  },

  high: {
    backgroundColor: red[45],
    color: white,
  },
  critical: {
    backgroundColor: red[35],
    color: white,
  },
});

const getSegmentColors = (palette: Palette) => {
  return [
    palette.none.backgroundColor,
    palette.low.backgroundColor,
    palette.medium.backgroundColor,
    palette.high.backgroundColor,
    palette.critical.backgroundColor,
  ];
};

export const SeverityGauge = ({ severity, palette: customPalette }: Props) => {
  const theme = useTheme();
  const defaultPalette = getDefaultPalette(theme);
  const { classes } = useStyles({
    palette: customPalette ? customPalette : defaultPalette,
  });

  return (
    <div className={classes.gaugeContainer}>
      <ReactSpeedometer
        value={getSeverity(severity)}
        width={450}
        minValue={0}
        maxValue={500}
        segmentColors={getSegmentColors(
          customPalette ? customPalette : defaultPalette,
        )}
        customSegmentStops={[0, 100, 200, 300, 400, 500]}
        currentValueText="Highest Vulnerability Level"
        customSegmentLabels={[
          {
            text: 'None',
          },
          {
            text: 'Low',
          },
          {
            text: 'Medium',
          },
          {
            text: 'High',
          },
          {
            text: 'Critical',
          },
        ]}
      />
    </div>
  );
};
