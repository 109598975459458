import { Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

export function labelStyles(theme: Theme) {
  return {
    color: theme.palette?.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;
}
