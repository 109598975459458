import Grid from '@mui/material/Grid';
import { GridProps } from '@mui/material/Grid';
import React from 'react';

import { makeStyles } from 'tss-react/mui';

type Props = {
  variant?: 'container' | 'item' | 'itemContainer';
  indent?: boolean;
  children: React.ReactNode;
} & Omit<GridProps, 'container' | 'item'>;

const useStyles = makeStyles()(theme => ({
  container: {
    paddingRight: 'unset',
  },
  item: {
    padding: 'unset',
  },
  indent: {
    padding: theme.spacing(1),
  },
}));

/**
 * Wraps `@mui/material/Grid` and omits the default paddings.
 *
 * @param Props.
 * Use `variant`-prop instead of the `container` and `item` props. `variant` defaults to `container`, possible values are `'container' | 'item' | 'itemContainer'`.
 * Grid without the `variant`-property would default to just a plain `div` and hence is not supported.
 * `container` is a width: 100% flex-box with flex-wrap and sizing border-box
 * `item` is a zero-margin div with border-box-sizing
 * Use `indent` to increase the visual indentation and visually express parent-child relation of components.
 *
 * All other React-props, e.g. `className`, `style`, as well as all props from the Grid-API, e.g. `direction`, `spacing`, `columns`, etc. can still be used.
 * @returns configured instance of `@mui/material/Grid`
 */
export function DefaultGrid({
  variant = 'container',
  indent = false,
  children,
  className,
  ...otherProps
}: Props) {
  const { classes } = useStyles();

  const calculatedClasses = React.useMemo(() => {
    let variantClasses: string;
    switch (variant) {
      case 'item':
        variantClasses = classes.item;
        break;
      case 'itemContainer':
        variantClasses = `${classes.container} ${classes.item}`;
        break;
      case 'container':
      default:
        variantClasses = classes.container;
        break;
    }
    const result = [variantClasses];
    if (indent) result.push(classes.indent);
    if (className) result.push(className);
    return result.join(' ');
  }, [classes, indent, variant, className]);

  return (
    <Grid
      data-testid="DefaultGrid"
      className={calculatedClasses}
      container={variant === 'container' || variant === 'itemContainer'}
      item={variant === 'item' || variant === 'itemContainer'}
      {...otherProps}
    >
      {children}
    </Grid>
  );
}
