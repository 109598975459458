import { isEqual } from 'lodash';
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

// no need to store all other fields in this case.
export type UserFeatureFlagType = Record<string, boolean | undefined>;

type UserFeatureFlagContextType = {
  features: UserFeatureFlagType;
  initialize: (features: UserFeatureFlagType) => void;
  update: (feature: string, enabled: boolean) => void;
};

const STORAGE_KEY = 'backstage.user_enabled_features';

const readInitialFeatures = (): UserFeatureFlagType => {
  const item = localStorage.getItem(STORAGE_KEY);
  if (!item) {
    return {};
  }
  return JSON.parse(item);
};

const UserFeatureFlagContext = createContext<UserFeatureFlagContextType>({
  features: readInitialFeatures(),
  initialize: () => {
    throw new Error('Unsupported yet');
  },
  update: () => {
    throw new Error('Unsupported yet');
  },
});

export const UserFeatureFlagContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [features, setFeatures] = useState<UserFeatureFlagType>(
    readInitialFeatures(),
  );
  const value: UserFeatureFlagContextType = useMemo(
    () => ({
      features,
      initialize: (initialFeatures: UserFeatureFlagType) => {
        if (!isEqual(initialFeatures, features)) {
          setFeatures(initialFeatures);
        }
      },
      update: (feature: string, enabled: boolean) => {
        const updatedFeatures = { ...features, [feature]: enabled };
        localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedFeatures));
        setFeatures(updatedFeatures);
      },
    }),
    [features, setFeatures],
  );

  return (
    <UserFeatureFlagContext.Provider value={value}>
      {children}
    </UserFeatureFlagContext.Provider>
  );
};

export function useUserFeatureFlagContext(): UserFeatureFlagContextType {
  const context = useContext(UserFeatureFlagContext);
  if (!context) {
    throw new Error(
      'useUserFeatureFlagContext must be used within GlobalFlagContextType, use <UserFeatureFlagContext /> before calling this hook',
    );
  }
  return context;
}
