import { EmptyState, Link, WarningPanel } from '@backstage/core-components';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { GridItemComponent } from '@mercedes-benz/plugin-landing-page';
import React from 'react';
import { ScaffolderCategory } from '../../types';
import { CATEGORY_ICONS } from '../icons';
import { useScaffolderCategories } from '../../hooks/useScaffolderCategories';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  card: {
    height: '100%',
    transition: 'transform 200ms ease',
    '> a': {
      textDecoration: 'none !important',
    },
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
});

export const ScaffolderCategoriesGrid = () => {
  const { classes } = useStyles();
  const { loading, error, categories } = useScaffolderCategories();

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return (
      <WarningPanel
        severity="error"
        title="Could not load Scaffolder Categories."
      >
        {error.message}
      </WarningPanel>
    );
  }

  if (!categories?.length) {
    return (
      <EmptyState
        missing="info"
        title="No Scaffolder Categories to display"
        description="You haven't configured any Scaffolder categories yet."
        action={
          <Button
            variant="contained"
            color="primary"
            href="/docs/default/component/backstage-user-doc/catalog/catalog-content/"
          >
            Read more
          </Button>
        }
      />
    );
  }

  function toItem(category: ScaffolderCategory) {
    return {
      icon: CATEGORY_ICONS[category.id] ?? <LibraryBooksIcon />,
      title: category.title,
      description: category.summary,
      id: category.id,
    };
  }

  return (
    <Grid container alignItems="stretch" spacing={2}>
      {categories
        .map(category => toItem(category))
        .map((item, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Card className={classes.card}>
              <Link to={`/create/templates/${item.id}`}>
                <GridItemComponent key={index} item={item} alignCentered />
              </Link>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};
