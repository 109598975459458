import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/esm/useAsync';

/**
 * React hook to retrieve a reference of authenticated user identity along with profile info.
 *
 * @author manuel.santos@mercedes-benz.io
 * */
export const useIdentityRef = () => {
  const identityApi = useApi(identityApiRef);

  const { value, error, loading } = useAsync(async () => {
    return {
      profile: await identityApi.getProfileInfo(),
      identity: await identityApi.getBackstageIdentity(),
    };
  }, [identityApi]);

  return {
    profile: value?.profile,
    identity: value?.identity,
    loading,
    error,
  };
};
