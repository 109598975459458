export const INFRA_ACCOUNT = 'account';
export const INFRA_DOCUMENTDB = 'documentdb';
export const INFRA_EFS = 'efs';
export const INFRA_EKS = 'eks';
export const INFRA_CACHE = 'cache';
export const INFRA_GATEWAY = 'gateway';
export const INFRA_LAMBDA = 'lambda';
export const INFRA_MEMORYDB = 'memorydb';
export const INFRA_MQ = 'mq';
export const INFRA_SEARCH = 'search';
export const INFRA_RDS_INSTANCE = 'rds-instance';
export const INFRA_RDS_CLUSTER = 'rds-cluster';
export const INFRA_S3 = 's3';
export const INFRA_SNS = 'sns';
export const INFRA_SQS = 'sqs';
export const INFRA_DYNAMODB_TABLE = 'dynamodb-table';
export const INFRA_DYNAMODB_GLOBAL_TABLE = 'dynamodb-global-table';
export const INFRA_DYNAMODB_STREAM = 'dynamodb-stream';
