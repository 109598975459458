import { LinkButton } from '@backstage/core-components';
import Typography from '@mui/material/Typography';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useApp } from '@backstage/core-plugin-api';
import WorkIcon from '@mui/icons-material/Work';

const useStyles = makeStyles()(theme => ({
  heading: {
    marginBottom: theme.spacing(4),
  },
  icon: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    width: '50px',
    height: '50px',
    padding: theme.spacing(1),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& svg': {
      fill: '#fff',
    },
  },
  iconOutlined: {
    width: '50px',
    height: '50px',
    padding: theme.spacing(1),
    borderRadius: '50%',
    border: 'solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& svg': {
      fill: theme.palette.mode === 'dark' ? '#FFFFFF' : '#0078D6',
    },
  },
  noStyle: {
    width: '50px !important',
    height: '50px !important',
    marginBottom: theme.spacing(2),
    border: 0,
    background: 'none',
  },
  item: {
    padding: '1rem',
  },
  title: {
    fontWeight: 700,
  },
  titleOutlined: {
    fontWeight: 400,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& .MuiCardActions-root': {
      justifyContent: 'center',
    },
    '& .icon': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  link: {
    padding: '0',
    marginTop: '1rem',
    display: 'block',
    textDecoration: 'none !important',
  },
}));

export type Item = {
  icon?: React.JSX.Element;
  systemIcon?: string;
  title: string;
  description?: string;
  link?: string;
};

type GridComponentProps = {
  item: Item;
  alignCentered?: boolean;
  outlined?: boolean;
  noStyle?: boolean;
};

export const GridItemComponent = ({
  item,
  alignCentered,
  noStyle = true,
  outlined,
}: GridComponentProps) => {
  const app = useApp();
  const { classes } = useStyles();
  const { systemIcon, icon } = item;

  const iconClass = noStyle ? classes.noStyle : outlined;

  const Icon = systemIcon
    ? app.getSystemIcon(systemIcon!) ?? WorkIcon
    : undefined;
  return (
    <div className={`${alignCentered ? classes.center : ''} ${classes.item} `}>
      {icon && (
        <div
          className={`${outlined ? classes.iconOutlined : classes.icon} icon`}
        >
          {icon}
        </div>
      )}
      {Icon && (
        <Icon
          className={`${
            outlined ? classes.iconOutlined : classes.icon
          } icon ${iconClass}`}
        />
      )}
      <Typography
        className={outlined ? classes.titleOutlined : classes.title}
        variant="h6"
        gutterBottom
      >
        {item.title}
      </Typography>
      {item.description && (
        <Typography variant="body2" color="textSecondary">
          {item.description}
        </Typography>
      )}

      {item.link && (
        <LinkButton
          to={item.link}
          size="small"
          color="primary"
          variant="text"
          className={classes.link}
        >
          Learn More
        </LinkButton>
      )}
    </div>
  );
};
