import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const useStyles = makeStyles()(theme => ({
  close: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  newsletterSignup: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    '& .v5-MuiTypography-root': {
      marginBottom: theme.spacing(1),
    },
    '& .v5-MuiTextField-root': {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
  },
  backdrop: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgb(255 255 255 / 85%)'
        : 'rgb(66 66 66 / 85%)',
  },
}));

export function NewsletterDialog(props: {
  open: boolean;
  onClose: () => void;
}) {
  const { open, onClose } = props;
  const { classes } = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="newsletter-dialog-title"
      slotProps={{
        backdrop: {
          classes: {
            root: classes.backdrop,
          },
        },
      }}
    >
      <DialogTitle>Newsletter Subscription</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        className={classes.close}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid item sm={12} className={classes.newsletterSignup}>
          <Typography>
            Type your email to subscribe to Backstage newsletter
          </Typography>
          <TextField label="Email" variant="outlined" />
          <Button variant="contained" color="secondary">
            Subscribe
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
