import {
  CardTab,
  EmptyState,
  StructuredMetadataTable,
  TabbedCard,
} from '@backstage/core-components';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import React from 'react';
import { ManifestProps } from '../AemManifestPage';

export const ManifestResourcesCard = ({ manifestFile }: ManifestProps) => {
  return (
    <TabbedCard title="Resources">
      <CardTab label="Scripts">
        <Grid container spacing={4} alignItems="stretch">
          {manifestFile?.config.files.scripts.map((script, index) => (
            <Grid md={6} item className="grid-item" key={`script-${index}`}>
              <Paper variant="outlined">
                <StructuredMetadataTable
                  metadata={{ ...script.attrs, url: script.url }}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </CardTab>
      <CardTab label="Styles">
        {!manifestFile?.config.files.styles?.length && (
          <EmptyState title="No style sheet found" missing="data" />
        )}
        {manifestFile?.config.files.styles?.map((style, index) => (
          <Grid md={6} item className="grid-item" key={`style-${index}`}>
            <Paper variant="outlined">
              <StructuredMetadataTable
                metadata={{ ...style.attrs, url: style.url }}
              />
            </Paper>
          </Grid>
        ))}
      </CardTab>
    </TabbedCard>
  );
};
