import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  ActionItemsResponse,
  AnnouncementsResponse,
  HomeApi,
  MigrationsResponse,
  SummaryResponse,
} from '@mercedes-benz/home-common';

export const homeApiRef = createApiRef<HomeApi>({
  id: 'plugin.home.api',
});

export type HttpRequest = {
  method: string;
  path: string;
  responseAsJson?: boolean;
};

export class HomeApiClient implements HomeApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }

  getSummary(): Promise<SummaryResponse> {
    return this.submitRequest({
      method: 'GET',
      path: `/summary`,
    });
  }

  getActionItems(): Promise<ActionItemsResponse> {
    return this.submitRequest({
      method: 'GET',
      path: `/actions`,
    });
  }

  getAnnouncements(): Promise<AnnouncementsResponse> {
    return this.submitRequest({
      method: 'GET',
      path: `/announcements`,
    });
  }

  getMigrations(): Promise<MigrationsResponse> {
    return this.submitRequest({
      method: 'GET',
      path: `/migrations`,
    });
  }

  getAssignedIssues(): Promise<void> {
    return Promise.resolve(undefined);
  }

  getDeployments(): Promise<void> {
    return Promise.resolve(undefined);
  }

  getInsights(): Promise<void> {
    return Promise.resolve(undefined);
  }

  getNotifications(): Promise<void> {
    return Promise.resolve(undefined);
  }

  getPullRequests(): Promise<void> {
    return Promise.resolve(undefined);
  }

  private async submitRequest({
    path,
    method,
    responseAsJson = true,
  }: HttpRequest): Promise<any> {
    const headers: Record<string, string> = {};
    const url = `${await this.discoveryApi.getBaseUrl('home')}${path}`;
    const response = await this.fetchApi.fetch(url, { method, headers });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return responseAsJson ? response.json() : response;
  }
}
