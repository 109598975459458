import { Entity } from '@backstage/catalog-model';
import { API_VERSION_V1ALPHA1 } from '../../common';
import { Kind } from '../../kind-type';

export enum ContentType {
  CSV = 'csv',
  JSON = 'json',
  TEXT = 'text',
  YAML = 'yaml',
  MARKDOWN = 'markdown',
}

/**
 * @public
 */
export interface ContentAlphaEntity extends Entity {
  apiVersion: API_VERSION_V1ALPHA1;
  kind: Kind.CONTENT;
  spec: {
    data?: string;
    fetch?: boolean;
    target?: string;
    type: ContentType;
    lifecycle: string;
    // An ownership relation where the owner is usually an organizational entity (User or Group)
    owner: string;
    // An entity reference to the system that the component belongs to.
    system?: string;
  };
}
