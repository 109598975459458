import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { EmptyState } from '@backstage/core-components';
import { EntitySwitch } from '@backstage/plugin-catalog';
import {
  EntityGithubActionsContent,
  isGithubActionsAvailable,
} from '@backstage-community/plugin-github-actions';
import { EntityGithubDeploymentsCard } from '@backstage-community/plugin-github-deployments';
import {
  EntityJenkinsContent,
  isJenkinsAvailable,
} from '@backstage-community/plugin-jenkins';
import { GitHubGuard } from '@mercedes-benz/permission';
import React from 'react';

export const CICDContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={isJenkinsAvailable}>
      <EntityJenkinsContent />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <GitHubGuard feature="Actions and Deployments">
        <Grid container spacing={3} alignItems="stretch" direction="row">
          <Grid item xs={12}>
            <EntityGithubDeploymentsCard />
          </Grid>
          <Grid item xs={12}>
            <EntityGithubActionsContent />
          </Grid>
        </Grid>
      </GitHubGuard>
    </EntitySwitch.Case>
    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);
