import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ReportOverview } from '@mercedes-benz/pmas-common';
import { submitRequest } from './submitRequest';

export class FingerpointerApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi;

  constructor(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  async getReportsOverview(): Promise<ReportOverview[]> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'GET',
      path: `/fingerpointer/overview`,
    });
  }

  async getReportRun(reportRunId: number): Promise<any> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'GET',
      path: `/fingerpointer/reportRun/${reportRunId}`,
    });
  }

  async getFindings(
    subjectBlockId: number,
    evaluationBlockId: number,
  ): Promise<any> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'GET',
      path: `/fingerpointer/reportRun/${subjectBlockId}/evaluationBlock/${evaluationBlockId}`,
    });
  }

  async downloadReport(
    runId: number,
    format: string,
    subjectBlocks: string,
  ): Promise<string> {
    return submitRequest({
      discoveryApi: this.discoveryApi,
      fetchApi: this.fetchApi,
      method: 'GET',
      path: `/fingerpointer/download/${runId}/${format}/${subjectBlocks}`,
      responseType: 'text',
    });
  }
}
