import {
  Entity,
  RELATION_OWNED_BY,
  stringifyEntityRef,
  UserEntity,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/esm/useAsync';

/**
 * React hook to retrieve users belonging to group/user owning an entity.
 *
 * @param entity - entity to scan owners for.
 * @author manuel.santos@mercedes-benz.io
 * */
export const useOwnerUsers = (entity: Entity) => {
  const catalogApi = useApi(catalogApiRef);

  const {
    value: ownership,
    loading,
    error,
  } = useAsync(async () => {
    const owners = getEntityRelations(entity, RELATION_OWNED_BY);

    // Only fetch details if the owners is not empty, otherwise it will fetch everything.
    if (owners.length > 0) {
      // Entity can be owned by a single User or by a Group
      const userNames = owners
        .filter(owner => owner.kind.toLocaleLowerCase('en-US') === 'user')
        .map(userRef => userRef.name);

      const groupRefs = owners
        .filter(owner => owner.kind.toLocaleLowerCase('en-US') === 'group')
        .map(groupRef => stringifyEntityRef(groupRef)); // kind:namespace/name;

      const response = await catalogApi.getEntities({
        filter: {
          kind: 'User',
          'metadata.name': userNames.length > 0 ? userNames : Symbol(),
          'relations.memberOf': groupRefs.length > 0 ? groupRefs : Symbol(),
        },
      });
      return {
        users: response.items as UserEntity[],
        ownerRefs: owners,
      };
    }
    return {};
  }, [catalogApi, entity]);

  return {
    users: ownership?.users ?? [],
    ownerRefs: ownership?.ownerRefs ?? [],
    loading,
    error,
  };
};
