import { useApi } from '@backstage/core-plugin-api';
import { PREFERENCE_OPT_OUT } from '@mercedes-benz/user-settings-common';
import { useCallback, useState } from 'react';
import useAsync from 'react-use/esm/useAsync';
import { userSettingsApiRef } from '../api-refs';
import { OptOutPreference } from '../types';

export type OptOutCallbacks = {
  onOptOut?: Function;
  onOptIn?: Function;
  onError?: Function;
};

export function useOptOut(feature: string, callbacks?: OptOutCallbacks) {
  const userSettingsApi = useApi(userSettingsApiRef);
  const [optOutActive, setOptOutActive] = useState(false);
  const [error, setError] = useState(null);

  const handleError = useCallback(
    (exception: any) => {
      setError(exception);
      if (callbacks && callbacks.onError) {
        callbacks.onError(exception);
      }
    },
    [callbacks],
  );

  useAsync(async () => {
    try {
      const preference = await userSettingsApi.get({
        bucket: PREFERENCE_OPT_OUT,
        key: feature,
      });

      if (preference?.value) {
        const preferenceValue = preference?.value as OptOutPreference;
        setOptOutActive(preferenceValue.optOut);
      } else {
        setOptOutActive(false);
      }
    } catch (exception) {
      handleError(exception);
    }
  }, []);

  const toggleOptOut = useCallback(() => {
    if (optOutActive) {
      // OPT-IN if already OUT
      userSettingsApi
        .delete({
          bucket: PREFERENCE_OPT_OUT,
          key: feature,
        })
        .then(_ => {
          setOptOutActive(false);
          if (callbacks && callbacks.onOptIn) {
            callbacks.onOptIn();
          }
        })
        .catch(exception => {
          handleError(exception);
        });
    } else {
      // OPT-OUT if already IN
      userSettingsApi
        .set({
          bucket: PREFERENCE_OPT_OUT,
          key: feature,
          value: {
            feature: feature,
            optOut: true,
          },
        })
        .then(_ => {
          setOptOutActive(true);
          if (callbacks && callbacks.onOptOut) {
            callbacks.onOptOut();
          }
        })
        .catch(exception => {
          handleError(exception);
        });
    }
  }, [callbacks, feature, handleError, optOutActive, userSettingsApi]);

  return {
    optOutActive,
    error,
    toggleOptOut,
  };
}
