/**
 * Constant storing Infrastructure Platform annotation
 **/
export const INFRA_PLATFORM_ANNOTATION = 'infra.mercedes-benz.com/platform';
/**
 * Constant storing Infrastructure Tenant annotation
 **/
export const INFRA_TENANT_ANNOTATION = 'infra.mercedes-benz.com/tenant';
/**
 * Constant storing Infrastructure Environment annotation
 **/
export const INFRA_ENVIRONMENT_ANNOTATION =
  'infra.mercedes-benz.com/environment';
