import { IconLinkVerticalProps } from '@backstage/core-components';
import {
  Confluence,
  Discord,
  Jira,
  Mattermost,
  Mercedes,
  Microsoftteams,
  Skypeforbusiness,
  Slack,
} from '@icons-pack/react-simple-icons';
import React from 'react';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import PhoneIcon from '@mui/icons-material/ContactPhone';
import GitHubIcon from '@mui/icons-material/GitHub';
import WebIcon from '@mui/icons-material/Language';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuBook from '@mui/icons-material/MenuBook';
import { BackstageIcon } from '../../icons';

export const DEFAULT_KEY = 'web';
export const DOCS_KEY = 'docs';

export const channelsDb = (): Record<string, IconLinkVerticalProps> => {
  const style = { width: 24, height: 24 };
  const size = style.width;

  return {
    web: {
      label: 'Web',
      icon: <WebIcon style={style} />,
    },
    email: {
      label: 'Email',
      icon: <ContactMailIcon style={style} />,
    },
    mailto: {
      label: 'Email',
      icon: <ContactMailIcon style={style} />,
    },
    tel: {
      label: 'Phone',
      icon: <PhoneIcon style={style} />,
    },
    teams: {
      label: 'Teams',
      icon: <Microsoftteams size={size} />,
    },
    slack: {
      label: 'Slack',
      icon: <Slack size={size} />,
    },
    mattermost: {
      label: 'Mattermost',
      icon: <Mattermost size={size} />,
    },
    skype: {
      label: 'Skype',
      icon: <Skypeforbusiness size={size} />,
    },
    discord: {
      label: 'Discord',
      icon: <Discord size={size} />,
    },
    tenants: {
      label: 'TDMS',
      icon: <Mercedes size={size} />,
    },
    backstage: {
      label: 'Backstage',
      icon: <BackstageIcon style={style} />,
    },
    git: {
      label: 'Github',
      icon: <GitHubIcon style={style} />,
    },
    github: {
      label: 'Github',
      icon: <GitHubIcon style={style} />,
    },
    docs: {
      label: 'Docs',
      icon: <LibraryBooksIcon style={style} />,
    },
    doc: {
      label: 'Docs',
      icon: <LibraryBooksIcon style={style} />,
    },
    jira: {
      label: 'Jira',
      icon: <Jira size={size} />,
    },
    confluence: {
      label: 'confluence',
      icon: <Confluence size={size} />,
    },
    guidelines: {
      label: 'Guidelines',
      icon: <MenuBook style={style} />,
    },
  };
};
