import React from 'react';
import { makeStyles } from 'tss-react/mui';
import LinesSecondary from '../../assets/lines-1.svg';
import Lines from '../../assets/lines.svg';

const useStyles = makeStyles()(() => ({
  lines: {
    position: 'absolute',
    top: 0,
    right: 0,
    pointerEvents: 'none',
  },
  linesSecondary: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
  },
}));

export const AbstractComponent = ({
  type = 'primary',
}: {
  type?: 'primary' | 'secondary';
}) => {
  const { classes } = useStyles();

  return type === 'secondary' ? (
    <img src={LinesSecondary} alt="" className={classes.linesSecondary} />
  ) : (
    <img src={Lines} alt="" className={classes.lines} />
  );
};
