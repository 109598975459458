import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import { RELATION_HAS_PACKAGE } from '@mb.io/catalog-model';
import React from 'react';
import {
  asSeamlessEntities,
  seamlessEntityColumns,
  seamlessEntityHelpLink,
} from '../seamless-constants';

type Props = {
  variant?: 'gridItem';
};

export const SeamlessHasPackagesCard = ({ variant = 'gridItem' }: Props) => {
  return (
    <RelatedEntitiesCard
      variant={variant}
      title="Has packages"
      entityKind="Seamless"
      relationType={RELATION_HAS_PACKAGE}
      columns={seamlessEntityColumns}
      asRenderableEntities={asSeamlessEntities}
      emptyMessage="This project contains no packages."
      emptyHelpLink={seamlessEntityHelpLink}
    />
  );
};
