import {
  BackstageThemeAdditions,
  createUnifiedTheme,
  PageThemeSelector,
  themes,
  UnifiedTheme,
} from '@backstage/theme';
import {
  black,
  blue,
  DarkTheme,
  grey,
  LightTheme,
  white,
} from '@mercedes-benz/mui5-theme';
import { merge } from 'lodash';
import { isCustom } from '../conditions';
import { createCustomThemeOverrides } from './custom-theme-overrides';
import { getHeaderBackground } from './header-background';
import { ThemeOptions } from '@mui/material/styles';

export const MB_PRIMARY_LIGHT = blue[50];
export const MB_PRIMARY_DARK = blue[65];
export const BACKGROUND_HOVER_LIGHT = blue[55];
export const BACKGROUND_HOVER_DARK = blue[60];

type Props = {
  baseTheme: typeof DarkTheme | typeof LightTheme;
  additionalPalette?: Partial<typeof DarkTheme.palette>;
  additionalOverrides?: ThemeOptions['components'];
  type?: 'light' | 'dark';
  navigationBackgroundColor?: string;
};

const getPageTheme =
  (source: BackstageThemeAdditions['getPageTheme']) =>
  (selector: PageThemeSelector) => {
    const pageTheme = source(selector);

    if (isCustom(selector.themeId)) {
      const [, parentType] = selector.themeId.split('-') ?? [];
      const headerBackground = getHeaderBackground(
        selector.themeId,
        parentType,
      );
      return {
        ...pageTheme,
        backgroundImage: headerBackground,
      };
    }
    // fallback if type is missing or not configured as well for seamless
    return pageTheme;
  };

export const mbuiBaseTheme = ({
  baseTheme,
  additionalPalette,
  additionalOverrides,
  type = 'light',
  navigationBackgroundColor,
}: Props): UnifiedTheme => {
  const current =
    type === 'dark'
      ? themes.dark.getTheme('v5')!
      : themes.light.getTheme('v5')!;

  baseTheme.typography.h1.fontFamily =
    'MB Corpo A Title Condensed,MB Corpo S Title Condensed';
  baseTheme.typography.h2.fontFamily =
    'MB Corpo A Title Condensed,MB Corpo S Title Condensed';
  baseTheme.typography.h3.fontFamily =
    'MB Corpo A Title Condensed,MB Corpo S Title Condensed';
  baseTheme.typography.h4.fontFamily =
    'MB Corpo A Title Condensed,MB Corpo S Title Condensed';
  baseTheme.typography.h5.fontFamily = 'MB Corpo S Text';

  const theme = createUnifiedTheme({
    components: {
      ...merge(
        baseTheme.components,
        createCustomThemeOverrides(type),
        additionalOverrides,
      ),
    },
    palette: {
      ...current.palette,
      ...baseTheme.palette,
      ...additionalPalette,
      navigation: {
        background: navigationBackgroundColor || black,
        indicator: grey[35],
        color: grey[90],
        selectedColor: white,
        navItem: {
          hoverBackground:
            type === 'dark' ? BACKGROUND_HOVER_DARK : BACKGROUND_HOVER_LIGHT,
        },
      },
    },
    fontFamily: 'MB Corpo S Text',
    typography: {
      ...current.typography,
      ...(baseTheme.typography as any),
    },
  });

  const sourceV5 = theme.getTheme('v5')!.getPageTheme;
  const sourceV4 = theme.getTheme('v4')!.getPageTheme;

  theme.getTheme('v5')!.getPageTheme = getPageTheme(sourceV5);
  theme.getTheme('v4')!.getPageTheme = getPageTheme(sourceV4);

  return theme;
};
