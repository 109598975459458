import { Progress } from '@backstage/core-components';
import { ActionPermissionsType } from '@mb.io/tdms-common';
import React, { ReactNode } from 'react';
import { AccessType, usePermissionContext } from './PermissionContext';

type WithPermissionProps = {
  children: ReactNode;
  access: AccessType;
  kind: keyof ActionPermissionsType;
  tenant?: string;
};

export const PermissionCheck = ({
  access,
  children,
  tenant,
  kind,
}: WithPermissionProps) => {
  const { usePermission } = usePermissionContext();
  const { value, loading } = usePermission(access, kind, tenant);

  if (loading) {
    return <Progress />;
  }
  if (!value) {
    return <div>You do not have access to this page!</div>;
  }
  return <> {value && children} </>;
};
