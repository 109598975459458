import Grid from '@mui/material/Grid';
import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  EntityOwnershipCard,
  EntityUserProfileCard,
} from '@backstage/plugin-org';
import React from 'react';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';

export const UserPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard variant="gridItem" height={800} maxDepth={1} />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
