import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/esm/useAsync';
import { parseEntityRef } from '../utils';

/**
 * React hook to retrieve favourite items based on starred information.
 *
 * @author manuel.santos@mercedes-benz.io
 * */
export const useFavouriteEntities = (): {
  loading: boolean;
  error: Error | undefined;
  entities: Entity[] | undefined;
} => {
  const { starredEntities } = useStarredEntities();
  const catalogApi = useApi(catalogApiRef);

  const {
    value: entities,
    loading,
    error,
  } = useAsync(async () => {
    const starredEntityNames = Array.from(starredEntities.values()).map(
      entityRef => parseEntityRef(entityRef),
    );

    // Only fetch details if the itemNames is not empty, otherwise it will fetch everything.
    if (starredEntityNames.length > 0) {
      const filter = starredEntityNames.map(entityName => {
        return {
          kind: entityName.kind ?? 'component',
          'metadata.namespace': entityName.namespace ?? 'default',
          'metadata.name': entityName.name,
        };
      });

      const response = await catalogApi.getEntities({
        filter,
        fields: [
          'kind',
          'metadata.name',
          'metadata.title',
          'metadata.namespace',
          'spec.system',
          'spec.owner',
        ],
      });
      return response.items;
    }
    return [];
  }, [catalogApi, starredEntities]);

  return {
    loading,
    error,
    entities,
  };
};
