import {
  ExpandableNavigation,
  ReportIssue,
  TextSize,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import React from 'react';
import { EntityTechdocsContent } from '../../techdocs-wrapper/TechDocsContentReaderWrapper';
import { Mermaid } from '@mercedes-benz/backstage-plugin-techdocs-addon-mermaid';

export const TechdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ExpandableNavigation />
      <TextSize />
      <ReportIssue />
      <Mermaid
        config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
      />
    </TechDocsAddons>
  </EntityTechdocsContent>
);
