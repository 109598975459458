import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { DwsApiClient, dwsApiRef } from './api';
import { rootRouteRef } from './routes';

export const dwsPlugin = createPlugin({
  id: 'dws',
  apis: [
    createApiFactory({
      api: dwsApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new DwsApiClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const DwsPage = dwsPlugin.provide(
  createRoutableExtension({
    name: 'DwsPage',
    component: () =>
      import('./components/Dashboard/Dashboard').then(m => m.Dashboard),
    mountPoint: rootRouteRef,
  }),
);
