import { createApiRef } from '@backstage/core-plugin-api';
import {
  BookingPermissionType,
  BookingsService,
  BookingServiceProperty,
  PlatformBookingsType,
} from '@mb.io/tdms-common';
import { actionPermissions } from '../permissions';
import { ApiClient } from './ApiClient';
import { API_VERSION } from './constants';

export const bookingsApiRef = createApiRef<BookingsService>({
  id: 'plugin.tdms-next.bookings-api',
});

export class BookingsApiClient extends ApiClient implements BookingsService {
  getPlatformBookings(acronym: string): Promise<PlatformBookingsType[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/bookings/${acronym}/details`,
      },
      actionPermissions.tenantBookings.read,
    );
  }

  getBookings(): Promise<PlatformBookingsType[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/bookings`,
      },
      actionPermissions.backOfficeBookings.read,
    );
  }

  addPlatformBooking(
    acronym: string,
    platformServicePackageId: number,
    body: { comment: string; endDate: string; startDate: string },
  ): Promise<PlatformBookingsType> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/bookings/${acronym}/${platformServicePackageId}`,
        body,
      },
      actionPermissions.backOfficeBookings.create,
    );
  }

  updatePlatformBooking(
    bookingId: number,
    body: { comment: string; endDate: string; startDate: string },
  ): Promise<PlatformBookingsType> {
    return this.submitRequest(
      {
        method: 'PUT',
        path: `/${API_VERSION}/bookings/${bookingId}`,
        body,
      },
      actionPermissions.backOfficeBookings.update,
    );
  }

  removePlatformBooking(bookingId: number): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'DELETE',
        path: `/${API_VERSION}/bookings/${bookingId}`,
        responseAsJson: false,
      },
      actionPermissions.backOfficeBookings.delete,
    );
  }

  getBookingPermissions(
    bookingId: number,
    serviceSlug: string,
  ): Promise<BookingPermissionType[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/platformBookingPermissions/${bookingId}/${serviceSlug}`,
      },
      actionPermissions.backOfficePermissions.read,
    );
  }

  getBookingProperties(
    _bookingId: number,
    _locationApi?: string,
  ): Promise<BookingServiceProperty[]> {
    throw new Error('Not implemented yet');
  }

  updatePlatformBookingPermission(
    tenantAcronym: string,
    body: { bookingPermissionIds: number[]; servicePermissionSlug: string },
  ): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'PUT',
        path: `/${API_VERSION}/platformBookingPermissions/${tenantAcronym}`,
        body,
      },
      actionPermissions.backOfficeServicePermissions.create,
    );
  }

  getBookingElevationStatus(
    bookingId: number,
    serviceSlug: string,
  ): Promise<Response> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/platformBookingPermissions/${bookingId}/${serviceSlug}/isElevatable`,
        responseAsJson: false,
      },
      actionPermissions.backOfficePermissions.read,
    );
  }
}
