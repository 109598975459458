import React, { cloneElement, Fragment, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { searchApiRef, useSearch } from '@backstage/plugin-search-react';
import useAsync from 'react-use/esm/useAsync';
import { makeStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import AllIcon from '@mui/icons-material/FontDownload';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

const useStyles = makeStyles()(theme => ({
  card: {
    backgroundColor: 'rgba(0, 0, 0, .11)',
  },
  cardContent: {
    paddingTop: theme.spacing(1),
  },
  icon: {
    color: theme.palette.text.primary,
  },
  list: {
    width: '100%',
  },
  listItemIcon: {
    width: '24px',
    height: '24px',
  },
  accordion: {
    backgroundColor: theme.palette.background.paper,
  },
  accordionSummary: {
    minHeight: 'auto',
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
  },
  accordionSummaryContent: {
    margin: theme.spacing(2, 0),
    '&.Mui-expanded': {
      margin: theme.spacing(2, 0),
    },
  },
  accordionDetails: {
    padding: theme.spacing(0, 0, 1),
  },
}));

/**
 * @public
 */
export type SearchTypeAccordionProps = {
  name: string;
  types: Array<{
    value: string;
    name: string;
    icon: JSX.Element;
  }>;
  defaultValue?: string;
  showCounts?: boolean;
};

function computeNrResults(numberOfResults?: number) {
  if (numberOfResults) {
    const results = numberOfResults >= 10000 ? `>10000` : numberOfResults;
    return `${results} results`;
  }
  return ' -- ';
}

export const SearchTypeAccordion = (props: SearchTypeAccordionProps) => {
  const { classes } = useStyles();
  const { filters, setPageCursor, setTypes, term, types } = useSearch();
  const searchApi = useApi(searchApiRef);
  const [expanded, setExpanded] = useState(true);
  const { defaultValue, name, showCounts, types: givenTypes } = props;

  const toggleExpanded = () => setExpanded(prevState => !prevState);
  const handleClick = (type: string) => {
    return () => {
      setTypes(type !== '' ? [type] : []);
      setPageCursor(undefined);
    };
  };

  // Handle any provided defaultValue
  useEffect(() => {
    if (defaultValue) {
      setTypes([defaultValue]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const definedTypes = [
    {
      value: '',
      name: 'All',
      icon: <AllIcon />,
    },
    ...givenTypes,
  ];
  const selected = types[0] || '';

  const { value: resultCounts } = useAsync(async () => {
    if (!showCounts) {
      return {};
    }

    const counts = await Promise.all(
      definedTypes
        .map(t => t.value)
        .map(async type => {
          const { numberOfResults } = await searchApi.query({
            term,
            types: type ? [type] : [],
            filters:
              types.includes(type) || (!types.length && !type) ? filters : {},
            pageLimit: 0,
          });

          return [type, computeNrResults(numberOfResults)];
        }),
    );

    return Object.fromEntries(counts);
  }, [filters, showCounts, term, types]);

  return (
    <Card className={classes.card}>
      <CardHeader title={name} titleTypographyProps={{ variant: 'overline' }} />
      <CardContent className={classes.cardContent}>
        <Accordion
          className={classes.accordion}
          expanded={expanded}
          onChange={toggleExpanded}
        >
          <AccordionSummary
            classes={{
              root: classes.accordionSummary,
              content: classes.accordionSummaryContent,
            }}
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
          >
            {expanded
              ? 'Collapse'
              : definedTypes.filter(t => t.value === selected)[0].name}
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetails }}>
            <List
              className={classes.list}
              component="nav"
              aria-label="filter by type"
              disablePadding
              dense
            >
              {definedTypes.map(type => (
                <Fragment key={type.value}>
                  <Divider />
                  <ListItemButton
                    selected={
                      types[0] === type.value ||
                      (types.length === 0 && type.value === '')
                    }
                    onClick={handleClick(type.value)}
                  >
                    <ListItemIcon>
                      {cloneElement(type.icon, {
                        className: classes.listItemIcon,
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={type.name}
                      secondary={resultCounts?.[type.value]}
                    />
                  </ListItemButton>
                </Fragment>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};
