import Typography from '@mui/material/Typography';
import Grid, { GridSize } from '@mui/material/Grid';
import { useElementFilter } from '@backstage/core-plugin-api';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { labelStyles } from '../shared-styles';

const useStyles = makeStyles<{ responsive: boolean }>()(
  (theme, { responsive }) => ({
    value: {
      fontWeight: 'bold',
      overflow: 'hidden',
      lineHeight: '24px',
      wordBreak: 'break-word',
      ...(responsive
        ? {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }
        : {}),
    },
    label: {
      ...labelStyles(theme),
    },
  }),
);

type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type GridSizes = {
  [K in Breakpoints]?: GridSize | boolean;
};

type Props = {
  label: string;
  defaultValue?: string;
  value?: string | number | boolean | React.JSX.Element;
  suffix?: string;
  className?: string;
  gridSizes?: GridSizes;
  children?: React.ReactNode;
  responsive?: boolean;
  valueAsFlag?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

export const OverviewField = ({
  label,
  defaultValue,
  value,
  suffix = '',
  gridSizes,
  children,
  className,
  responsive = false,
  valueAsFlag = false,
  ...otherProps
}: Props) => {
  const { classes } = useStyles({ responsive });

  const childElements = useElementFilter(children, c => c.getElements());

  const asFlag = valueAsFlag && typeof value === 'boolean';

  const valueContent =
    childElements.length > 0 ? (
      childElements
    ) : (
      <Typography variant="body2" className={classes.value}>
        {asFlag && (value ? 'Enabled' : 'Disabled')}
        {!asFlag && typeof value === 'object' ? value : `${value} ${suffix}`}
      </Typography>
    );

  const content =
    childElements.length > 0 || asFlag || value ? (
      valueContent
    ) : (
      <Typography variant="body2" className={classes.value}>
        {defaultValue || `unknown`}
      </Typography>
    );

  return (
    <Grid {...otherProps} item {...gridSizes} className={className}>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      {content}
    </Grid>
  );
};
