import { FieldValidation } from '@rjsf/utils';
import { JAVA_PACKAGE_NAME_REGEX } from './java-package.validation';

/**
 * GroupId uniquely identifies your project across all projects. A group ID should follow Java's package name rules. This means it starts with a reversed domain name you control.
 * eg: org.apache.maven, org.apache.commons
 * */
export const validateMavenGroupId = (
  value: string,
  validation: FieldValidation,
) => {
  if (!new RegExp(JAVA_PACKAGE_NAME_REGEX).test(value)) {
    validation.addError(
      `Invalid Maven groupId ${value}. Should follow Java's package name rules ${JAVA_PACKAGE_NAME_REGEX}. eg: org.apache.maven, org.apache.commons`,
    );
  }
};
