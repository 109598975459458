import {
  Content,
  ContentHeader,
  CreateButton,
  Page,
  SupportButton,
} from '@backstage/core-components';
import { default as React } from 'react';

type Props = {
  title: string;
  supportTitle?: string;
  themeId?: string;
  createTitle?: string;
  children?: React.ReactNode;
};

export const ContentPageLayout = ({
  title,
  supportTitle,
  createTitle,
  children,
  themeId,
}: Props) => {
  return (
    <Page themeId={themeId ?? 'home'}>
      <Content>
        <ContentHeader title={title}>
          {createTitle && <CreateButton title={createTitle} />}
          {supportTitle && <SupportButton>{supportTitle}</SupportButton>}
        </ContentHeader>
        {children}
      </Content>
    </Page>
  );
};
