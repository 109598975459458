import { Entity } from '@backstage/catalog-model';
import { Progress, WarningPanel } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { DefaultGrid, useCardClasses } from '@mb.io/core-components';
import { HARBOR_REPOSITORY_ANNOTATION } from '@mb.io/harbor-common';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import useAsync from 'react-use/esm/useAsync';
import { makeStyles } from 'tss-react/mui';
import { harborApiRef } from '../../api';
import {
  useHarborAnnotation,
  useRepositoryKey,
} from '../../hooks/useHarborAnnotation';
import { ArtifactOverviewCard } from '../ArtifactOverviewCard';
import { ArtifactsTable } from '../ArtifactsTable';
import { HarborContentContainer } from '../HarborContentContainer';
import { ScanOverviewCard } from '../ScanOverviewCard';
import { VulnerabilityScoreCard } from '../VulnerabilityScoreCard';

type Props = {
  title?: string;
  entity: Entity;
  variant: 'gridItem' | 'fullHeight';
};

const useStyles = makeStyles()(theme => ({
  card: {
    paddingBottom: theme.spacing(1),
  },
  projectInfo: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(1),
    },
  },
  vulnScore: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1),
    },
  },
}));

export const HarborContent = ({ title, entity, variant }: Props) => {
  const repositoryId = useHarborAnnotation(entity);
  const repositoryKey = useRepositoryKey(repositoryId);
  const harborApi = useApi(harborApiRef);
  const { classes } = useStyles();
  const up720 = useMediaQuery('(min-width:720px)');
  const { cardClass, cardContentClass } = useCardClasses(variant);

  const {
    value: artifact,
    loading,
    error,
  } = useAsync(() => {
    return harborApi.getLatestArtifact(repositoryId);
  }, [harborApi]);

  if (loading) {
    return (
      <HarborContentContainer title={title} repositoryKey={repositoryKey}>
        <Progress />
      </HarborContentContainer>
    );
  }

  if (error) {
    return (
      <HarborContentContainer title={title} repositoryKey={repositoryKey}>
        <WarningPanel
          severity="error"
          title={`Could not load latest artifact from repository ${repositoryKey}`}
        >
          {error.message}
        </WarningPanel>
      </HarborContentContainer>
    );
  }

  if (!artifact) {
    return (
      <HarborContentContainer title={title} repositoryKey={repositoryKey}>
        <WarningPanel
          severity="error"
          title={`Could not load latest artifact for repository ${repositoryKey}. Fix value of ${HARBOR_REPOSITORY_ANNOTATION} annotation.`}
        />
      </HarborContentContainer>
    );
  }

  return (
    <HarborContentContainer title={title} repositoryKey={repositoryKey}>
      <DefaultGrid variant="container">
        <DefaultGrid variant="itemContainer" xs={12}>
          <DefaultGrid
            className={`${classes.card} ${classes.projectInfo}`}
            variant="item"
            xs={12}
            md={6}
          >
            <ArtifactOverviewCard
              artifact={artifact}
              repositoryId={repositoryId}
              variant="gridItem"
            />
          </DefaultGrid>
          {up720 && (
            <DefaultGrid
              className={`${classes.card} ${classes.vulnScore}`}
              variant="item"
              xs={12}
              md={6}
            >
              <VulnerabilityScoreCard
                title="Vulnerability Score"
                artifact={artifact}
                repositoryId={repositoryId}
              />
            </DefaultGrid>
          )}
        </DefaultGrid>
        <DefaultGrid variant="item" xs={12}>
          <ScanOverviewCard
            scanOverview={artifact.scanOverview}
            variant="gridItem"
          />
        </DefaultGrid>
        <DefaultGrid variant="item" xs={12}>
          <Card className={cardClass}>
            <CardHeader
              title="Docker Images"
              action={
                <>
                  <Button
                    aria-label="All Images"
                    title="View All Images"
                    href={artifact.repoUrl}
                    target="_blank"
                  >
                    View All Images
                  </Button>
                </>
              }
            />
            <CardContent className={cardContentClass}>
              <ArtifactsTable {...repositoryId} />
            </CardContent>
          </Card>
        </DefaultGrid>
      </DefaultGrid>
    </HarborContentContainer>
  );
};
