import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import {
  PmasApiService,
  Endpoints,
  PossibleIntegrationTypes,
  DefaultResponse,
} from '@mercedes-benz/pmas-common';
import { IntegrationsApi } from './integrationsApi';
import { UrlGroupsApi } from './urlGroupsApi';
import { FingerpointerApi } from './fingerpointerApi';

export const pmasApiRef = createApiRef<PmasApiService>({
  id: 'plugin.pmas.api',
});

export class PmasApiClient implements PmasApiService {
  private readonly integrationsApi: IntegrationsApi;
  private readonly urlGroupsApi: UrlGroupsApi;
  private readonly fingerpointerApi: FingerpointerApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    const { discoveryApi, fetchApi = { fetch } } = options;
    this.integrationsApi = new IntegrationsApi(discoveryApi, fetchApi);
    this.urlGroupsApi = new UrlGroupsApi(discoveryApi, fetchApi);
    this.fingerpointerApi = new FingerpointerApi(discoveryApi, fetchApi);
  }

  getTenants() {
    return this.integrationsApi.getTenants();
  }

  getAllTenantOverview() {
    return this.integrationsApi.getAllTenantOverview();
  }

  getTenantIntegrations(name: string) {
    return this.integrationsApi.getTenantIntegrations(name);
  }

  enableTenantIntegrations(name: string, status: string) {
    return this.integrationsApi.enableTenantIntegrations(name, status);
  }

  getIntegration(integrationId: number, integrationType: Endpoints) {
    return this.integrationsApi.getIntegration(integrationId, integrationType);
  }

  getIntegrations(integrationType: Endpoints) {
    return this.integrationsApi.getIntegrations(integrationType);
  }

  getIntegrationsPerTenant<T = PossibleIntegrationTypes>(
    tenant: string,
    integrationType: Endpoints,
  ): Promise<DefaultResponse<T>> {
    return this.integrationsApi.getIntegrationsPerTenant(
      tenant,
      integrationType,
    );
  }

  getDatasources(integrationType: Endpoints) {
    return this.integrationsApi.getDatasources(integrationType);
  }

  createIntegration(integrationType: string, integration: object) {
    return this.integrationsApi.createIntegration(integrationType, integration);
  }

  updateIntegration(
    integrationType: string,
    integrationId: number,
    integration: object,
  ) {
    return this.integrationsApi.updateIntegration(
      integrationType,
      integrationId,
      integration,
    );
  }

  deleteIntegration(integrationId: number, integrationType: string) {
    return this.integrationsApi.deleteIntegration(
      integrationId,
      integrationType,
    );
  }

  getUrlGroups() {
    return this.urlGroupsApi.getUrlGroups();
  }

  createUrlGroup(urlGroupProps: object) {
    return this.urlGroupsApi.createUrlGroup(urlGroupProps);
  }

  updateUrlGroup(urlGroupId: number, urlGroupProps: object) {
    return this.urlGroupsApi.updateUrlGroup(urlGroupId, urlGroupProps);
  }

  deleteUrlGroup(urlGroupId: number) {
    return this.urlGroupsApi.deleteUrlGroup(urlGroupId);
  }

  addUrlsToUrlGroup(urlGroupId: number, urls: string) {
    return this.urlGroupsApi.addUrlsToUrlGroup(urlGroupId, urls);
  }

  deleteUrlsFromUrlGroup(urlGroupId: number, url: string) {
    return this.urlGroupsApi.deleteUrlsFromUrlGroup(urlGroupId, url);
  }

  getReportsOverview() {
    return this.fingerpointerApi.getReportsOverview();
  }

  getReportRun(reportRunId: number) {
    return this.fingerpointerApi.getReportRun(reportRunId);
  }

  getFindings(subjectBlockId: number, evaluationBlockId: number) {
    return this.fingerpointerApi.getFindings(subjectBlockId, evaluationBlockId);
  }

  downloadReport(runId: number, format: string, subjectBlocks: string) {
    return this.fingerpointerApi.downloadReport(runId, format, subjectBlocks);
  }
}
