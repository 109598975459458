/**
 * Entity annotation containing the name of the academy project
 *
 * @public
 */
export const ANNOTATION_ACADEMY_PROJECT = 'mercedes-benz.com/academy-project';
/**
 * Entity annotation containing the academy git repository url
 *
 * @public
 */
export const ANNOTATION_ACADEMY_REPO = 'mercedes-benz.com/academy-repository';

/**
 * Entity annotation containing the academy tutorial id
 *
 * @public
 */
export const ANNOTATION_ACADEMY_ID = 'mercedes-benz.com/academy-id';
