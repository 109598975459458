import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { Entity } from '@backstage/catalog-model';
import { Progress, WarningPanel } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useOwnerUsers } from '../../hooks';
import { useSlice } from '../../hooks/useSlice';
import { useThemedIconClasses } from '../../hooks/useThemedIconClasses';
import { OverviewField } from '../OverviewField';
import { TeamMemberContact } from '../TeamMemberContact';

type Props = {
  entity: Entity;
};

export const TeamMembersContact = ({ entity }: Props) => {
  const themedIconClasses = useThemedIconClasses();
  const { users, ownerRefs, loading, error } = useOwnerUsers(entity);
  const { data: visibleUsers, hasMore } = useSlice(users, 10);
  const catalogEntityRoute = useRouteRef(entityRouteRef);
  const navigate = useNavigate();

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <WarningPanel severity="error" title="Error loading team members.">
        {error.message}
      </WarningPanel>
    );
  }

  return (
    <Grid container>
      <OverviewField
        label="Team Members"
        defaultValue="No members available"
        gridSizes={{ xs: 12 }}
      >
        {visibleUsers.map(user => (
          <TeamMemberContact key={user.metadata.name} member={user} />
        ))}
        {hasMore && ownerRefs.length > 0 && (
          <Chip
            key="more-users"
            icon={<ExpandMoreIcon className={themedIconClasses} />}
            label="More"
            onClick={_ => navigate(catalogEntityRoute(ownerRefs[0]))}
          />
        )}
      </OverviewField>
    </Grid>
  );
};
