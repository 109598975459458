import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import {
  registerComponentRouteRef,
  rootRouteRef,
  scaffolderTaskRouteRef,
  scaffolderTemplatesRouteRef,
  selectedTemplateRouteRef,
  viewTechDocRouteRef,
} from './routes';
import {
  createScaffolderFieldExtension,
  scaffolderApiRef,
} from '@backstage/plugin-scaffolder-react';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import {
  MyGroupsPicker,
  MyGroupsPickerSchema,
} from './components/fields/MyGroupsPicker/MyGroupsPicker';
import {
  OwnedEntityPicker,
  OwnedEntityPickerSchema,
} from './components/fields/OwnedEntityPicker/OwnedEntityPicker';
import {
  EntityTagsPicker,
  EntityTagsPickerSchema,
} from './components/fields/EntityTagsPicker/EntityTagsPicker';
import {
  OwnerPicker,
  OwnerPickerSchema,
} from './components/fields/OwnerPicker/OwnerPicker';
import {
  RepoUrlPicker,
  RepoUrlPickerSchema,
} from './components/fields/RepoUrlPicker/RepoUrlPicker';
import { repoPickerValidation } from './components';
import {
  EntityNamePicker,
  EntityNamePickerSchema,
} from './components/fields/EntityNamePicker/EntityNamePicker';
import { entityNamePickerValidation } from './components/fields/EntityNamePicker';
import {
  EntityPicker,
  EntityPickerSchema,
} from './components/fields/EntityPicker/EntityPicker';
import { ScaffolderClient } from './api';

export const scaffolderPlugin = createPlugin({
  id: 'scaffolder',
  apis: [
    createApiFactory({
      api: scaffolderApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        scmIntegrationsApi: scmIntegrationsApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, scmIntegrationsApi, fetchApi, identityApi }) =>
        new ScaffolderClient({
          discoveryApi,
          scmIntegrationsApi,
          fetchApi,
          identityApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
    templates: scaffolderTemplatesRouteRef,
    selectedTemplate: selectedTemplateRouteRef,
    ongoingTask: scaffolderTaskRouteRef,
  },
  externalRoutes: {
    registerComponent: registerComponentRouteRef,
    viewTechDoc: viewTechDocRouteRef,
  },
});

/**
 * A field extension for selecting an Entity that exists in the Catalog.
 *
 * @public
 */
export const EntityPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: EntityPicker,
    name: 'EntityPicker',
    schema: EntityPickerSchema,
  }),
);

/**
 * The field extension for selecting a name for a new Entity in the Catalog.
 *
 * @public
 */
export const EntityNamePickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: EntityNamePicker,
    name: 'EntityNamePicker',
    validation: entityNamePickerValidation,
    schema: EntityNamePickerSchema,
  }),
);

/**
 * The field extension which provides the ability to select a RepositoryUrl.
 * Currently, this is an encoded URL that looks something like the following `github.com?repo=myRepoName&owner=backstage`.
 *
 * @public
 */
export const RepoUrlPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: RepoUrlPicker,
    name: 'RepoUrlPicker',
    validation: repoPickerValidation,
    schema: RepoUrlPickerSchema,
  }),
);

/**
 * A field extension for picking users and groups out of the Catalog.
 *
 * @public
 */
export const OwnerPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: OwnerPicker,
    name: 'OwnerPicker',
    schema: OwnerPickerSchema,
  }),
);

/**
 * A field extension for picking groups a user belongs to out of the catalog.
 *
 * @public
 */
export const MyGroupsPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: MyGroupsPicker,
    name: 'MyGroupsPicker',
    schema: MyGroupsPickerSchema,
  }),
);

/**
 * A field extension to show all the Entities that are owned by the current logged-in User for use in templates.
 *
 * @public
 */
export const OwnedEntityPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: OwnedEntityPicker,
    name: 'OwnedEntityPicker',
    schema: OwnedEntityPickerSchema,
  }),
);

/**
 * EntityTagsPickerFieldExtension
 * @public
 */
export const EntityTagsPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: EntityTagsPicker,
    name: 'EntityTagsPicker',
    schema: EntityTagsPickerSchema,
  }),
);

/**
 * @alpha
 * The Router and main entrypoint to the Alpha Scaffolder plugin.
 */
export const NextScaffolderPage = scaffolderPlugin.provide(
  createRoutableExtension({
    name: 'NextScaffolderPage',
    component: () =>
      import('./pages/ScaffolderIndex').then(m => m.ScaffolderIndex),
    mountPoint: rootRouteRef,
  }),
);
