import { EntityLink, RELATION_OWNED_BY } from '@backstage/catalog-model';
import { InfoCard } from '@backstage/core-components';
import { IconComponent, useApp } from '@backstage/core-plugin-api';
import { getEntityRelations, useEntity } from '@backstage/plugin-catalog-react';
import LanguageIcon from '@mui/icons-material/Language';
import React from 'react';
import { ColumnBreakpoints, useSupportEntity } from '../../hooks';
import { makeUniqueArray } from '../../utils';
import { EntityLinksEmptyState } from './EntityLinksEmptyState';
import { LinksGridList } from './LinksGridList';

export interface EnhancedEntityLinksCardProps {
  cols?: ColumnBreakpoints | number;
  variant?: 'gridItem';
  hideEmpty?: boolean;
}

export function EnhancedEntityLinksCard(
  props: Readonly<EnhancedEntityLinksCardProps>,
) {
  const { cols = undefined, variant, hideEmpty = false } = props;
  const { entity } = useEntity();
  const app = useApp();
  const owners = getEntityRelations(entity, RELATION_OWNED_BY);
  const { value: teamSupportEntity } = useSupportEntity(owners[0]);

  const teamLinks = teamSupportEntity?.metadata?.links ?? [];
  const entityLinks = entity?.metadata?.links ?? [];
  const links = makeUniqueArray<EntityLink>([...entityLinks, ...teamLinks]);

  const iconResolver = (key?: string): IconComponent =>
    key
      ? app.getSystemIcon(key) ?? (LanguageIcon as IconComponent)
      : (LanguageIcon as IconComponent);

  if ((!links || links.length === 0) && hideEmpty) {
    return <></>;
  }

  return (
    <InfoCard title="Links" variant={variant}>
      {!links || links.length === 0 ? (
        <EntityLinksEmptyState />
      ) : (
        <LinksGridList
          cols={cols}
          items={links.map(({ url, title, icon }) => ({
            text: title ?? url,
            href: url,
            Icon: iconResolver(icon),
          }))}
        />
      )}
    </InfoCard>
  );
}
