import { RELATION_DEPENDS_ON } from '@backstage/catalog-model';
import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import React from 'react';
import {
  asContentEntities,
  contentEntityColumns,
  contentEntityHelpLink,
} from '../content-constants';

type Props = {
  variant?: 'gridItem';
};

export const DependsOnContentsCard = ({ variant = 'gridItem' }: Props) => {
  return (
    <RelatedEntitiesCard
      variant={variant}
      title="Depends on contents"
      entityKind="Content"
      relationType={RELATION_DEPENDS_ON}
      columns={contentEntityColumns}
      asRenderableEntities={asContentEntities}
      emptyMessage="No content is a dependency of this component."
      emptyHelpLink={contentEntityHelpLink}
    />
  );
};
