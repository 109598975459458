import Grid from '@mui/material/Grid';
import React from 'react';
import { AnalyzeInfoCard } from './AnalyzeInfoCard';
import { AnalyzeStepper } from './AnalyzeStepper';

/**
 * The default catalog analyzer page.
 *
 * @public
 */
export const CatalogAnalyzer = () => {
  return (
    <Grid container spacing={2} direction="row-reverse">
      <Grid item xs={12} lg={6} className="grid-item">
        <AnalyzeInfoCard />
      </Grid>

      <Grid item xs={12} lg={6} className="grid-item">
        <AnalyzeStepper />
      </Grid>
    </Grid>
  );
};
