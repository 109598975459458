import { UserEntity } from '@backstage/catalog-model';
import { useUserGroups } from '@mb.io/core-components';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import {
  usePlatformFeatureFlagContext,
  useUserFeatureFlagContext,
} from '../../../context';
import { FeatureFlagListItem } from './FeatureFlagListItem';

type Props = {
  feature: string;
  user?: UserEntity;
};

export const UserFeatureFlagItem = ({ feature, user }: Props) => {
  const { features, update } = useUserFeatureFlagContext();
  const { findPlatformFeatureFlag } = usePlatformFeatureFlagContext();

  const userGroupNames = useUserGroups(user);

  const featureFlag = findPlatformFeatureFlag(feature);

  const toggleFeature = () => update(feature, !features[feature]);

  const getButton = (isForbidden: boolean | undefined) => {
    if (!isForbidden) {
      return {
        button: undefined,
        onClick: toggleFeature,
      };
    }

    return {};
  };

  let isForbidden: boolean | undefined = false;

  if (featureFlag?.allowedOwners?.length && !user) {
    isForbidden = true; // explicitly set forbidden to true if user is undefined (non-provider user)
  } else if (typeof featureFlag?.allowedOwners !== 'undefined') {
    isForbidden =
      userGroupNames.findIndex(groupName =>
        featureFlag.allowedOwners?.includes(groupName),
      ) === -1;
  }

  if (!isForbidden && featureFlag) {
    isForbidden =
      featureFlag.forbiddenOwners &&
      userGroupNames.findIndex(groupName =>
        featureFlag.forbiddenOwners?.includes(groupName),
      )! >= 0;
  }

  return (
    <ListItem
      divider
      {...getButton(isForbidden)}
      data-testid="user-feature-item"
    >
      <ListItemIcon>
        <Tooltip
          placement="top"
          arrow
          title={features[feature] ? 'Disable' : 'Enable'}
        >
          <Switch
            data-testid="user-feature-switch"
            color="primary"
            checked={features[feature] ?? false}
            name={feature}
          />
        </Tooltip>
      </ListItemIcon>
      <FeatureFlagListItem
        featureFlag={featureFlag}
        isForbidden={isForbidden}
      />
    </ListItem>
  );
};
