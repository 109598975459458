import { useCallback } from 'react';

function matches(node: any, selector: string): boolean {
  return (node as Element).matches?.(selector);
}

const ruleName = 'responsive-search-page-container';
const responsiveClassName = `${ruleName}-${Math.floor(Math.random() * 10000)}`;

function createResponsiveCssClass() {
  if (
    Array.from(document.head.querySelectorAll('style')).find(
      style => style.getAttribute('data-meta') === ruleName,
    )
  ) {
    return;
  }
  const style: HTMLStyleElement = document.createElement('style');
  style.textContent = `
    @media (max-width:800px) {
      .${responsiveClassName} {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
      }
    }`;
  style.setAttribute('data-meta', ruleName);
  document.head.appendChild(style);
}

// Callback function to execute when mutations are observed on the targetNode
async function onMutationCallback(
  mutations: MutationRecord[],
  _: MutationObserver,
) {
  for (const mutation of mutations) {
    // A child node has been added or removed
    if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
      handleMutation(mutation);
    }
  }
}

const registerObserver = (cb: MutationCallback) => {
  // node that will be observed for mutations
  const targetNode = document.querySelector('body');

  // which mutations to observe
  const config: MutationObserverInit = { childList: true, subtree: true };

  const observer: MutationObserver = new MutationObserver(cb);

  // Start observing the target node for configured mutations
  if (targetNode) observer.observe(targetNode, config);
};

function handleMutation(mutation: MutationRecord) {
  for (const node of Array.from(mutation.addedNodes)) {
    if (matches(node, 'main') && node.hasChildNodes()) {
      const searchPageTitle = findChildNodesMatchingSelector(node);

      if (searchPageTitle) {
        addResponsiveStyles();
      }
    }
  }
}

function addResponsiveStyles() {
  const searchContainerChildNodes = getSearchContainerChildNodes();

  const filtersContainer = findFiltersContainer(searchContainerChildNodes);

  const searchResultsContainer = findSearchResultsContainer(
    searchContainerChildNodes,
  );

  createResponsiveCssClassIfContainersExist(
    filtersContainer,
    searchResultsContainer,
  );

  addClassListAndStyle(filtersContainer, 'min-width', '178px');

  addClassListAndStyle(searchResultsContainer, 'flex', '1');
}

function findFiltersContainer(searchContainerChildNodes: ChildNode[]) {
  return searchContainerChildNodes.find(childNode =>
    matches(childNode, '.MuiGrid-grid-xs-3'),
  );
}

function findSearchResultsContainer(searchContainerChildNodes: ChildNode[]) {
  return searchContainerChildNodes.find(childNode =>
    matches(childNode, '.MuiGrid-grid-xs-9'),
  );
}

function addClassListAndStyle(
  container: ChildNode | undefined,
  property: string,
  value: string,
): void {
  if (container) {
    (container as Element).classList?.add(responsiveClassName);
    (container as HTMLElement).style?.setProperty(property, value);
  }
}

function createResponsiveCssClassIfContainersExist(
  filtersContainer: ChildNode | undefined,
  searchResultsContainer: ChildNode | undefined,
): void {
  if (filtersContainer || searchResultsContainer) {
    createResponsiveCssClass();
  }
}

function getSearchContainerChildNodes(): ChildNode[] {
  return Array.from(
    document.querySelector(
      'article[class*=BackstageContent-root] > div[class*=MuiGrid-container]',
    )?.childNodes ?? [],
  );
}

function findChildNodesMatchingSelector(node: Node): ChildNode | undefined {
  return Array.from(node.childNodes).find(
    childNode =>
      matches(childNode, 'header[class*=BackstageHeader-header]') &&
      childNode.textContent === 'Search',
  );
}

/**
 * Adds responsiveness to Search-Page as soon as it is loaded
 */
export function useSearchPageStyle() {
  const memoizedCb: MutationCallback = useCallback(onMutationCallback, []);
  registerObserver(memoizedCb);
}
