import Button from '@mui/material/Button';
import React, { ComponentProps } from 'react';
import { useStyles } from './buttons-style';

export const BackButton = (props: ComponentProps<typeof Button>) => {
  const { classes } = useStyles();

  return (
    <Button variant="outlined" className={classes.button} {...props}>
      {props.children || 'Back'}
    </Button>
  );
};
