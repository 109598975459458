import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { HARBOR_REPOSITORY_ANNOTATION } from '@mb.io/harbor-common';
import { rootRouteRef } from './routes';

import { Entity } from '@backstage/catalog-model';
import { HarborApiClient, harborApiRef } from './api';

export const isHarborAvailable = (entity: Entity) =>
  Boolean(entity?.metadata.annotations?.[HARBOR_REPOSITORY_ANNOTATION]);

export const harborPlugin = createPlugin({
  id: 'harbor',
  apis: [
    createApiFactory({
      api: harborApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new HarborApiClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const EntityHarborContent = harborPlugin.provide(
  createRoutableExtension({
    name: 'HarborContentPage',
    component: () =>
      import('./components/HarborContentPage').then(m => m.HarborContentPage),
    mountPoint: rootRouteRef,
  }),
);

export const HarborVulnerabilityScoreCard = harborPlugin.provide(
  createComponentExtension({
    name: 'HarborVulnerabilityScoreCard',
    component: {
      lazy: () => import('./components/').then(m => m.VulnerabilityScoreCard),
    },
  }),
);

export const HarborArtifactsTable = harborPlugin.provide(
  createComponentExtension({
    name: 'HarborArtifactsTable',
    component: {
      lazy: () =>
        import('./components/ArtifactsTable').then(m => m.ArtifactsTable),
    },
  }),
);
