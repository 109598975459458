import { ActionPayload } from '@mercedes-benz/permission-common';
import React, { ReactNode } from 'react';
import { useSystemRoleGuard } from '../hooks';

type BackstageSystemRoleGuardOptions = ActionPayload & {
  children: ReactNode;
  loadingComponent?: ReactNode;
};

export const BackstageSystemRoleGuard = (
  options: BackstageSystemRoleGuardOptions,
) => {
  const { allowed, loading } = useSystemRoleGuard({
    actionType: options.actionType,
    details: options.details,
    provider: options.provider,
  } as ActionPayload);

  if (loading && options.loadingComponent) {
    return options.loadingComponent;
  }

  if (!allowed) {
    return <></>;
  }

  return <>{options.children}</>;
};
