import { ANNOTATION_SOURCE_LOCATION } from '@backstage/catalog-model';
import {
  CodeSnippet,
  EmptyState,
  Progress,
  WarningPanel,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import React from 'react';

type GitHubGuardProps = {
  feature?: string;
  children: React.ReactElement;
};

export const GitHubGuard = ({ children, feature }: GitHubGuardProps) => {
  const { entity, loading, error } = useAsyncEntity();
  const scmIntegrations = useApi(scmIntegrationsApiRef);

  const githubIntegrations = scmIntegrations.github.list();

  if (githubIntegrations.length === 0) {
    return (
      <div>
        <WarningPanel
          severity="warning"
          title="No GitHub integration available. Please configure at least one GitHub instance."
        />
      </div>
    );
  }

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <div>
        <WarningPanel severity="error" title="Could not load entity.">
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }

  const sourceLocation =
    entity?.metadata.annotations?.[ANNOTATION_SOURCE_LOCATION];
  const allowed =
    sourceLocation &&
    sourceLocation.includes(scmIntegrations.github.list()[0].config.host);

  return (
    <>
      {allowed ? (
        children
      ) : (
        <EmptyState
          title={
            feature ? `Access Denied to GitHub ${feature}` : `Access Denied`
          }
          missing="data"
          description="Only components on git.i.mercedes-benz.com are allowed to show GitHub information. Please migrate your components to unlock this functionality."
        />
      )}
    </>
  );
};
