import { RELATION_HAS_PART } from '@backstage/catalog-model';
import { InfoCardVariants } from '@backstage/core-components';
import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import React from 'react';

import {
  asSeamlessEntities,
  seamlessEntityColumns,
  seamlessEntityHelpLink,
} from '../seamless-constants';

/** @public */
export interface HasSeamlessCardCardProps {
  variant?: InfoCardVariants;
}

export function HasSeamlessCard(props: HasSeamlessCardCardProps) {
  const { variant = 'gridItem' } = props;
  return (
    <RelatedEntitiesCard
      variant={variant}
      title="Has Seamless Components"
      entityKind="Seamless"
      relationType={RELATION_HAS_PART}
      columns={seamlessEntityColumns}
      asRenderableEntities={asSeamlessEntities}
      emptyMessage="No Seamless components is part of this system"
      emptyHelpLink={seamlessEntityHelpLink}
    />
  );
}
