/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useEffect } from 'react';

import { useShadowRootElements } from '@backstage/plugin-techdocs-react';
import mermaid, { MermaidConfig } from 'mermaid';
import { isMermaidCode } from './hooks';
import { MermaidProps } from './props';
import { PaletteMode } from '@mui/material/';
import { useTheme } from '@mui/material/styles';

export function selectConfig(
  backstagePalette: PaletteMode,
  properties: MermaidProps,
): MermaidConfig {
  // Theme set directly in the Mermaid configuration takes
  // precedence for backwards-compatibility
  if (properties.config) {
    return properties.config;
  }

  if (backstagePalette === 'light') {
    return properties.lightConfig || {};
  }
  return { ...properties.darkConfig, theme: 'dark' };
}

/**
 * Show report issue button when text is highlighted
 */

let diagramId = 0;

const makeDiagram = async (
  el: HTMLDivElement | HTMLPreElement,
  diagramText: string,
  backstagePalette: PaletteMode,
  properties: MermaidProps,
) => {
  el.style.display = 'none';

  const diagramElement = document.createElement('div');
  diagramElement.className = 'mermaid';

  el.parentNode?.insertBefore(diagramElement, el.nextSibling);

  const id = `mermaid-${diagramId++}`;

  const config = selectConfig(backstagePalette, properties);
  if (config) {
    mermaid.initialize(config);
  }

  const { svg, bindFunctions } = await mermaid.render(id, diagramText);
  diagramElement.innerHTML = svg;
  bindFunctions?.(diagramElement);
};

export const MermaidAddon = (properties: MermaidProps) => {
  const highlightTables = useShadowRootElements<HTMLDivElement>([
    '.highlighttable',
  ]);
  const highlightDivs = useShadowRootElements<HTMLDivElement>(['.highlight']);
  const mermaidPreBlocks = useShadowRootElements<HTMLPreElement>(['.mermaid']);
  const theme = useTheme();

  useEffect(() => {
    highlightTables.forEach(highlightTable => {
      if (!highlightTable.classList.contains('language-text')) {
        return;
      }

      // Skip already processed
      if (highlightTable.style.display === 'none') {
        return;
      }

      const codeBlock = highlightTable.querySelector('code');
      if (!codeBlock) {
        return;
      }

      const diagramText = codeBlock.textContent ?? '';

      // Ideally we could detect mermaid based on some annotation, but use a regex for now
      if (!isMermaidCode(diagramText)) {
        return;
      }

      makeDiagram(highlightTable, diagramText, theme.palette.mode, properties);
    });
  }, [highlightTables, properties, theme.palette.mode]);

  useEffect(() => {
    highlightDivs.forEach(highlightDiv => {
      if (!highlightDiv.classList.contains('language-text')) {
        return;
      }

      // Skip already processed
      if (highlightDiv.style.display === 'none') {
        return;
      }

      // skip mkdocs-material < 9 code blocks (handled above)
      const table = highlightDiv.querySelector('table');
      if (!table) {
        return;
      }

      const codeBlock = highlightDiv.querySelector('code');
      if (!codeBlock) {
        return;
      }

      const diagramText = codeBlock.textContent ?? '';

      // Ideally we could detect mermaid based on some annotation, but use a regex for now
      if (!isMermaidCode(diagramText)) {
        return;
      }

      makeDiagram(highlightDiv, diagramText, theme.palette.mode, properties);
    });
  }, [highlightDivs, properties, theme.palette.mode]);

  useEffect(() => {
    mermaidPreBlocks.forEach(mermaidPreBlock => {
      // Skip already processed
      if (mermaidPreBlock.style.display === 'none') {
        return;
      }

      const codeBlock = mermaidPreBlock.querySelector('code');
      if (!codeBlock) {
        return;
      }

      const diagramText = codeBlock.textContent ?? '';

      makeDiagram(mermaidPreBlock, diagramText, theme.palette.mode, properties);
    });
  }, [mermaidPreBlocks, properties, theme.palette.mode]);

  return null;
};
