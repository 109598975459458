import { CompoundEntityRef } from '@backstage/catalog-model';
import { HeaderIconLinkRow, Progress } from '@backstage/core-components';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { ContentType } from '@mb.io/catalog-model';
import React from 'react';
import { useSupportEntity } from '../../hooks';
import { isValidUrl } from '../../utils';
import { DEFAULT_KEY, DOCS_KEY, channelsDb } from './channelsDb';

const CHANNELS = channelsDb();
export const KEYS = Object.keys(CHANNELS);
const sortLongestFirst = (keys: string[]): string[] => {
  const sortedKeys = [...keys];
  sortedKeys.sort((pre, post) => post.length - pre.length);
  return sortedKeys;
};

const KEYS_REGEX = new RegExp(
  `(?:https?:\\/\\/(?:[a-z0-9-]*.)?)?(${sortLongestFirst(KEYS).join('|')}).*`,
);

export function keyFor(url: string) {
  if (!url) return DEFAULT_KEY;
  const key = url.replace(KEYS_REGEX, '$1');
  if (isValidUrl(key)) {
    return url.includes('doc') ? DOCS_KEY : DEFAULT_KEY;
  }
  return key;
}

function buildChannelLinks(channelUrls: string[]) {
  return channelUrls
    .filter(channelUrl => isValidUrl(channelUrl))
    .map(channelUrl => {
      const channel = CHANNELS[keyFor(channelUrl)];
      channel.href = channelUrl;
      return channel;
    });
}

type Props = {
  owner: CompoundEntityRef;
  originUrl?: string;
};

function createOrgUrl(originUrl: string, owner: CompoundEntityRef): string {
  const url = new URL(originUrl);
  return originUrl.includes('dh-io')
    ? `${url.protocol}//${url.hostname}/dh-io-${owner.name}`
    : `${url.protocol}//${url.hostname}/${owner.name}`;
}

export const SupportChannels = ({ owner, originUrl }: Props) => {
  const alertApi = useApi(alertApiRef);

  const { value: supportContentEntity, loading } = useSupportEntity(owner);

  if (loading) {
    return <Progress />;
  }
  const channelUrls = originUrl ? [createOrgUrl(originUrl, owner)] : [];

  // Only allows Json content
  if (
    supportContentEntity &&
    supportContentEntity.spec.type === ContentType.JSON &&
    supportContentEntity.spec.data
  ) {
    try {
      channelUrls.push(
        ...(JSON.parse(supportContentEntity.spec.data) as string[]),
      );
    } catch (parsingException) {
      const message = `Malformed content for support resource '${owner.name}-support' owned by ${owner.name}.`;
      alertApi.post({
        message: message,
        severity: 'error',
      });
    }
  }

  const channelLinks = buildChannelLinks(channelUrls);
  return <HeaderIconLinkRow links={channelLinks} />;
};
