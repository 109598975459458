import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import React from 'react';
import { PlatformFeatureFlag } from '../../../types/platform-feature.flag';
import { FeatureFlagListItem } from './FeatureFlagListItem';

type Props = {
  featureFlag: PlatformFeatureFlag;
};

export const PlatformFeatureFlagItem = ({ featureFlag }: Props) => (
  <ListItem divider onClick={() => {}} data-testid="platform-feature-item">
    <FeatureFlagListItem featureFlag={featureFlag} />
    <Chip
      label={featureFlag.available ? 'Enabled' : 'Disabled'}
      color={featureFlag.available ? 'default' : 'secondary'}
    />
  </ListItem>
);
