import { fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { ActionPayload, encodePayload } from '@mercedes-benz/permission-common';

export const useRolePermissionFetch = (
  url: string,
  requestInit: RequestInit,
  ref: ActionPayload,
) => {
  const { fetch } = useApi(fetchApiRef);

  const headers: Record<string, unknown> = (requestInit.headers as never) || {};
  headers['role-permission'] = encodePayload(ref);

  requestInit.headers = headers as never;

  return fetch(url, requestInit);
};
