import { z } from 'zod';
import {
  AccountOverviewSchema,
  BdDetailsSchema,
  BlackDuckSchema,
  ClusterSchema,
  ClusterTabSchema,
  DetailsSchema,
  HarborDetailsSchema,
  HarborSchema,
  InfraSecurityStageSchema,
  InfrastructureSecuritySchema,
  IsoViewStageSchema,
  OverviewSchema,
  PhaseSchema,
  PolicyViolationWithToolRefSchema,
  ProductOwnerSchema,
  ProductSchema,
  SecHubDetailsSchema,
  SecHubSchema,
  SecurityHubSchema,
  SonarDetailsSchema,
  SonarSchema,
  StageSchema,
  TeamSchema,
  ToolReferenceSchema,
  ToolReferenceWithoutHrefSchema,
} from './schemas';

export type Team = z.infer<typeof TeamSchema>;
export type ProductOwner = z.infer<typeof ProductOwnerSchema>;

export type BlackDuck = z.infer<typeof BlackDuckSchema>;
export type Sonar = z.infer<typeof SonarSchema>;
export type Harbor = z.infer<typeof HarborSchema>;
export type SecHub = z.infer<typeof SecHubSchema>;
export type SecurityHub = z.infer<typeof SecurityHubSchema>;

export type Phase = z.infer<typeof PhaseSchema>;

export type Stage = z.infer<typeof StageSchema>;
export const StageWithLabel: Record<Stage, string> = {
  prod: 'Production',
  qa: 'Quality Assurance',
  dev: 'Development',
};

export type InfraSecurityStage = z.infer<typeof InfraSecurityStageSchema>;
export const InfraSecurityStageWithLabel: Record<InfraSecurityStage, string> = {
  nonProdAccounts: 'Non Production',
  prodAccounts: 'Production',
};

export type IsoViewStage = z.infer<typeof IsoViewStageSchema>;
export const IsoViewStageWithLabel: Record<IsoViewStage, string> = {
  prod: 'Production',
  qa: 'Quality Assurance',
  dev: 'Development',
};

export type ClusterTab = z.infer<typeof ClusterTabSchema>;

export const ClusterTabWithLabel: Record<ClusterTab, string> = {
  InfraSecurityTab: 'Infra Security',
  ApplicationSecurityTab: 'App Security',
  IsoViewTab: 'ISO View',
};

export type ToolReference = z.infer<typeof ToolReferenceSchema>;
export type ToolReferenceWithoutHref = z.infer<
  typeof ToolReferenceWithoutHrefSchema
>;
export type PolicyViolationWithToolRef = z.infer<
  typeof PolicyViolationWithToolRefSchema
>;

export type BdDetails = z.infer<typeof BdDetailsSchema>;
export type HarborDetails = z.infer<typeof HarborDetailsSchema>;
export type SonarDetails = z.infer<typeof SonarDetailsSchema>;
export type SecHubDetails = z.infer<typeof SecHubDetailsSchema>;

export type Details = z.infer<typeof DetailsSchema>;
export type Overview = z.infer<typeof OverviewSchema>;

export type AccountOverview = z.infer<typeof AccountOverviewSchema>;
export type InfrastructureSecurity = z.infer<
  typeof InfrastructureSecuritySchema
>;
export type Product = z.infer<typeof ProductSchema>;
export type Cluster = z.infer<typeof ClusterSchema>;
export type IsoView = z.infer<typeof ProductSchema>;

export function isValidPhase(x: unknown): x is Phase {
  const { success } = PhaseSchema.safeParse(x);
  return success;
}
