import { pageTheme } from '@backstage/theme';
import { isCustom } from '../conditions';

export const getHeaderBackground = (type: string, parentType?: string) => {
  if (isCustom(type)) {
    switch (type) {
      case 'tsc-node':
        return pageTheme.service.backgroundImage;
      case 'tsc-web':
        return pageTheme.website.backgroundImage;
      case 'vue-library':
        return pageTheme.library.backgroundImage;
      case 'catalog':
        return pageTheme.home.backgroundImage;
      case 'ci-cd':
        return pageTheme.other.backgroundImage;
      default:
        return parentType
          ? `url(/headers/${parentType}.svg)`
          : pageTheme.other.backgroundImage;
    }
  } else {
    return pageTheme[type]
      ? pageTheme[type].backgroundImage
      : pageTheme.other.backgroundImage;
  }
};
