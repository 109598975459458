import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const platformFeatureFlagPlugin = createPlugin({
  id: 'platform-feature-flag',
  routes: {
    root: rootRouteRef,
  },
});

export const PlatformFeatureFlagPage = platformFeatureFlagPlugin.provide(
  createRoutableExtension({
    name: 'PlatformFeatureFlagPage',
    component: () =>
      import('./components/page/PlatformFeatureFlagPage').then(
        m => m.PlatformFeatureFlagPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
