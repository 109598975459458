import { Progress, WarningPanel } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  Artifact,
  HARBOR_REPOSITORY_ANNOTATION,
  RepositoryId,
  ScanOverview,
} from '@mb.io/harbor-common';
import { black, green, red, white } from '@mercedes-benz/mui5-theme';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import DataTable from 'react-data-table-component';
import useAsync from 'react-use/esm/useAsync';
import { harborApiRef } from '../../api';
import { useRepositoryKey } from '../../hooks/useHarborAnnotation';
import { TableColumn } from 'react-data-table-component';

function tableCustomStyles(themeType: 'light' | 'dark') {
  return {
    headCells: {
      style: {
        fontWeight: 'bold',
      },
    },
    ...(themeType === 'dark' && {
      rows: {
        highlightOnHoverStyle: {
          '&:nth-of-type(odd)': {
            color: white,
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
          },
          '&:nth-of-type(even)': {
            color: white,
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
          },
        },
      },
    }),
  };
}

function getTableColum(themeType: 'light' | 'dark'): TableColumn<Artifact>[] {
  return [
    {
      name: 'Tag name',
      selector: () => 'tag',
    },
    {
      name: 'Size MB',
      selector: a => a.size,
    },
    {
      name: 'Vulnerabilities severity',
      selector: a => a.scanOverview?.severity ?? 'None',
      style: {
        fontWeight: 'bold',
      },

      conditionalCellStyles: [
        {
          when: (row: { scanOverview: ScanOverview }) =>
            row.scanOverview.severity === 'None',
          style: {
            backgroundColor: green[55],
            color: white,
          },
        },
        {
          when: (row: { scanOverview: ScanOverview }) =>
            row.scanOverview.severity === 'Low',
          style: {
            backgroundColor: themeType === 'light' ? red[85] : red[70],
            color: black,
          },
        },
        {
          when: (row: { scanOverview: ScanOverview }) =>
            row.scanOverview.severity === 'Medium',
          style: {
            backgroundColor: themeType === 'light' ? red[60] : red[55],
            color: black,
          },
        },
        {
          when: (row: { scanOverview: ScanOverview }) =>
            row.scanOverview.severity === 'High',
          style: {
            backgroundColor: red[45],
            color: white,
          },
        },
        {
          when: (row: { scanOverview: ScanOverview }) =>
            row.scanOverview.severity === 'Critical',
          style: {
            backgroundColor: red[35],
            color: white,
          },
        },
      ],
    },
    {
      name: 'Total Vulnerabilities',
      selector: (item: Artifact) => item?.scanOverview?.summary?.total,
    },
    {
      name: 'Push Time',
      selector: a => a.pushTime,
    },
    {
      name: 'Pull Time',
      selector: a => a.pullTime,
    },
  ];
}

export const ArtifactsTable = (repositoryId: RepositoryId) => {
  const theme = useTheme();
  const repositoryKey = useRepositoryKey(repositoryId);
  const mode = theme.palette.mode === 'dark' ? 'dark' : 'light';

  const harborApi = useApi(harborApiRef);
  const {
    value: artifacts,
    loading,
    error,
  } = useAsync(() => {
    return harborApi.getArtifacts(repositoryId);
  }, [harborApi]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <WarningPanel
        severity="error"
        title={`Could not load artifacts for repository ${repositoryKey}`}
      >
        {error.message}
      </WarningPanel>
    );
  }

  if (!artifacts) {
    return (
      <WarningPanel
        severity="error"
        title={`Could not load artifacts for repository ${repositoryKey}. Fix value of ${HARBOR_REPOSITORY_ANNOTATION} annotation.`}
      />
    );
  }

  return (
    <DataTable
      theme={mode}
      noHeader
      striped
      columns={getTableColum(mode)}
      data={artifacts}
      defaultSortFieldId="pushTime"
      defaultSortAsc={false}
      pointerOnHover
      highlightOnHover
      onRowClicked={artifact =>
        window.open(
          `${artifact.repoUrl}/artifacts-tab/artifacts/${artifact.digest}`,
        )
      }
      customStyles={tableCustomStyles(mode) as never}
    />
  );
};
