import Grid from '@mui/material/Grid';
import React from 'react';
import { AnalyzeInfoCard } from './AnalyzeInfoCard';
import { AnalyzeStepper } from './AnalyzeStepper';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Content,
  ContentHeader,
  Header,
  Page,
  SupportButton,
} from '@backstage/core-components';

/**
 * The default catalog analyzer page.
 *
 * @public
 */
export const CatalogAnalyzerPage = () => {
  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptionalString('app.title') || 'Backstage';

  return (
    <Page themeId="home">
      <Header title="Analyze a catalog file" />
      <Content>
        <ContentHeader title={`Start analyzing your component in ${appTitle}`}>
          <SupportButton>
            Start analyzing your component in {appTitle} before tracking/adding
            it to your repository. Backstage will discover the catalog files
            automatically whenever you install our app and push a catalog-*.yaml
            file to your repository.
          </SupportButton>
        </ContentHeader>

        <Grid container spacing={2} direction="row-reverse">
          <Grid item xs={12} md={6} lg={6} xl={8} className="grid-item">
            <AnalyzeInfoCard />
          </Grid>

          <Grid item xs={12} md={8} lg={6} xl={4} className="grid-item">
            <AnalyzeStepper />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
