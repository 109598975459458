import { FieldValidation } from '@rjsf/utils';

export const JAVA_PACKAGE_NAME_REGEX = /^[a-z]+(\.[a-zA-Z_][a-zA-Z0-9_]*)*$/gi;

export const validateJavaPackageName = (
  value: string,
  validation: FieldValidation,
) => {
  if (!new RegExp(JAVA_PACKAGE_NAME_REGEX).test(value)) {
    validation.addError(
      `Invalid Java package name ${value}. Should follow Java's package name rules ${JAVA_PACKAGE_NAME_REGEX}. eg: org.apache.maven, org.apache.commons`,
    );
  }
};
