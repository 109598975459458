import Chip from '@mui/material/Chip';
import { UserEntity } from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import UserIcon from '@mui/icons-material/Person';
import POIcon from '@mui/icons-material/SupervisorAccount';
import ISAIcon from '@mui/icons-material/VerifiedUser';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useThemedIconClasses } from '../../hooks/useThemedIconClasses';
import { entityRefToQueryParams } from '../../utils';

const getRoles = (member: UserEntity) =>
  member.metadata.role ? (member.metadata.role as string[]) : [];

const hasRole = (member: UserEntity, role: string) =>
  getRoles(member).includes(role);

const hasOneOfRoles = (member: UserEntity, roles: string[]) =>
  roles.find(role => hasRole(member, role)) !== undefined;

const getIcon = (member: UserEntity, className?: string) => {
  if (hasOneOfRoles(member, ['PO', 'PM'])) return <POIcon />;
  if (hasRole(member, 'ISA')) return <ISAIcon />;
  return <UserIcon className={className} />;
};

const getNameSuffix = (member: UserEntity) => {
  if (hasRole(member, 'PM')) return `(PM)`;
  if (hasRole(member, 'PO')) return `(PO)`;
  if (hasRole(member, 'ISA')) return `(ISA)`;
  return '';
};

type Props = {
  member: UserEntity;
};

export const TeamMemberContact = ({ member }: Props) => {
  const themedIconClasses = useThemedIconClasses();
  const catalogEntityRoute = useRouteRef(entityRouteRef);
  const navigate = useNavigate();

  const path = catalogEntityRoute(entityRefToQueryParams(member));
  const displayName = member.spec.profile?.displayName ?? member.metadata.name;
  const fullName = `${displayName} ${getNameSuffix(member)}`;

  return (
    <Chip
      icon={getIcon(member, themedIconClasses)}
      label={fullName}
      variant="outlined"
      onClick={_ => navigate(path)}
    />
  );
};
