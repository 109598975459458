import { FieldProps } from '@rjsf/utils';
import React from 'react';
import { MuiMarkdown } from 'mui-markdown';
import { CustomFieldExtensionSchema } from '@backstage/plugin-scaffolder-react';

// See also: https://backstage.io/docs/features/software-templates/writing-custom-field-extensions
/**
 * This is the actual component that will get rendered in the form
 */
export const CustomMarkdownTextExtension = ({
  formData,
}: FieldProps<string>) => {
  return <MuiMarkdown>{formData ?? ''}</MuiMarkdown>;
};

export const CustomMarkdownTextExtensionSchema: CustomFieldExtensionSchema = {
  returnValue: { type: 'string' },
};
