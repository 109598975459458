import {
  CardTab,
  EmptyState,
  StructuredMetadataTable,
  TabbedCard,
} from '@backstage/core-components';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import React from 'react';
import { ManifestProps } from '../AemManifestPage';

export const ComponentConfigCard = ({ manifestFile }: ManifestProps) => {
  const dialogs = manifestFile?.aemComponentConfig.dialog?.map(dialog => {
    const cloned = { ...dialog };
    if (!cloned.elements?.length) {
      delete cloned.elements;
      return cloned;
    }
    return {
      ...cloned,
      elements: cloned.elements?.map((element, index) => (
        <StructuredMetadataTable
          dense
          key={`element-${index}`}
          metadata={element}
        />
      )),
    };
  });

  return (
    <TabbedCard title="AEM Configurations">
      <CardTab label="Dialog Configuration">
        <Grid container spacing={4} alignItems="stretch">
          {dialogs?.map((dialog, index) => (
            <Grid
              item
              sm={12}
              md={6}
              xl={4}
              className="grid-item"
              key={`dialog-${index}`}
            >
              <Paper variant="outlined">
                <StructuredMetadataTable dense metadata={dialog} />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </CardTab>
      <CardTab label="Cropping Configuration">
        {!manifestFile?.aemComponentConfig.croppingConfig && (
          <EmptyState
            title="Cropping configuration does not exist for the component"
            missing="data"
          />
        )}
        {manifestFile?.aemComponentConfig.croppingConfig &&
          manifestFile.aemComponentConfig.croppingConfig.map(
            (config, index) => (
              <Grid
                item
                sm={12}
                md={6}
                xl={4}
                className="grid-item"
                key={`config-${index}`}
              >
                <Paper variant="outlined">
                  <StructuredMetadataTable dense metadata={config} />
                </Paper>
              </Grid>
            ),
          )}
      </CardTab>
      <CardTab label="Edit Configuration">
        {!manifestFile?.aemComponentConfig.editConfig && (
          <EmptyState
            title="Edit configuration does not exist for the component"
            missing="data"
          />
        )}
        {manifestFile?.aemComponentConfig.editConfig && (
          <Grid item sm={12} md={6} xl={4} className="grid-item">
            <Paper variant="outlined">
              <StructuredMetadataTable
                dense
                metadata={manifestFile?.aemComponentConfig.editConfig}
              />
            </Paper>
          </Grid>
        )}
      </CardTab>
      <CardTab label="Container Configuration">
        {!manifestFile?.aemComponentConfig.containerConfig && (
          <EmptyState
            title="Container configuration does not exist for the component"
            missing="data"
          />
        )}
        {manifestFile?.aemComponentConfig.containerConfig && (
          <Grid item sm={12} md={6} xl={4} className="grid-item">
            <Paper variant="outlined">
              <StructuredMetadataTable
                dense
                metadata={manifestFile?.aemComponentConfig.containerConfig}
              />
            </Paper>
          </Grid>
        )}
      </CardTab>
    </TabbedCard>
  );
};
