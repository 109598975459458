import { JSONSchema6 } from 'json-schema';
import Schema from './content.alpha.schema.json';

export { ContentEntityDataValidator } from './content-entity-data.validator';
export { ContentEntityPolicy } from './content-entity.policy';
export { ContentType } from './content.alpha.entity';
export type { ContentAlphaEntity as ContentEntity } from './content.alpha.entity';
export const ContentEntityAlphaSchema: JSONSchema6 = Schema as Omit<
  JSONSchema6,
  'examples'
>;
