import Grid from '@mui/material/Grid';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityFeedbackResponseContent } from '@backstage-community/plugin-entity-feedback';
import {
  isContainer,
  isPackage,
  isSeamlessComponent,
  isSeamlessType,
  not,
  or,
} from '@mb.io/core-components';
import { BLACKDUCK_BACKEND_BASE_URL } from '@mercedes-benz/blackduck-common';
import { EntityBlackDuckContent } from '@mercedes-benz/plugin-blackduck';
import {
  AemManifestPage,
  ComponentPreviewPage,
} from '@mercedes-benz/plugin-seamless';
import { EntitySonarqubeContent } from '@mercedes-benz/plugin-sonarqube-wrapper';
import React from 'react';
import { SeamlessContainsCard } from '../../seamless/SeamlessContainsCard';
import { SeamlessHasPackagesCard } from '../../seamless/SeamlessHasPackagesCard';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';
import { CICDContent } from '../generic/CICDContent';
import { dependenciesContent } from '../generic/DependenciesContent';
import { ComponentOverviewContent } from '../generic/OverviewContent';
import {
  ProtectedDependabotContentPage,
  ProtectedGithubInsightsContentPage,
  ProtectedGithubPullRequestsContentPage,
} from '../generic/ProtectedContent';
import { TechdocsContent } from '../generic/TechdocsContent';

export const SeamlessPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {ComponentOverviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/preview"
      title="Preview"
      if={or(isSeamlessComponent, isPackage)}
    >
      <ComponentPreviewPage />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/aem-manifest-config"
      title="AEM Configuration"
      if={or(isSeamlessComponent, isPackage)}
    >
      <AemManifestPage />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/packages"
      title="Packages"
      if={isSeamlessType('mono-repo')}
    >
      <SeamlessHasPackagesCard />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/components"
      title="Sub Components"
      if={isPackage}
    >
      <SeamlessContainsCard />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/ci-cd"
      title="CI/CD"
      if={isSeamlessType('mono-repo')}
    >
      {CICDContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/sonarqube"
      title="SonarQube"
      if={isSeamlessType('mono-repo')}
    >
      <EntitySonarqubeContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      path={BLACKDUCK_BACKEND_BASE_URL}
      title="BlackDuck"
      if={isSeamlessType('mono-repo')}
    >
      <EntityBlackDuckContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/dependencies"
      title="Dependencies"
      if={not(isContainer)}
    >
      {dependenciesContent({
        canConsumeApis: true,
        dependsOnContent: false,
        dependsOnSeamless: true,
      })}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API" if={isSeamlessType('tsc-node')}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12} xl={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={12} xl={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {TechdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/pull-requests"
      title="Pull Requests"
      if={isSeamlessType('mono-repo')}
    >
      <ProtectedGithubPullRequestsContentPage />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/code-insights"
      title="Code Insights"
      if={isSeamlessType('mono-repo')}
    >
      <ProtectedGithubInsightsContentPage />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/dependabot"
      title="Dependabot"
      if={isSeamlessType('mono-repo')}
    >
      <ProtectedDependabotContentPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
