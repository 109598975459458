/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */
import { EntityLayout } from '@backstage/plugin-catalog';
import React from 'react';
import { EntityLayoutWrapper } from '../EntityLayoutWrapper';
import { GenericOverviewContent } from '../generic/OverviewContent';
import { TechdocsContent } from '../generic/TechdocsContent';

export const DefaultEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {GenericOverviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {TechdocsContent}
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
