import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useTemplates } from '../../hooks/useTemplates';
import { Link, Progress, WarningPanel } from '@backstage/core-components';
import Typography from '@mui/material/Typography';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import CloseIcon from '@mui/icons-material/Close';
import { ScaffolderCategoryIcon } from '../../components/icons';
import { parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { useNavigate } from 'react-router-dom';
import { selectedTemplateRouteRef } from '../../routes';
import { useRouteRef } from '@backstage/core-plugin-api';
import Backdrop from '@mui/material/Backdrop';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  close: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  backdrop: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgb(255 255 255 / 85%)'
        : 'rgb(66 66 66 / 85%)',
  },
}));

export function TemplateSearchDialog(props: {
  open: boolean;
  onClose: () => void;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<TemplateEntityV1beta3[]>(
    [],
  );
  const { loading, error, entities } = useTemplates();
  const navigate = useNavigate();
  const templateRoute = useRouteRef(selectedTemplateRouteRef);

  const { classes } = useStyles();

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <WarningPanel severity="error" title="Could not load software templates.">
        {error.message}
      </WarningPanel>
    );
  }

  if (!entities || !entities.length) {
    return (
      <Typography variant="body2">
        No templates found. Learn more about{' '}
        <Link to="https://backstage.io/docs/features/software-templates/adding-templates">
          adding templates
        </Link>
        .
      </Typography>
    );
  }

  const handleSearch = () => {
    const filteredResults = entities.filter(item => {
      const { name, title, tags, description } = item.metadata;
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      // Check if any of the properties match the search term
      return (
        lowerCaseSearchTerm.trim().length === 0 ||
        (tags &&
          tags.find(tag => tag.toLowerCase().includes(lowerCaseSearchTerm))) ||
        name.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.spec.type.toLowerCase().includes(lowerCaseSearchTerm) ||
        (description &&
          description.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (title && title.toLowerCase().includes(lowerCaseSearchTerm))
      );
    });

    setSearchResults(filteredResults);
  };

  const handleClear = () => {
    setSearchTerm('');
    handleSearch();
  };

  const handleClose = () => {
    setSearchTerm('');
    setSearchResults([]);
    props.onClose();
  };

  const handleSelect = (template: TemplateEntityV1beta3) => {
    handleClose();
    const { namespace, name } = parseEntityRef(stringifyEntityRef(template));
    navigate(templateRoute({ namespace, templateName: name }));
  };

  const startAdornment = (
    <InputAdornment position="start">
      <IconButton aria-label="Query" size="small" disabled>
        <SearchIcon />
      </IconButton>
    </InputAdornment>
  );

  const clearButtonEndAdornment = (
    <InputAdornment position="end">
      <Button aria-label="Clear" size="small" onClick={handleClear}>
        Clear
      </Button>
    </InputAdornment>
  );

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={props.open}
    >
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.open}
        onClose={handleClose}
        aria-labelledby="entity-inspector-dialog-title"
        slotProps={{
          backdrop: {
            classes: {
              root: classes.backdrop,
            },
          },
        }}
      >
        <DialogTitle>Create Something</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.close}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            id="search-bar-text-field"
            data-testid="search-bar-next"
            variant="outlined"
            margin="normal"
            value={searchTerm}
            placeholder="What do you want to build?"
            InputProps={{
              startAdornment,
              endAdornment: clearButtonEndAdornment,
              autoComplete: 'off',
            }}
            fullWidth
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
            onChange={e => {
              setSearchTerm(e.target.value);
              handleSearch();
            }}
          />
          <List>
            {searchResults.map((template: TemplateEntityV1beta3) => (
              <ListItemButton onClick={_ => handleSelect(template)}>
                <ListItemAvatar>
                  <ScaffolderCategoryIcon category={template.spec.type} />
                </ListItemAvatar>
                <ListItemText
                  primary={template.metadata.name}
                  secondary={template.metadata.description}
                />
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
