import { RepositoryId } from '@mb.io/harbor-common';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { ScanButton } from '../ScanButton';

const useStyles = makeStyles({ name: 'EmptyScan' })(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    margin: 20,
  },
  action: {
    marginTop: theme.spacing(2),
  },
  item: {
    padding: '0 !important',
  },
}));

type Props = {
  title: string;
  description?: string | React.JSX.Element;
  repositoryId: RepositoryId;
  artifactSha256: string;
};

export function EmptyScan(props: Props) {
  const { title, description, repositoryId, artifactSha256 } = props;
  const { classes } = useStyles();

  return (
    <Grid item xs={12} classes={{ root: classes.root }}>
      <Grid container direction="column">
        <Grid item xs classes={{ root: classes.item }}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid item xs classes={{ root: classes.item }}>
          <Typography variant="body1">{description}</Typography>
        </Grid>
        <Grid
          item
          xs
          classes={{ root: classes.item }}
          className={classes.action}
        >
          <ScanButton
            artifactSha256={artifactSha256}
            repositoryId={repositoryId}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
