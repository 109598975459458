/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { FieldValidation } from '@rjsf/utils';
import { ApiHolder } from '@backstage/core-plugin-api';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import { ScmIntegrationRegistry } from '@backstage/integration';

/**
 * The validation function for the `repoUrl` that is returned from the
 * field extension. Ensures that you have all the required fields filled for
 * the different providers that exist.
 *
 * @public
 */
export const repoPickerValidation = (
  value: string,
  validation: FieldValidation,
  context: { apiHolder: ApiHolder },
) => {
  try {
    const { host, searchParams } = new URL(`https://${value}`);

    const integrationApi = context.apiHolder.get(scmIntegrationsApiRef);

    if (!host) {
      validation.addError(
        'Incomplete repository location provided, host not provided',
      );
      return;
    }

    validateRepositoryLocation(integrationApi, host, searchParams, validation);

    // Do this for all hosts
    validateRepo(searchParams, validation);
  } catch {
    validation.addError('Unable to parse the Repository URL');
  }
};

function validateRepo(
  searchParams: URLSearchParams,
  validation: FieldValidation,
) {
  if (!searchParams.get('repo')) {
    validation.addError(
      'Incomplete repository location provided, repo not provided',
    );
  }
}

function validateRepositoryLocation(
  integrationApi: ScmIntegrationRegistry | undefined,
  host: string,
  searchParams: URLSearchParams,
  validation: FieldValidation,
) {
  if (integrationApi?.byHost(host)?.type === 'bitbucket') {
    // workspace is only applicable for bitbucket cloud
    validateBitbucketRepo(host, searchParams, validation);
  }

  // For anything other than bitbucket and gerrit
  else if (
    integrationApi?.byHost(host)?.type !== 'gerrit' &&
    !searchParams.get('owner')
  ) {
    validation.addError(
      'Incomplete repository location provided, owner not provided',
    );
  }
}

function validateBitbucketRepo(
  host: string,
  searchParams: URLSearchParams,
  validation: FieldValidation,
) {
  if (host === 'bitbucket.org' && !searchParams.get('workspace')) {
    validation.addError(
      'Incomplete repository location provided, workspace not provided',
    );
  }

  if (!searchParams.get('project')) {
    validation.addError(
      'Incomplete repository location provided, project not provided',
    );
  }
}
