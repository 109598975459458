import { OverviewField, useCardClasses } from '@mb.io/core-components';
import { ScanOverview } from '@mb.io/harbor-common';
import { black, red, white } from '@mercedes-benz/mui5-theme';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  critical: {
    backgroundColor: red[35],
    '& [class*=makeStyles-label], & [class*=makeStyles-value]': {
      color: white,
    },
  },
  high: {
    backgroundColor: red[45],
    '& [class*=makeStyles-label], & [class*=makeStyles-value]': {
      color: white,
    },
  },
  medium: {
    backgroundColor: theme.palette.mode === 'light' ? red[60] : red[55],
    '& [class*=makeStyles-label], & [class*=makeStyles-value]': {
      color: black,
    },
  },
  low: {
    backgroundColor: theme.palette.mode === 'light' ? red[85] : red[70],
    '& [class*=makeStyles-label], & [class*=makeStyles-value]': {
      color: black,
    },
  },
}));

type Props = {
  variant: 'gridItem' | 'fullHeight';
  scanOverview: ScanOverview;
};

export const ScanOverviewCard = ({ variant, scanOverview }: Props) => {
  const { classes } = useStyles();
  const { cardClass, cardContentClass } = useCardClasses(variant);

  const vulnerabilitiesOverview = `${scanOverview.summary.total} (${scanOverview.summary.fixable} Fixable)`;

  return (
    <Card className={cardClass}>
      <CardHeader title="Security Scan" />
      <Divider />
      <CardContent className={cardContentClass}>
        <Grid container>
          <OverviewField
            label="Status"
            defaultValue="Not Scanned"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={scanOverview.scan_status}
          />
          <OverviewField
            label="Duration"
            defaultValue="No scan duration"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={scanOverview.duration}
            suffix="Seconds"
          />
          <OverviewField
            label="Complete Percent"
            defaultValue="0 %"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={scanOverview.complete_percent}
            suffix="%"
          />
          <OverviewField
            label="Start Time"
            defaultValue="Not started"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={scanOverview.start_time}
          />
          <OverviewField
            label="End Time"
            defaultValue="Not ended"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={scanOverview.end_time}
          />
          <OverviewField
            label="Vulnerabilities"
            defaultValue="Not scanned"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={vulnerabilitiesOverview}
          />
          <OverviewField
            className={classes.critical}
            label="Critical"
            defaultValue="0"
            gridSizes={{ xs: 12, sm: 3 }}
            value={scanOverview.summary.summary.Critical}
          />
          <OverviewField
            className={classes.high}
            label="High"
            defaultValue="0"
            gridSizes={{ xs: 12, sm: 3 }}
            value={scanOverview.summary.summary.High}
          />
          <OverviewField
            className={classes.medium}
            label="Medium"
            defaultValue="0"
            gridSizes={{ xs: 12, sm: 3 }}
            value={scanOverview.summary.summary.Medium}
          />
          <OverviewField
            className={classes.low}
            label="Low"
            defaultValue="0"
            gridSizes={{ xs: 12, sm: 3 }}
            value={scanOverview.summary.summary.Low}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};
